const ProgressSteps = ({ currentStep }) => (
    <div className="flex items-center justify-center gap-4">
      {[
        { step: 1, label: "Order Details" },
        { step: 2, label: "Select Writer" },
        { step: 3, label: "Payment" },
      ].map((item, index) => (
        <div key={item.step} className="flex items-center">
          {index > 0 && <div className="w-16 h-0.5 bg-gray-200" />}
          <div className={`flex items-center gap-2 ${currentStep === item.step ? "text-[#2563EB]" : "text-gray-400"}`}>
            <div
              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                currentStep === item.step ? "bg-[#2563EB] text-white" : "bg-gray-200"
              }`}
            >
              {item.step}
            </div>
            <span className="hidden sm:inline text-sm font-medium">{item.label}</span>
          </div>
        </div>
      ))}
    </div>
  )

  export default ProgressSteps;