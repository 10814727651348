"use client"

import { useEffect, useState, useRef } from "react"
import { motion, AnimatePresence, useAnimation } from "framer-motion"
import { FileUp, Book, Cog, Calculator, Package } from "lucide-react"

const iconComponents = {
  upload: FileUp,
  subject: Book,
  services: Cog,
  pricing: Calculator,
  order: Package,
}

const iconAnimations = {
  upload: {
    y: [0, -8, 0],
    scale: [1, 1.1, 1],
    transition: {
      duration: 1.5,
      repeat: Number.POSITIVE_INFINITY,
      ease: "easeInOut",
    },
  },
  subject: {
    rotateY: [0, 360],
    scale: [1, 1.1, 1],
    transition: {
      duration: 2,
      repeat: Number.POSITIVE_INFINITY,
      ease: "easeInOut",
    },
  },
  services: {
    rotate: [0, 360],
    scale: [1, 1.1, 1],
    transition: {
      duration: 2.5,
      repeat: Number.POSITIVE_INFINITY,
      ease: "linear",
    },
  },
  pricing: {
    scale: [1, 1.15, 1],
    rotate: [0, 10, -10, 0],
    transition: {
      duration: 1.8,
      repeat: Number.POSITIVE_INFINITY,
      ease: "easeInOut",
    },
  },
  order: {
    x: [-5, 5, -5],
    y: [0, -3, 0],
    transition: {
      duration: 2,
      repeat: Number.POSITIVE_INFINITY,
      ease: "easeInOut",
    },
  },
}

const Loader = ({ type, message }) => {
  const Icon = iconComponents[type]
  const animation = iconAnimations[type]
  const [particles, setParticles] = useState([])
  const containerRef = useRef(null)
  const iconAnimation = useAnimation()

  useEffect(() => {
    const newParticles = Array.from({ length: 15 }).map((_, i) => (
      <motion.div
        key={i}
        className="absolute w-1 h-1 rounded-full bg-white/40"
        initial={{
          x: Math.random() * 200 - 100,
          y: Math.random() * 200 - 100,
          scale: 0,
        }}
        animate={{
          x: Math.random() * 200 - 100,
          y: Math.random() * 200 - 100,
          scale: Math.random() * 0.5 + 0.2,
          opacity: [0.2, 0.5, 0.2],
        }}
        transition={{
          duration: Math.random() * 2 + 1,
          repeat: Number.POSITIVE_INFINITY,
          ease: "easeInOut",
        }}
      />
    ))
    setParticles(newParticles)
  }, [])

  useEffect(() => {
    iconAnimation.start(animation)
  }, [animation, iconAnimation])

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect()
      const x = (e.clientX - left - width / 2) / 15
      const y = (e.clientY - top - height / 2) / 15
      containerRef.current.style.transform = `rotateY(${x}deg) rotateX(${-y}deg)`
    }
  }

  const handleMouseLeave = () => {
    if (containerRef.current) {
      containerRef.current.style.transform = "rotateY(0deg) rotateX(0deg)"
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      <AnimatePresence>
        <motion.div
          ref={containerRef}
          initial={{ opacity: 0, scale: 0.9, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          exit={{ opacity: 0, scale: 0.9, y: -10 }}
          className="bg-gradient-to-r from-[#2196F3] to-[#00BCD4] rounded-2xl shadow-2xl p-8 flex flex-col items-center max-w-xs w-full mx-4 relative overflow-hidden"
          style={{ perspective: "1000px", transformStyle: "preserve-3d" }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {particles}
          <motion.div className="text-white mb-4 relative z-10" animate={iconAnimation}>
            <Icon size={40} strokeWidth={1.5} />
          </motion.div>
          <motion.h2
            className="text-2xl font-bold text-white mb-2 text-center relative z-10"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            {getMessage(type)}
          </motion.h2>
          {message && (
            <motion.p
              className="text-white/90 text-center text-sm relative z-10"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3, duration: 0.5 }}
            >
              {message}
            </motion.p>
          )}
          <motion.div
            className="w-full bg-white/20 h-1.5 rounded-full mt-6 overflow-hidden relative z-10"
            initial={{ width: 0 }}
            animate={{ width: "100%" }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <motion.div
              className="bg-white h-full rounded-full"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{
                delay: 0.4,
                duration: 3,
                repeat: Number.POSITIVE_INFINITY,
                ease: "easeInOut",
              }}
            />
          </motion.div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
        </motion.div>
      </AnimatePresence>
    </div>
  )
}

export default function LoaderMultiple({ type, message }) {
  const [showLoader, setShowLoader] = useState(false)
  const [loaderType, setLoaderType] = useState(null)
  const timerRef = useRef(null)

  useEffect(() => {
    if (type) {
      setShowLoader(true)
      setLoaderType(type)

      // Clear any existing timer
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }

      // Set a new timer for 30 seconds
      timerRef.current = setTimeout(() => {
        setShowLoader(false)
      }, 30000)
    } else {
      // If type becomes falsy, start a short timer to allow for smooth transitions
      timerRef.current = setTimeout(() => {
        setShowLoader(false)
      }, 1000)
    }

    // Cleanup function
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [type])

  if (!showLoader) {
    return null
  }

  return <Loader type={loaderType} message={message} />
}

function getMessage(type) {
  switch (type) {
    case "upload":
      return "Uploading Document"
    case "subject":
      return "Loading Subject"
    case "services":
      return "Loading Services"
    case "pricing":
      return "Calculating Price"
    case "order":
      return "Preparing Order"
    default:
      return "Loading"
  }
}

