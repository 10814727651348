import React, { useState } from "react";
import Icons from "../utils/Icons";
import Col6 from "./components/Col6";
import { useSelector } from "react-redux";
import { returnDateWithTime } from "../utils/functions/functions";

const LeadCard = ({ close, data,  hideAction ,style}) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const isManager = user?.userType === "MANAGER";
  // const isClient = user?.userType === "CLIENT";
  const isOperator = user?.userType === "OPERATOR";
  const isExpert = user?.userType === "EXPERT";
  const isManagerOrOperator = isManager || isOperator;
  const isManagerOrExpert = isManager || isExpert;
  const isAdmin= user?.userType === "ADMIN";
  const isSuperAdmin= user?.userType === "SUPER_ADMIN";
  const isAdminOrSuperAdmin=isAdmin || isSuperAdmin;
  return (
    <div className="flex justify-center items-center min-h-screen " style={{...style}}>
      <div className="w-full detail-card-lead bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="bg-[#00234a] h-24 relative text-right">
          <button
            className="relative top-4 left-4 text-white mr-21"
            onClick={close}
          >
            <Icons title={"cross"} />
          </button>
          <h3 className="text-center text-white">{data?.subject}</h3>
        </div>

        <div className={`pt-10 px-4 ${!hideAction && "pb-4"}`}>
          <div className="row">
            {Col6(
              data?.orderId ? "Order Id" : "Lead Id",
              data?.orderId || data?.leadId
            )}
            {Col6("Word Count", data?.wordCount)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Source", data?.source || "NA")}
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Expert Deadline", returnDateWithTime(data?.expertDeadline))}
            {Col6(
              (isManagerOrOperator || isAdminOrSuperAdmin) ? "Client Deadline" : "Deadline",
              returnDateWithTime(
                isExpert ? data?.expertDeadline : data?.clientDeadline
              )
            )}
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Lead Date", returnDateWithTime(data?.createdAt))}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Total Payment", data?.totalPayment)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Payment Type", data?.paymentType)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Pending Payment", data?.pendingPayment)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Amount", data?.amount)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Last Update", returnDateWithTime(data?.updatedAt))}
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Payment Received", data?.paymentRecieved)}
            {Col6("Subject", data?.subject)}
            {Col6("Category", data?.category)}
            { console.log("data",data?.requirement)}
            {Col6("Reference Type", data?.referenceType)}
            {Col6("Requirement", data?.requirement)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Expert Name", data?.expert?.name)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Plan", data?.plan)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("University", data?.university)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Course", data?.course)}
            {Col6("Status", data?.status)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Currency", data?.currancy)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) && Col6("Total Payment", data?.totalPayment)}
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Payment Received", data?.payamnetRecived)}
            {(isManagerOrExpert || isOperator || isAdminOrSuperAdmin)  && Col6("Expert Amount", data?.expertAmount)}
          </div>
          <div className="row">
            {(isManagerOrOperator || isAdminOrSuperAdmin) &&
              Col6("Payment Screenshort", data?.screenshots)}
            {(isManagerOrExpert || isAdminOrSuperAdmin) && Col6("Requirement File", data?.document)}
          </div>
          {/* {(user?.userType === "MANAGER" || user?.userType === "OPERATOR") && (
            <div className="relative">
              <button
                onClick={() => onEditClick(data)}
                className="bg-[#00234a] text-white text-sm py-1 px-4 rounded-md hover:bg-opacity-90 transition duration-300"
              >
                Edit{" "}
                <span className="edit-icon-lead">
                  <Icons title={"edit"} />
                </span>
              </button>
            </div>
          )} */}
        </div>

        {(isManagerOrOperator || isAdminOrSuperAdmin) && (
          <div className="border-t border-gray-200 px-4 py-4">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold text-gray-700">
                Client Information
              </h3>
            </div>
            <div className="space-y-2">
              {(isManagerOrOperator || isAdminOrSuperAdmin) && (
                <p className="text-sm text-gray-600">
                  <span className="font-bold">Email:</span>{" "}
                  {data?.client?.email}
                </p>
              )}
              {/* <p className="text-sm text-gray-600"><span className="font-bold">Office ID:</span> OW12345</p> */}
              {(isManager || isAdminOrSuperAdmin) && (
                <p className="text-sm text-gray-600">
                  <span className="font-bold">Status:</span>{" "}
                  {data?.client?.status}
                </p>
              )}
              {/* <p className="text-sm text-gray-600"><span className="font-bold">Location:</span> San Francisco, CA</p> */}
              {(isManager || isAdminOrSuperAdmin) && (
                <p className="text-sm text-gray-600">
                  <span className="font-bold">Joined:</span>{" "}
                  {returnDateWithTime(data?.client?.createdAt)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadCard;
