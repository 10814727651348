import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Users, UserCheck, UserCog, Briefcase, User, ChevronDown, Search, X, Star, ShoppingCart, Calendar, Wallet, Clock, DollarSign, AlertCircle, ChevronUp } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DashboardLayout from '../layouts/DashboardLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsAction, getUserReportListAction } from '../../services/action/common';
import UserReportList from './components/UserReportList';

const UsersReport = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const {user}=useSelector((state)=>state.authenticationReducer)
  const { reports } = useSelector((state) => state.commonReducer);
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");


  const [pageData, setPageData] = useState({
    page: 1,
  });
  const [search, setSearch] = useState("");
  const metrics =(currentData)=>{
    return [
    { id: 'clients', title: 'Clients', value: currentData?.clients, icon: Users, color: 'bg-blue-500' },
    { id: 'operators_sales', title: 'Operators (Sales)', value: currentData?.operatorSales, icon: UserCheck, color: 'bg-green-500' },
    { id: 'operators_operations', title: 'Operators (Operations)', value: currentData?.operatorOperations, icon: UserCog, color: 'bg-yellow-500' },
    { id: 'operators_accounts', title: 'Operators (Accounts)', value: currentData?.operatorAccounts, icon: Briefcase, color: 'bg-purple-500' },
    { id: 'experts_internal', title: 'Experts (Internal)', value: currentData?.expertInternal, icon: User, color: 'bg-red-500' },
    { id: 'experts_external', title: 'Experts (External)', value: currentData?.expertExternal, icon: User, color: 'bg-indigo-500' },
  ];
}

  const months = [
    "All",
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const filteredMetrics = useMemo(() => {
    return metrics(reports?.current)?.filter(metric =>
      metric.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm,reports]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      dispatch(
        getReportsAction({
          userType: user?.userType?.toLowerCase(),
          data: {
            reportType: "USERS",
            selectedMonth: selectedMonth == "All" ? "" : selectedMonth,
          },
        })
      );
    }
  }, [dispatch, user, selectedMonth]);


  const getUserReportList = ({
    userType = selectedMetric,
    specificMonth = selectedMonth,
    page = pageData?.page,
    firstTime,
    tempSearch = search,
    dateFilter=selectedDateFilter,
  }) => {
  
    const payload = {
      page,
      search: tempSearch,
      specificMonth: specificMonth === "All" ? "" : specificMonth,
      userType,
      dateFilter
    };
    // debugger;   
    dispatch(
      getUserReportListAction({
        userType: user?.userType,
        data: payload,
        firstTime: firstTime,
      })
    );
  };

  return (
    <DashboardLayout>
    <div className="min-h-screen bg-[#001B3D] p-6">
      <div className="max-w-7xl mx-auto">
        <header className="mb-4">
          <div className="flex justify-between items-center mb-6">
            <div>
              <h1 className="text-4xl font-bold text-white mb-2">
                Orderwise User Reports
              </h1>
              <p className="text-blue-300">
                Your user management dashboard
              </p>
            </div>
            <div className="relative">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                className="appearance-none bg-[#0A2547] text-white py-2 px-4 pr-8 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {months.map((month) => (
                  <option key={month} value={month}>{month}</option>
                ))}
              </select>
              <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
            </div>
          </div>
        </header>

        <motion.div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12"
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {filteredMetrics.map((metric) => (
            <motion.div 
              key={metric.id}
              className="bg-[#0A2547] rounded-xl shadow-lg overflow-hidden hover:bg-[#0F2F5B] transition-all duration-300 cursor-pointer"
              variants={itemVariants}
              onClick={() => {
                getUserReportList({
                  userType: metric.id,
                  firstTime: true,
                  page: 1,
                });
                setSelectedMetric(metric.id);
                setPageData({ page: 1 });
              }}
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <span className="text-lg font-semibold text-white">
                    {metric.title}
                  </span>
                  <div className={`p-2 rounded-full ${metric.color} text-white`}>
                    <metric.icon className="h-6 w-6" />
                  </div>
                </div>
                <p className="text-3xl font-bold text-white">
                  {metric.value}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>

        <div className="bg-[#0A2547] rounded-xl shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-bold text-white mb-4">
            User Growth This Year
          </h2>
          <div className="h-[300px]">
           {selectedMonth==="All" && <ResponsiveContainer width="100%" height="100%">
              <LineChart data={reports?.graphData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                <XAxis dataKey="month" stroke="#8295AF" />
                <YAxis stroke="#8295AF" />
                <Tooltip contentStyle={{ backgroundColor: '#0A2547', border: 'none' }} />
                <Line type="monotone" dataKey="clients" stroke="#3B82F6" strokeWidth={2} dot={{ fill: '#3B82F6' }} />
                <Line type="monotone" dataKey="operators" stroke="#10B981" strokeWidth={2} dot={{ fill: '#10B981' }} />
                <Line type="monotone" dataKey="experts" stroke="#F59E0B" strokeWidth={2} dot={{ fill: '#F59E0B' }} />
              </LineChart>
            </ResponsiveContainer>}
            {
              selectedMonth!=="All" &&(
                <ResponsiveContainer width="100%" height="100%">
                <LineChart data={reports?.graphData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#1E3A5F" />
                  <XAxis dataKey="day" stroke="#8295AF" />
                  <YAxis stroke="#8295AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#0A2547",
                      border: "none",
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="clients"
                    name='Clients'
                    stroke="#3B82F6"
                    strokeWidth={2}
                    dot={{ fill: "#3B82F6" }}
                  />
                   <Line
                      type="monotone"
                      dataKey="experts"
                      name="Experts"
                      stroke="#10B981"
                      strokeWidth={2}
                      dot={{ fill: "#10B981" }}
                    />
                   <Line
                      type="monotone"
                      dataKey="operators"
                      name="Operators"
                      stroke="#3B82F6"
                      strokeWidth={2}
                      dot={{ fill: "#3B82F6" }}
                    />
                </LineChart>
              </ResponsiveContainer>
              )
            }
          </div>
        </div>
        <UserReportList
            metrics={metrics}
            selectedMetric={selectedMetric}
            setSelectedMetric={setSelectedMetric}
            setPageData={setPageData}
            fetchOrderList={getUserReportList}
            setSearch={setSearch}
            pageData={pageData}
            search={search}
            setSelectedDateFilter={setSelectedDateFilter}
            dateRange={selectedDateFilter}
          />
      </div>
    </div>
    </DashboardLayout>
  );
};

export default UsersReport;

