"use client";

import React, { Fragment, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  MessageCircle,
  Eye,
  Copy,
  Facebook,
  Twitter,
  MessageSquare,
  ChevronRight,
  TruckIcon,
  Edit,
  DollarSign,
} from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  generatePaymentLinkAction,
  getDashboardAction,
  startChatAction,
} from "../../services/action/common";
import { returnDateWithTime } from "../../components/utils/functions/functions";

// Importing ClientHeader component
import ClientHeader from "../../components/layout-new/HeaderNew";

// Importing TrackOrderModal component
import TrackOrderModal from "./orders/TrackOrderModal";
import SeasonalOfferBanner from "./orders/order-creation/SeasonalOfferBanner";
import FullScreenModal from "../../components/utils/modals/FullScreenModal";
import NewOrders from "./orders/NewOrders";
import CustomModal from "../../components/utils/modals/CustomModal";

const statusColors = {
  OPEN: "bg-blue-100 text-blue-800",
  ASSIGNED: "bg-green-100 text-green-800",
  UNDERQC: "bg-yellow-100 text-yellow-800",
  DELIVERED: "bg-purple-100 text-purple-800",
  REOPEN: "bg-orange-100 text-orange-800",
  ONHOLD: "bg-gray-100 text-gray-800",
  REFUND: "bg-red-100 text-red-800",
  MISSED_ORDER: "bg-red-200 text-red-900",
  CLIENTDEADLINE: "bg-pink-100 text-pink-800",
  DONE: "bg-green-200 text-green-900",
};

export default function ClientDashboardNew() {
  const [showToast, setShowToast] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [trackingModalOpen, setTrackingModalOpen] = useState(false);
  const [trackingOrderId, setTrackingOrderId] = useState("");
  const [trackingOrderStatus, setTrackingOrderStatus] = useState("");
  const [visibleRows, setVisibleRows] = useState(5);
  const [showAllRows, setShowAllRows] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("FULL");
  const [customAmount, setCustomAmount] = useState("");
  const [currentOrderId, setCurrentOrderId] = useState(null); // Added state variable
  const { user } = useSelector((state) => state.authenticationReducer);
  const [leadDetails, setLeadDetails] = useState(false);

  const { dashboardData } = useSelector((state) => state.commonReducer);

  const dispatch = useDispatch();
  const handleCopy = () => {
    navigator.clipboard.writeText("https://clickinpedia.io");
    setShowToast(true);
    setTimeout(() => setShowToast(false), 2000);
  };

  const toggleRowExpansion = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  const openTrackingModal = (orderId, status) => {
    setTrackingOrderId(orderId);
    setTrackingOrderStatus(status);
    setTrackingModalOpen(true);
  };

  const handlePayment = (orderId) => {
    setPaymentModalOpen(true);
    setCurrentOrderId(orderId); // Update: Store the current order ID
  };

  const userType = user?.userType?.toLowerCase();
  const generatePaymentLink = (orderId) => {
    // Updated generatePaymentLink function
    const callBack = (res) => {
      window.open(res.url,"_self");
    };

    let amount = 0;

    if (paymentType === "FULL") {
      const selectedLead = dashboardData?.leadList.find(
        (lead) => lead._id === orderId
      );
      amount = selectedLead?.totalPayment - selectedLead?.paymentRecieved;
    } else {
      amount = Number.parseFloat(customAmount);
    }
    const currency = dashboardData?.leadList.find(
      (lead) => lead._id === orderId
    )?.currancy; // Assuming the currency is stored in the lead object
    const lead = dashboardData?.leadList.find((lead) => lead._id === orderId);

    dispatch(
      generatePaymentLinkAction({
        userType,
        data: {
          leadId: orderId,
          currency,
          amount,
          paymentType: "razorpay",
        },
        cb: callBack,
      })
    );
  };

  useEffect(() => {
    dispatch(getDashboardAction({ userType: "client" }));
  }, [dispatch]);

  const startChat=(item)=>{
     dispatch(startChatAction(item));
  }

  return (
    <Fragment>
      <ClientHeader />
      <SeasonalOfferBanner redirect="/client/new-order" />
      <CustomModal
        size="xl"
        open={leadDetails}
        content={() => <NewOrders leadDetails={leadDetails} />}
        onClose={() => setLeadDetails(false)}
      />
      <div>
        {" "}
        {/* Increased top padding for better spacing */}
        {/* Statistics Section */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 py-4 sm:py-8">
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
              <h3 className="text-sm font-medium text-gray-500">Total Leads</h3>
              <p className="text-2xl font-bold text-gray-900">
                {dashboardData?.totalLeads || 0}
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
              <h3 className="text-sm font-medium text-gray-500">
                Total Orders
              </h3>
              <p className="text-2xl font-bold text-gray-900">
                {dashboardData?.orders || 0}
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
              <h3 className="text-sm font-medium text-gray-500">
                Balance Payment
              </h3>
              <p className="text-2xl font-bold text-gray-900">
                {dashboardData?.balancePayment || "$0"}
              </p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
              <h3 className="text-sm font-medium text-gray-500">
                Orders Completed
              </h3>
              <p className="text-2xl font-bold text-gray-900">
                {dashboardData?.orderCompleted || 0}
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Table Section */}
          <div className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200 mb-8">
            <div className="overflow-x-auto">
              <table className="w-full min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Order Details
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                    >
                      Due Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:table-cell"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {dashboardData?.leadList
                    .slice(0, showAllRows ? undefined : visibleRows)
                    .map((lead) => (
                      <React.Fragment key={lead._id}>
                        <tr className="hover:bg-gray-50">
                          <td className="px-6 py-4">
                            <div className="flex items-center">
                              <div>
                                <div className="text-sm font-medium text-gray-900">
                                  {lead.subject}
                                </div>
                                <div className="text-sm text-gray-500">
                                  Order ID: {lead.orderId || "N/A"}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                            {returnDateWithTime(lead.clientDeadline)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 hidden sm:table-cell">
                            {lead.orderStatus === "PAID" || lead.currancy
                              ? `${(
                                  lead.totalPayment - lead?.paymentRecieved
                                )?.toFixed(2)} ${lead.currancy}`
                              : "Pricing will be updated by experts"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap hidden sm:table-cell">
                            <span
                              className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                statusColors[lead.orderStatus] ||
                                statusColors[lead.leadStatus] ||
                                statusColors["OPEN"]
                              }`}
                            >
                              {lead.orderStatus || lead.leadStatus}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium hidden sm:table-cell">
                            <div className="flex space-x-2">
                              {(lead.leadStatus === "NEW" ||
                                lead.paymentType === "PARTIAL" ||
                                !!(
                                  lead?.totalPayment - lead?.paymentRecieved
                                )) && (
                                <button
                                  className="text-green-600 hover:text-green-900 flex items-center space-x-1"
                                  onClick={() => handlePayment(lead._id)}
                                >
                                  <DollarSign className="w-4 h-4" />
                                  <span>Pay Now</span>
                                </button>
                              )}
                              <button
                                onClick={() =>
                                  openTrackingModal(
                                    lead._id,
                                    lead.orderStatus || lead.leadStatus
                                  )
                                }
                                className="text-blue-600 hover:text-blue-900 flex items-center space-x-1"
                              >
                                <TruckIcon className="w-4 h-4" />
                                <span>Track</span>
                              </button>
                              <button
                              onClick={()=>startChat({ leadDetail: lead, userId: user?._id })}
                              className="text-blue-600 hover:text-blue-900 flex items-center space-x-1">
                                <MessageCircle className="w-4 h-4" />
                                <span>Chat</span>
                              </button>
                              {/* {(lead.orderStatus === "OPEN" || lead.leadStatus === "NEW") && (
                              <button onClick={()=>setLeadDetails(lead)} className="text-blue-600 hover:text-blue-900 flex items-center space-x-1">
                                <Edit className="w-4 h-4" />
                                <span>Edit</span>
                              </button>
                            )} */}
                              <button
                                type="button"
                                onClick={() => setExpandedRow(lead?._id)}
                                className="text-gray-600 hover:text-gray-900"
                              >
                                <Eye className="w-4 h-4" />
                              </button>
                            </div>
                          </td>
                        </tr>
                        <AnimatePresence>
                        {expandedRow === lead._id && (
                          <motion.tr
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <td colSpan={5} className="px-6 py-4 bg-gray-50">
                              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
                                <div>
                                  <p>
                                    <span className="font-medium">Subject:</span> {lead.subject}
                                  </p>
                                  <p>
                                    <span className="font-medium">Order ID:</span> {lead.orderId || "N/A"}
                                  </p>
                                  <p>
                                    <span className="font-medium">Status:</span> {lead.orderStatus || lead.leadStatus}
                                  </p>
                                </div>
                                <div>
                                  <p>
                                    <span className="font-medium">Due Date:</span>{" "}
                                    {new Date(lead.clientDeadline).toLocaleDateString()}
                                  </p>
                                  <p>
                                    <span className="font-medium">Price:</span> {lead.totalPayment.toFixed(2)}{" "}
                                    {lead.currancy}
                                  </p>
                                  <p>
                                    <span className="font-medium">Payment Type:</span> {lead.paymentType || "N/A"}
                                  </p>
                                </div>
                                <div className="sm:col-span-2">
                                  <p>
                                    <span className="font-medium">Requirement:</span> {lead.requirement}
                                  </p>
                                </div>
                                <div className="sm:col-span-2">
                                  <div className="mt-2 flex space-x-2">
                                    <button
                                      onClick={() => openTrackingModal(lead._id, lead.orderStatus || lead.leadStatus)}
                                      className="text-blue-600 hover:text-blue-900 text-sm font-medium flex items-center space-x-1"
                                    >
                                      <TruckIcon className="w-4 h-4" />
                                      <span>Track Order</span>
                                    </button>
                                    <button className="text-blue-600 hover:text-blue-900 text-sm font-medium flex items-center space-x-1">
                                      <MessageCircle className="w-4 h-4" />
                                      <span>Start Chat</span>
                                    </button>
                                    {(lead.orderStatus === "OPEN" || lead.leadStatus === "NEW") && (
                                      <button className="text-blue-600 hover:text-blue-900 text-sm font-medium flex items-center space-x-1">
                                        <Edit className="w-4 h-4" />
                                        <span>Edit Order</span>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </motion.tr>
                        )}
                      </AnimatePresence>
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
            {!showAllRows && dashboardData?.leadList.length > visibleRows && (
              <div className="px-6 py-4 text-center">
                <button
                  onClick={() => setShowAllRows(true)}
                  className="text-blue-600 hover:text-blue-900 font-medium"
                >
                  Show More
                </button>
              </div>
            )}
          </div>

          {/* Referral Section */}
          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200 mb-8">
            <div className="flex flex-col space-y-4">
              <h3 className="text-xl font-semibold text-gray-900">
                Refer a friend,{" "}
                <span className="text-blue-600">earn $50.00</span>
              </h3>
              <p className="text-sm text-gray-600">
                Gift your friend 20% OFF their first order and get $50.00 to
                your balance once it's completed!
              </p>
              <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
                <div className="w-full sm:w-auto flex-1">
                  <div className="flex">
                    <input
                      type="text"
                      value="https://clickinpedia.io"
                      readOnly
                      className="flex-1 px-3 py-2 border rounded-l-md text-sm"
                    />
                    <button
                      onClick={handleCopy}
                      className="px-4 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 transition-colors flex items-center space-x-2 text-sm"
                    >
                      <Copy className="w-4 h-4" />
                      <span>Copy</span>
                    </button>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button className="p-2 bg-[#0084FF] text-white rounded-full hover:bg-[#0077e6] transition-colors">
                    <MessageSquare className="w-5 h-5" />
                  </button>
                  <button className="p-2 bg-[#1877F2] text-white rounded-full hover:bg-[#166fe5] transition-colors">
                    <Facebook className="w-5 h-5" />
                  </button>
                  <button className="p-2 bg-[#1DA1F2] text-white rounded-full hover:bg-[#1a91da] transition-colors">
                    <Twitter className="w-5 h-5" />
                  </button>
                </div>
                <button className="text-blue-600 hover:text-blue-700 font-medium text-sm">
                  Learn more
                </button>
              </div>
            </div>
          </div>

          {/* Reward Program Section */}
          <div className="bg-white rounded-lg shadow-md p-6 border border-gray-200 mb-8">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0">
              <div className="flex items-start space-x-4">
                <div className="w-12 h-12 bg-red-500 rounded-lg flex items-center justify-center text-white font-bold text-2xl">
                  R
                </div>
                <div>
                  <h3 className="font-semibold text-lg text-gray-900">
                    Reward Program
                  </h3>
                  <p className="text-sm text-gray-600">
                    Earn points and collect rewards. Up to{" "}
                    <span className="text-blue-600">30% discount</span> for the
                    order, free{" "}
                    <span className="text-blue-600">Premium writer</span> option{" "}
                    <span className="text-blue-600">and more...</span>
                  </p>
                </div>
              </div>
              <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-700 font-medium text-sm">
                <span>Check it now</span>
                <ChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>

          {/* Toast Notification */}
          <AnimatePresence>
            {showToast && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-md text-xs z-50"
              >
                Copied to clipboard!
              </motion.div>
            )}
          </AnimatePresence>

          {/* Track Order Modal */}
          <TrackOrderModal
            isOpen={trackingModalOpen}
            onClose={() => setTrackingModalOpen(false)}
            orderId={trackingOrderId}
            status={trackingOrderStatus}
          />

          {/* Payment Modal */}
          {paymentModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Select Payment Type</h2>
                <div className="space-y-4">
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="FULL"
                      checked={paymentType === "FULL"}
                      onChange={(e) => setPaymentType(e.target.value)}
                      className="mr-2"
                    />
                    Full Payment
                  </label>
                  <label className="flex items-center">
                    <input
                      type="radio"
                      value="PARTIAL"
                      checked={paymentType === "PARTIAL"}
                      onChange={(e) => setPaymentType(e.target.value)}
                      className="mr-2"
                    />
                    Partial Payment
                  </label>
                  {paymentType === "PARTIAL" && (
                    <input
                      type="number"
                      value={customAmount}
                      onChange={(e) => setCustomAmount(e.target.value)}
                      placeholder="Enter amount"
                      className="w-full p-2 border rounded"
                    />
                  )}
                  <div className="flex justify-end space-x-2">
                    <button
                      onClick={() => setPaymentModalOpen(false)}
                      className="px-4 py-2 bg-gray-200 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        generatePaymentLink(currentOrderId); // Update: Call generatePaymentLink function
                        setPaymentModalOpen(false);
                      }}
                      className="px-4 py-2 bg-blue-600 text-white rounded"
                    >
                      Confirm Payment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
