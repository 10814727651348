import React from "react";

const ScreenLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-600 to-blue-800 p-4">
      <div className="relative">
        <div className="w-24 h-24 border-4 border-white/30 rounded-full animate-spin">
          <div className="absolute top-0 left-0 w-24 h-24 border-t-4 border-l-4 border-white rounded-full animate-pulse"></div>
        </div>
        <div className="absolute top-1/2 left-1/2 w-4 h-4 bg-white rounded-full -translate-x-1/2 -translate-y-1/2 animate-ping"></div>
      </div>
      <div className="mt-8 text-center max-w-md mx-auto px-4">
        <h2 className="text-3xl font-bold text-white mb-4 animate-pulse">
          Verifying Payment
        </h2>
        <p className="text-white/90 text-lg mb-2">
          Please wait while we confirm your transaction
        </p>
        <p className="text-white/70 text-sm">
          Do not refresh or close this page
        </p>
      </div>
    </div>
  );
};

export default ScreenLoader;
