import { useNavigate } from "react-router-dom";

 // SeasonalOfferBanner Component
 const SeasonalOfferBanner = ({handleApplyCouponCode,redirect}) => {

  const navigate = useNavigate();
  const handleApply =()=>{
    if(redirect){
      navigate(redirect)
    }else{
      handleApplyCouponCode()
    }
  }
  return (
    <div className="max-w-6xl mx-auto mb-6 px-4">
      <div className="bg-gradient-to-r from-[#2563EB] to-[#22D3EE] p-4 rounded-lg relative overflow-hidden">
        {/* Background Pattern */}
        <div className="absolute inset-0 opacity-10">
          {Array(20)
            .fill(0)
            .map((_, i) => (
              <div
                key={i}
                className="absolute text-blue-500 font-bold text-xl transform -rotate-45"
                style={{ left: `${i * 5}%`, top: `${(i % 4) * 25}%` }}
              >
                %
              </div>
            ))}
        </div>

        <div className="flex flex-col sm:flex-row items-center justify-between gap-4 relative z-10">
          <div className="flex items-center gap-4">
            <div className="bg-white text-[#2563EB] px-3 py-1 rounded shadow-md transform -rotate-2">
              <span className="font-bold text-sm">SEASONAL</span>
              <br />
              <span className="text-sm">OFFER</span>
            </div>
            <div className="flex items-baseline gap-1 text-white">
              <span className="text-sm">UPTO</span>
              <span className="text-4xl font-bold">45%</span>
              <span className="text-4xl font-bold">OFF</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="border-2 border-dashed border-white/50 px-4 py-2 rounded bg-white/10 text-white">
              <span className="text-sm">USE CODE : SUMMER2025</span>
            </div>
            <button onClick={handleApply} className="bg-white text-[#2563EB] px-6 py-2 rounded hover:bg-opacity-90 transition-colors">
              Claim Now
            </button>
          </div>
        </div>
      </div>
    </div>
  )
 }

  export default SeasonalOfferBanner;