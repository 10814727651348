import {
  BookOpen,
  Headphones,
  Search,
  Star,
  TrendingUp,
  Sparkles,
  Clock,
  GraduationCap,
} from "lucide-react";

export const sourceList = [
  { label: "", value: "" },
  { label: "WhatsApp UK", value: "WhatsApp UK" },
  { label: "WhatsApp AUS", value: "WhatsApp AUS" },
  { label: "WhatsApp USA", value: "WhatsApp USA" },
  { label: "website", value: "website" },
  { label: "Collect Chat", value: "Collect Chat" },
  { label: "Direct Call", value: "Direct Call" },
  { label: "Meta", value: "Meta" },
  { label: "Aisensy", value: "Aisensy" },
  { label: "Google Form", value: "Google Form" },
  { label: "Referral", value: "Referral" },
  { label: "LinkedIn Pulse", value: "LinkedIn Pulse" },
  { label: "Other", value: "Other" },
];

export const userListOption = [
  { label: "OPERATOR", value: "OPERATOR" },
  { label: "EXPERT", value: "EXPERT" },
  { label: "MANAGER", value: "MANAGER" },
  { label: "CLIENT", value: "CLIENT" },
];

export const covertIntoLabelAndObject = (data) => {
  return data?.map((item) => ({ label: item, value: item }));
};

export const subjectList = [
  {
    label: "Mathematics",
    options: [
      { value: "Algebra", label: "Algebra" },
      { value: "Calculus", label: "Calculus" },
      { value: "Statistics", label: "Statistics" },
      { value: "Geometry", label: "Geometry" },
      { value: "Trigonometry", label: "Trigonometry" },
    ],
  },
  {
    label: "Science",
    options: [
      { value: "Physics", label: "Physics" },
      { value: "Chemistry", label: "Chemistry" },
      { value: "Biology", label: "Biology" },
      { value: "Environmental Science", label: "Environmental Science" },
      { value: "Earth Science", label: "Earth Science" },
    ],
  },
  {
    label: "Engineering",
    options: [
      { value: "Mechanical Engineering", label: "Mechanical Engineering" },
      { value: "Electrical Engineering", label: "Electrical Engineering" },
      { value: "Civil Engineering", label: "Civil Engineering" },
      { value: "Computer Engineering", label: "Computer Engineering" },
      { value: "Chemical Engineering", label: "Chemical Engineering" },
    ],
  },
  {
    label: "Computer Science",
    options: [
      { value: "Programming", label: "Programming (Python, Java, C++, etc.)" },
      {
        value: "Data Structures and Algorithms",
        label: "Data Structures and Algorithms",
      },
      { value: "Database Management", label: "Database Management" },
      { value: "Artificial Intelligence", label: "Artificial Intelligence" },
      { value: "Machine Learning", label: "Machine Learning" },
    ],
  },
  {
    label: "Business and Management",
    options: [
      { value: "Marketing", label: "Marketing" },
      {
        value: "Human Resource Management",
        label: "Human Resource Management",
      },
      { value: "Finance", label: "Finance" },
      { value: "Operations Management", label: "Operations Management" },
      { value: "Project Management", label: "Project Management" },
    ],
  },
  {
    label: "Economics",
    options: [
      { value: "Microeconomics", label: "Microeconomics" },
      { value: "Macroeconomics", label: "Macroeconomics" },
      { value: "Econometrics", label: "Econometrics" },
      { value: "International Economics", label: "International Economics" },
      { value: "Public Economics", label: "Public Economics" },
    ],
  },
  {
    label: "Accounting",
    options: [
      { value: "Financial Accounting", label: "Financial Accounting" },
      { value: "Managerial Accounting", label: "Managerial Accounting" },
      { value: "Auditing", label: "Auditing" },
      { value: "Taxation", label: "Taxation" },
      { value: "Corporate Accounting", label: "Corporate Accounting" },
    ],
  },
  {
    label: "Law",
    options: [
      { value: "Criminal Law", label: "Criminal Law" },
      { value: "Contract Law", label: "Contract Law" },
      { value: "International Law", label: "International Law" },
      { value: "Constitutional Law", label: "Constitutional Law" },
      { value: "Corporate Law", label: "Corporate Law" },
    ],
  },
  {
    label: "Nursing and Healthcare",
    options: [
      { value: "Nursing Practice", label: "Nursing Practice" },
      { value: "Medical-Surgical Nursing", label: "Medical-Surgical Nursing" },
      { value: "Pediatric Nursing", label: "Pediatric Nursing" },
      { value: "Public Health", label: "Public Health" },
      { value: "Pharmacology", label: "Pharmacology" },
    ],
  },
  {
    label: "Social Sciences",
    options: [
      { value: "Psychology", label: "Psychology" },
      { value: "Sociology", label: "Sociology" },
      { value: "Anthropology", label: "Anthropology" },
      { value: "Political Science", label: "Political Science" },
      { value: "History", label: "History" },
    ],
  },
  {
    label: "Humanities",
    options: [
      { value: "Literature", label: "Literature" },
      { value: "Philosophy", label: "Philosophy" },
      { value: "Art History", label: "Art History" },
      { value: "Cultural Studies", label: "Cultural Studies" },
      { value: "Religious Studies", label: "Religious Studies" },
    ],
  },
  {
    label: "Education",
    options: [
      { value: "Educational Psychology", label: "Educational Psychology" },
      { value: "Curriculum Development", label: "Curriculum Development" },
      { value: "Classroom Management", label: "Classroom Management" },
      { value: "Special Education", label: "Special Education" },
      {
        value: "Early Childhood Education",
        label: "Early Childhood Education",
      },
    ],
  },
  {
    label: "Environmental Studies",
    options: [
      { value: "Sustainable Development", label: "Sustainable Development" },
      { value: "Environmental Policy", label: "Environmental Policy" },
      { value: "Conservation Biology", label: "Conservation Biology" },
      { value: "Climate Change", label: "Climate Change" },
      { value: "Renewable Energy", label: "Renewable Energy" },
    ],
  },
  {
    label: "Languages",
    options: [
      { value: "English", label: "English" },
      { value: "Spanish", label: "Spanish" },
      { value: "French", label: "French" },
      { value: "German", label: "German" },
      { value: "Chinese", label: "Chinese" },
    ],
  },
  {
    label: "Media and Communication",
    options: [
      { value: "Journalism", label: "Journalism" },
      { value: "Public Relations", label: "Public Relations" },
      { value: "Media Studies", label: "Media Studies" },
      { value: "Digital Marketing", label: "Digital Marketing" },
      { value: "Film Studies", label: "Film Studies" },
    ],
  },
  {
    label: "Fine Arts",
    options: [
      { value: "Visual Arts", label: "Visual Arts" },
      { value: "Music", label: "Music" },
      { value: "Theater", label: "Theater" },
      { value: "Dance", label: "Dance" },
      { value: "Graphic Design", label: "Graphic Design" },
    ],
  },
  {
    label: "Architecture",
    options: [
      { value: "Architectural Design", label: "Architectural Design" },
      { value: "Urban Planning", label: "Urban Planning" },
      { value: "Landscape Architecture", label: "Landscape Architecture" },
      { value: "Interior Design", label: "Interior Design" },
      { value: "Building Technology", label: "Building Technology" },
    ],
  },
  {
    label: "Health Sciences",
    options: [
      { value: "Public Health", label: "Public Health" },
      { value: "Nutrition", label: "Nutrition" },
      { value: "Physical Therapy", label: "Physical Therapy" },
      { value: "Occupational Therapy", label: "Occupational Therapy" },
      { value: "Health Informatics", label: "Health Informatics" },
    ],
  },
  {
    label: "Sports Science",
    options: [
      { value: "Sports Management", label: "Sports Management" },
      { value: "Exercise Physiology", label: "Exercise Physiology" },
      { value: "Sports Psychology", label: "Sports Psychology" },
      { value: "Coaching", label: "Coaching" },
      { value: "Kinesiology", label: "Kinesiology" },
    ],
  },
  {
    label: "Human Resources",
    options: [
      { value: "Talent Management", label: "Talent Management" },
      { value: "Organizational Behavior", label: "Organizational Behavior" },
      { value: "Labor Relations", label: "Labor Relations" },
      {
        value: "Compensation and Benefits",
        label: "Compensation and Benefits",
      },
      {
        value: "Recruitment and Selection",
        label: "Recruitment and Selection",
      },
    ],
  },
];

// You can now pass this `options` array to the `SelectBox` component.

export const countryTimezones = {
  AF: "Asia/Kabul",
  AL: "Europe/Tirane",
  DZ: "Africa/Algiers",
  AD: "Europe/Andorra",
  AO: "Africa/Luanda",
  AG: "America/Antigua",
  AR: "America/Argentina/Buenos_Aires",
  AM: "Asia/Yerevan",
  AU: "Australia/Sydney",
  AT: "Europe/Vienna",
  AZ: "Asia/Baku",
  BS: "America/Nassau",
  BH: "Asia/Bahrain",
  BD: "Asia/Dhaka",
  BB: "America/Barbados",
  BY: "Europe/Minsk",
  BE: "Europe/Brussels",
  BZ: "America/Belize",
  BJ: "Africa/Porto-Novo",
  BO: "America/La_Paz",
  BA: "Europe/Sarajevo",
  BW: "Africa/Gaborone",
  BR: "America/Sao_Paulo",
  BN: "Asia/Brunei",
  BG: "Europe/Sofia",
  BF: "Africa/Ouagadougou",
  BI: "Africa/Bujumbura",
  CV: "Atlantic/Cape_Verde",
  KH: "Asia/Phnom_Penh",
  CM: "Africa/Douala",
  CA: "America/Toronto",
  CD: "Africa/Kinshasa",
  CG: "Africa/Brazzaville",
  CH: "Europe/Zurich",
  CL: "America/Santiago",
  CN: "Asia/Shanghai",
  CO: "America/Bogota",
  CR: "America/Costa_Rica",
  HR: "Europe/Zagreb",
  CU: "America/Havana",
  CY: "Asia/Nicosia",
  CZ: "Europe/Prague",
  DK: "Europe/Copenhagen",
  DJ: "Africa/Djibouti",
  DM: "America/Dominica",
  DO: "America/Santo_Domingo",
  EC: "America/Guayaquil",
  EG: "Africa/Cairo",
  SV: "America/El_Salvador",
  GQ: "Africa/Malabo",
  ER: "Africa/Asmara",
  EE: "Europe/Tallinn",
  SZ: "Africa/Mbabane",
  ET: "Africa/Addis_Ababa",
  FJ: "Pacific/Fiji",
  FI: "Europe/Helsinki",
  FR: "Europe/Paris",
  GA: "Africa/Libreville",
  GB: "Europe/London",
  GD: "America/Grenada",
  GE: "Asia/Tbilisi",
  GH: "Africa/Accra",
  GR: "Europe/Athens",
  GT: "America/Guatemala",
  GN: "Africa/Conakry",
  GW: "Africa/Bissau",
  GY: "America/Guyana",
  HT: "America/Port-au-Prince",
  HN: "America/Tegucigalpa",
  HU: "Europe/Budapest",
  IS: "Atlantic/Reykjavik",
  IN: "Asia/Kolkata",
  ID: "Asia/Jakarta",
  IR: "Asia/Tehran",
  IQ: "Asia/Baghdad",
  IE: "Europe/Dublin",
  IL: "Asia/Jerusalem",
  IT: "Europe/Rome",
  JM: "America/Jamaica",
  JP: "Asia/Tokyo",
  JO: "Asia/Amman",
  KZ: "Asia/Almaty",
  KE: "Africa/Nairobi",
  KI: "Pacific/Tarawa",
  KP: "Asia/Pyongyang",
  KR: "Asia/Seoul",
  KW: "Asia/Kuwait",
  KG: "Asia/Bishkek",
  LA: "Asia/Vientiane",
  LV: "Europe/Riga",
  LB: "Asia/Beirut",
  LS: "Africa/Maseru",
  LR: "Africa/Monrovia",
  LY: "Africa/Tripoli",
  LT: "Europe/Vilnius",
  LU: "Europe/Luxembourg",
  MG: "Indian/Antananarivo",
  MW: "Africa/Blantyre",
  MY: "Asia/Kuala_Lumpur",
  MV: "Asia/Male",
  ML: "Africa/Bamako",
  MT: "Europe/Malta",
  MH: "Pacific/Majuro",
  MQ: "America/Martinique",
  MR: "Africa/Nouakchott",
  MU: "Indian/Mauritius",
  MX: "America/Mexico_City",
  FM: "Pacific/Chuuk",
  MD: "Europe/Chisinau",
  MC: "Europe/Monaco",
  MN: "Asia/Ulaanbaatar",
  ME: "Europe/Podgorica",
  MA: "Africa/Casablanca",
  MZ: "Africa/Maputo",
  MM: "Asia/Yangon",
  NA: "Africa/Windhoek",
  NP: "Asia/Kathmandu",
  NL: "Europe/Amsterdam",
  NZ: "Pacific/Auckland",
  NI: "America/Managua",
  NE: "Africa/Niamey",
  NG: "Africa/Lagos",
  NU: "Pacific/Niue",
  NF: "Pacific/Norfolk",
  NO: "Europe/Oslo",
  OM: "Asia/Muscat",
  PK: "Asia/Karachi",
  PW: "Pacific/Palau",
  PA: "America/Panama",
  PG: "Pacific/Port_Moresby",
  PY: "America/Asuncion",
  PE: "America/Lima",
  PH: "Asia/Manila",
  PL: "Europe/Warsaw",
  PT: "Europe/Lisbon",
  QA: "Asia/Qatar",
  RE: "Indian/Reunion",
  RO: "Europe/Bucharest",
  RU: "Europe/Moscow",
  RW: "Africa/Kigali",
  SA: "Asia/Riyadh",
  SD: "Africa/Khartoum",
  SN: "Africa/Dakar",
  SG: "Asia/Singapore",
  SX: "America/Philipsburg",
  SK: "Europe/Bratislava",
  SI: "Europe/Ljubljana",
  SB: "Pacific/Guadalcanal",
  SO: "Africa/Mogadishu",
  ZA: "Africa/Johannesburg",
  SS: "Africa/Juba",
  ES: "Europe/Madrid",
  LK: "Asia/Colombo",
  SD: "Africa/Khartoum",
  SR: "America/Paramaribo",
  SZ: "Africa/Mbabane",
  SE: "Europe/Stockholm",
  CH: "Europe/Zurich",
  SY: "Asia/Damascus",
  TJ: "Asia/Dushanbe",
  TZ: "Africa/Dodoma",
  TH: "Asia/Bangkok",
  TG: "Africa/Lome",
  TK: "Pacific/Tokelau",
  TO: "Pacific/Tongatapu",
  TT: "America/Port_of_Spain",
  TN: "Africa/Tunis",
  TR: "Europe/Istanbul",
  TM: "Asia/Ashgabat",
  TV: "Pacific/Funafuti",
  UG: "Africa/Kampala",
  UA: "Europe/Kiev",
  AE: "Asia/Dubai",
  GB: "Europe/London",
  US: "America/New_York", // You can specify other US time zones if needed
  UY: "America/Montevideo",
  UZ: "Asia/Tashkent",
  VU: "Pacific/Efate",
  VE: "America/Caracas",
  VN: "Asia/Hanoi",
  WF: "Pacific/Wallis",
  EH: "Africa/El_Aaiun",
  YE: "Asia/Aden",
  ZM: "Africa/Lusaka",
  ZW: "Africa/Harare",
};

export const countryCodes = [
  { code: "+1", country: "USA" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
  { code: "+86", country: "China" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+61", country: "Australia" },
  { code: "+34", country: "Spain" },
  { code: "+39", country: "Italy" },
  { code: "+1-242", country: "Bahamas" },
  { code: "+1-246", country: "Barbados" },
  { code: "+1-268", country: "Antigua and Barbuda" },
  { code: "+1-264", country: "Anguilla" },
  { code: "+1-784", country: "Saint Vincent and the Grenadines" },
  { code: "+52", country: "Mexico" },
  { code: "+54", country: "Argentina" },
  { code: "+57", country: "Colombia" },
  { code: "+64", country: "New Zealand" },
  { code: "+27", country: "South Africa" },
  { code: "+62", country: "Indonesia" },
  { code: "+63", country: "Philippines" },
  { code: "+66", country: "Thailand" },
  { code: "+60", country: "Malaysia" },
  { code: "+971", country: "United Arab Emirates" },
  { code: "+966", country: "Saudi Arabia" },
  { code: "+20", country: "Egypt" },
  { code: "+212", country: "Morocco" },
  { code: "+234", country: "Nigeria" },
  { code: "+31", country: "Netherlands" },
  { code: "+32", country: "Belgium" },
  { code: "+48", country: "Poland" },
  { code: "+47", country: "Norway" },
  { code: "+46", country: "Sweden" },
  { code: "+41", country: "Switzerland" },
  { code: "+351", country: "Portugal" },
  { code: "+90", country: "Turkey" },
  { code: "+380", country: "Ukraine" },
  { code: "+98", country: "Iran" },
  { code: "+82", country: "South Korea" },
  { code: "+84", country: "Vietnam" },
  { code: "+94", country: "Sri Lanka" },
  { code: "+358", country: "Finland" },
  { code: "+420", country: "Czech Republic" },
  { code: "+30", country: "Greece" },
  { code: "+64", country: "New Zealand" },
  { code: "+373", country: "Moldova" },
  { code: "+372", country: "Estonia" },
  { code: "+371", country: "Latvia" },
  { code: "+370", country: "Lithuania" },
  { code: "+356", country: "Malta" },
  { code: "+357", country: "Cyprus" },
  { code: "+995", country: "Georgia" },
  { code: "+374", country: "Armenia" },
  { code: "+359", country: "Bulgaria" },
  { code: "+382", country: "Montenegro" },
  { code: "+385", country: "Croatia" },
  { code: "+387", country: "Bosnia and Herzegovina" },
  { code: "+389", country: "North Macedonia" },
  { code: "+375", country: "Belarus" },
  { code: "+43", country: "Austria" },
  { code: "+36", country: "Hungary" },
  { code: "+420", country: "Czech Republic" },
  { code: "+372", country: "Estonia" },
  { code: "+40", country: "Romania" },
  { code: "+48", country: "Poland" },
  { code: "+380", country: "Ukraine" },
  { code: "+421", country: "Slovakia" },
  { code: "+385", country: "Croatia" },
  { code: "+386", country: "Slovenia" },
  { code: "+679", country: "Fiji" },
  { code: "+676", country: "Tonga" },
  { code: "+679", country: "Fiji" },
  { code: "+675", country: "Papua New Guinea" },
  { code: "+263", country: "Zimbabwe" },
  { code: "+254", country: "Kenya" },
  { code: "+256", country: "Uganda" },
  { code: "+250", country: "Rwanda" },
  { code: "+255", country: "Tanzania" },
  { code: "+258", country: "Mozambique" },
  { code: "+223", country: "Mali" },
  { code: "+225", country: "Ivory Coast" },
  { code: "+221", country: "Senegal" },
  { code: "+216", country: "Tunisia" },
  { code: "+243", country: "Democratic Republic of the Congo" },
  { code: "+233", country: "Ghana" },
  { code: "+263", country: "Zimbabwe" },
  { code: "+992", country: "Tajikistan" },
  { code: "+993", country: "Turkmenistan" },
  { code: "+996", country: "Kyrgyzstan" },
  { code: "+998", country: "Uzbekistan" },
  { code: "+968", country: "Oman" },
  { code: "+964", country: "Iraq" },
  { code: "+977", country: "Nepal" },
  { code: "+975", country: "Bhutan" },
  { code: "+880", country: "Bangladesh" },
  { code: "+856", country: "Laos" },
  { code: "+95", country: "Myanmar" },
  { code: "+66", country: "Thailand" },
  { code: "+855", country: "Cambodia" },
  { code: "+673", country: "Brunei" },
  { code: "+65", country: "Singapore" },
  { code: "+964", country: "Iraq" },
  { code: "+967", country: "Yemen" },
  { code: "+962", country: "Jordan" },
  { code: "+253", country: "Djibouti" },
  { code: "+265", country: "Malawi" },
  // Add more country codes as needed
];

export const MobileServiceIcons = (index) => {
  switch (index) {
    case 0:
      return <BookOpen className="h-6 w-6 text-white" />;
    case 1:
      return <Search className="h-6 w-6 text-white" />;
    case 2:
      return <TrendingUp className="h-6 w-6 text-white" />;
    case 3:
      return <Star className="h-6 w-6 text-white" />;
    case 4:
      return <Headphones className="h-6 w-6 text-white" />;
    case 5:
      return <GraduationCap className="h-6 w-6 text-white" />;
    default:
      return "";
  }
};

export const MobileAppPremiumServices = (index) => {
  switch (index) {
    case 0:
      return <Sparkles className="h-6 w-6 text-white" />;
    case 1:
      return <Clock className="h-6 w-6 text-white" />;
    case 2:
      return <GraduationCap className="h-6 w-6 text-white" />;
    default:
      return "";
  }
};

export const getStatusStylesChat = (status) => {
  const styles = {
    missed: { border: 'border-l-red-500', badge: 'bg-[#1a2d4a] text-red-400', text: 'Missed' },
    confirmed: { border: 'border-l-green-500', badge: 'bg-[#1a2d4a] text-green-400', text: 'Confirmed' },
    pending: { border: 'border-l-yellow-500', badge: 'bg-[#1a2d4a] text-yellow-400', text: 'Pending' },
  };
  return styles[status] || { border: 'border-l-gray-500', badge: 'bg-[#1a2d4a] text-gray-400', text: status };
};

export const expertOption=[
  "STEM (Science, Technology, Engineering, and Mathematics)",
  "Mathematics",
  "Algebra (Linear Algebra, Abstract Algebra)",
  "Calculus (Differential, Integral, Multivariable)",
  "Geometry (Euclidean, Non-Euclidean, Analytical)",
  "Statistics and Probability (Descriptive, Inferential)",
  "Number Theory",
  "Discrete Mathematics",
  "Differential Equations",
  "Mathematical Modeling",
  "Physics",
  "Classical Mechanics",
  "Quantum Physics",
  "Electromagnetism",
  "Thermodynamics",
  "Optics",
  "Nuclear Physics",
  "Astrophysics",
  "Solid State Physics",
  "Chemistry",
  "Organic Chemistry",
  "Inorganic Chemistry",
  "Physical Chemistry",
  "Analytical Chemistry",
  "Biochemistry",
  "Environmental Chemistry",
  "Medicinal Chemistry",
  "Chemical Kinetics",
  "Biology",
  "Genetics",
  "Microbiology",
  "Molecular Biology",
  "Zoology",
  "Botany",
  "Ecology",
  "Evolutionary Biology",
  "Immunology",
  "Computer Science",
  "Programming (Python, Java, C++, etc.)",
  "Data Structures and Algorithms",
  "Artificial Intelligence and Machine Learning",
  "Cybersecurity",
  "Web Development",
  "Database Management Systems",
  "Software Engineering",
  "Computer Networks",
  "Engineering",
  "Civil Engineering (Structural, Geotechnical, etc.)",
  "Mechanical Engineering (Fluid Dynamics, Thermodynamics)",
  "Electrical Engineering (Circuits, Power Systems)",
  "Electronics and Communication Engineering",
  "Aerospace Engineering",
  "Robotics and Automation",
  "Mechatronics",
  "Renewable Energy Engineering",
  "Humanities",
  "History",
  "World History (Ancient Civilizations, World Wars)",
  "Regional History (European, Asian, African)",
  "Economic History",
  "Social History",
  "Political History",
  "History of Science and Technology",
  "Political Science",
  "Comparative Politics",
  "Political Ideologies",
  "International Politics",
  "Public Administration",
  "Political Theory",
  "Diplomacy and Foreign Policy",
  "Sociology",
  "Social Structures",
  "Urban Sociology",
  "Cultural Sociology",
  "Sociology of Education",
  "Gender and Society",
  "Crime and Deviance",
  "Literature",
  "English Literature (Shakespeare, Romanticism, Modernism)",
  "American Literature",
  "Postcolonial Literature",
  "Literary Theory and Criticism",
  "Comparative Literature",
  "Poetry Analysis",
  "Philosophy",
  "Ethics",
  "Logic and Critical Thinking",
  "Epistemology",
  "Metaphysics",
  "Philosophy of Religion",
  "Political Philosophy",
  "Business and Economics",
  "Accounting and Finance",
  "Financial Accounting",
  "Managerial Accounting",
  "Investment Analysis",
  "Auditing",
  "Corporate Finance",
  "Marketing",
  "Digital Marketing",
  "Consumer Behavior",
  "Marketing Research",
  "Branding and Advertising",
  "Economics",
  "Microeconomics (Demand-Supply, Game Theory)",
  "Macroeconomics (GDP, Inflation)",
  "Development Economics",
  "Behavioral Economics",
  "Management",
  "Operations Management",
  "Organizational Behavior",
  "Strategic Management",
  "Project Management",
  "Health and Medicine",
  "Medical Sciences",
  "Anatomy and Physiology",
  "Pathology",
  "Pharmacology",
  "Clinical Medicine",
  "Nursing",
  "Patient Care and Management",
  "Mental Health Nursing",
  "Pediatric Nursing",
  "Geriatric Nursing",
  "Public Health",
  "Epidemiology",
  "Health Policy",
  "Nutrition and Dietetics",
  "Arts and Design",
  "Art History",
  "Drawing and Painting Techniques",
  "Graphic Design Tools (Photoshop, Illustrator)",
  "3D Modeling and Animation",
  "Typography and Layout Design",
  "Social Sciences",
  "Law (Criminal Law, Constitutional Law)",
  "Psychology (Cognitive, Behavioral)",
  "Anthropology (Cultural, Biological)",
  "Geography (Physical, Human)",
  "Interdisciplinary Studies",
  "Environmental Studies",
  "Media Studies",
  "Gender and Sexuality Studies",
  "Cultural Studies"
]


export const universities = [
  {
    label: "Australia Universities",
    options: [
      { value: "australian_national_university", label: "Australian National University" },
      { value: "university_of_canberra", label: "University of Canberra" },
      { value: "australian_catholic_university", label: "Australian Catholic University" },
      { value: "charles_sturt_university", label: "Charles Sturt University" },
      { value: "macquarie_university", label: "Macquarie University" },
      { value: "southern_cross_university", label: "Southern Cross University" },
      { value: "university_of_new_england", label: "University of New England" },
      { value: "university_of_new_south_wales", label: "University of New South Wales" },
      { value: "university_of_newcastle", label: "University of Newcastle" },
      { value: "university_of_sydney", label: "University of Sydney" },
      { value: "university_of_technology_sydney", label: "University of Technology Sydney" },
      { value: "university_of_wollongong", label: "University of Wollongong" },
      { value: "western_sydney_university", label: "Western Sydney University" },
      { value: "charles_darwin_university", label: "Charles Darwin University" },
      { value: "bond_university", label: "Bond University" },
      { value: "central_queensland_university", label: "Central Queensland University" },
      { value: "griffith_university", label: "Griffith University" },
      { value: "james_cook_university", label: "James Cook University" },
      { value: "queensland_university_of_technology", label: "Queensland University of Technology" },
      { value: "university_of_queensland", label: "University of Queensland" },
      { value: "university_of_southern_queensland", label: "University of Southern Queensland" },
      { value: "university_of_the_sunshine_coast", label: "University of the Sunshine Coast" },
      { value: "flinders_university", label: "Flinders University" },
      { value: "torrens_university_australia", label: "Torrens University Australia" },
      { value: "university_of_adelaide", label: "University of Adelaide" },
      { value: "university_of_south_australia", label: "University of South Australia" },
      { value: "university_of_tasmania", label: "University of Tasmania" },
      { value: "deakin_university", label: "Deakin University" },
      { value: "federation_university_australia", label: "Federation University Australia" },
      { value: "la_trobe_university", label: "La Trobe University" },
      { value: "monash_university", label: "Monash University" },
      { value: "rmit_university", label: "RMIT University" },
      { value: "swinburne_university_of_technology", label: "Swinburne University of Technology" },
      { value: "university_of_divinity", label: "University of Divinity" },
      { value: "university_of_melbourne", label: "University of Melbourne" },
      { value: "victoria_university", label: "Victoria University" },
      { value: "curtin_university", label: "Curtin University" },
      { value: "edith_cowan_university", label: "Edith Cowan University" },
      { value: "murdoch_university", label: "Murdoch University" },
      { value: "university_of_notre_dame_australia", label: "University of Notre Dame Australia" },
      { value: "university_of_western_australia", label: "University of Western Australia" },
    ],
  },
  {
    label: "UK Universities",
    options: [
      { value: "university_of_bath", label: "University of Bath" },
      { value: "university_of_birmingham", label: "University of Birmingham" },
      { value: "university_of_bristol", label: "University of Bristol" },
      { value: "university_of_cambridge", label: "University of Cambridge" },
      { value: "university_of_durham", label: "University of Durham" },
      { value: "university_of_exeter", label: "University of Exeter" },
      { value: "imperial_college_london", label: "Imperial College London" },
      { value: "kings_college_london", label: "King's College London" },
      { value: "university_of_leeds", label: "University of Leeds" },
      { value: "university_of_oxford", label: "University of Oxford" },
      { value: "university_of_sheffield", label: "University of Sheffield" },
      { value: "university_of_st_andrews", label: "University of St Andrews" },
    ],
  },
  {
    label: "Germany Universities",
    options: [
      { value: "rwth_aachen_university", label: "RWTH Aachen University" },
      { value: "university_of_augsburg", label: "University of Augsburg" },
      { value: "university_of_bamberg", label: "University of Bamberg" },
      { value: "technical_university_of_munich", label: "Technical University of Munich" },
      { value: "university_of_mannheim", label: "University of Mannheim" },
    ],
  },
]



export const courses = [
  {
    label: "Master's Courses",
    options: [
      { value: "mba", label: "Master of Business Administration (MBA)" },
      { value: "mcom", label: "Master of Commerce" },
      { value: "mmarketing", label: "Master of Marketing" },
      { value: "mmanagement", label: "Master of Management" },
      { value: "mfinance", label: "Master of Finance" },
      { value: "mhrm", label: "Master of Human Resource Management" },
      { value: "mib", label: "Master of International Business" },
      { value: "mentrepreneurship", label: "Master of Entrepreneurship" },
      { value: "mengineering", label: "Master of Engineering" },
      { value: "mcs", label: "Master of Computer Science" },
      { value: "mit", label: "Master of Information Technology" },
      { value: "mds", label: "Master of Data Science" },
      { value: "mcybersecurity", label: "Master of Cybersecurity" },
      { value: "mse", label: "Master of Software Engineering" },
    ],
  },
  {
    label: "Bachelor's Courses",
    options: [
      { value: "bba", label: "Bachelor of Business Administration (BBA)" },
      { value: "bcom", label: "Bachelor of Commerce (BCom)" },
      { value: "binternational_business", label: "Bachelor of International Business" },
      { value: "bcs", label: "Bachelor of Computer Science" },
      { value: "bit", label: "Bachelor of Information Technology" },
      { value: "bse", label: "Bachelor of Software Engineering" },
      { value: "bpsychology", label: "Bachelor of Psychology" },
    ],
  },
]