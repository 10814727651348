import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import {
  createServicesAction,
  createSubjectAction,
  deleteSbjectsAction,
  deleteServicesAction,
  getServicesAction,
  getSubjectAction,
  updateServicesAction,
  updateSubjectAction,
} from "../../services/action/common"
import DashboardLayout from "../layouts/DashboardLayout"

export default function ManageServices() {
  const { services, subjects } = useSelector((state) => state.commonReducer)
  //   const [services, setServices] = useState([])
  //   const [subjects, setSubjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState("")
  const [activeTab, setActiveTab] = useState("services")
  const [editingService, setEditingService] = useState(null)
  const [editingSubject, setEditingSubject] = useState(null)
  const [isServiceDialogOpen, setIsServiceDialogOpen] = useState(false)
  const [isSubjectDialogOpen, setIsSubjectDialogOpen] = useState(false)
  const { user } = useSelector((state) => state.authenticationReducer)
  const userType = user?.userType?.toLowerCase()

  //   useEffect(()=>{
  //    console.log("serviceList",serviceList)
  //   },[serviceList])

  const serviceForm = useForm({
    defaultValues: {
      serviceName: "",
    },
  })

  const subjectForm = useForm({
    defaultValues: {
      subject: "",
      service: "",
      serviceCharge: 0,
      qcCharge: 0,
      pagePerWordCharge: 0,
      urgencyCharge: 0,
      offPrice: 0,
    },
  })

  //   useEffect(() => {
  // Initialize with some dummy data
  // setServices([
  //   { _id: "1", serviceName: "Academic Writing" },
  //   { _id: "2", serviceName: "Editing and Proofreading" },
  // ])
  // setSubjects([
  //   {
  //     _id: "1",
  //     subject: "Mathematics",
  //     service: { _id: "1", serviceName: "Academic Writing" },
  //     serviceCharge: 10,
  //     qcCharge: 5,
  //     pagePerWordCharge: 0.05,
  //     urgencyCharge: 15,
  //     offPrice: 100,
  //   },
  //   {
  //     _id: "2",
  //     subject: "Physics",
  //     service: { _id: "2", serviceName: "Editing and Proofreading" },
  //     serviceCharge: 12,
  //     qcCharge: 6,
  //     pagePerWordCharge: 0.06,
  //     urgencyCharge: 18,
  //     offPrice: 120,
  //   },
  // ]);
  //   }, [user]);

  const getServices = () => {
    dispatch(getServicesAction({ userType: user?.userType }))
  }

  const getSubject = () => {
    dispatch(getSubjectAction({ userType: user?.userType }))
  }

  const filteredServices = services?.filter((service) =>
    service.serviceName.toLowerCase().includes(searchTerm.toLowerCase()),
  )
  const filteredSubjects = subjects?.filter((subject) =>
    subject.subject.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const dispatch = useDispatch()
  const handleAddService = (data) => {
    if (data.serviceName.trim() === "") {
      serviceForm.setError("serviceName", {
        type: "manual",
        message: "Service name is required",
      })
      return
    }
    dispatch(
      createServicesAction({
        userType,
        data: { serviceName: data.serviceName },
        cb: () => {
          getServices()
          setIsServiceDialogOpen(false)
          serviceForm.reset()
        },
      }),
    )
  }

  useEffect(() => {
    if (user?.userType) {
      getServices()
      getSubject()
    }
  }, [user])

  const handleAddSubject = (data) => {
    if (data.subject.trim() === "") {
      subjectForm.setError("subject", {
        type: "manual",
        message: "Subject name is required",
      })
      return
    }
    if (data.service === "") {
      subjectForm.setError("service", {
        type: "manual",
        message: "Service is required",
      })
      return
    }
    if (data?._id) {
      delete data._id
    }
    dispatch(
      createSubjectAction({
        userType,
        data: data,
        cb: () => {
          getSubject()
          setIsSubjectDialogOpen(false)
          setEditingSubject(null)
          subjectForm.reset()
        },
      }),
    )
  }

  const handleDeleteService = (id) => {
    if (window.confirm("Are you sure you want to delete this service?")) {
      dispatch(
        deleteServicesAction({
          userType,
          id,
          cb: () => {
            getServices()
          },
        }),
      )
    }
  }

  const handleDeleteSubject = (id) => {
    if (window.confirm("Are you sure you want to delete this subject?")) {
      dispatch(deleteSbjectsAction({ id, userType: user?.userType, cb: getSubject }))
    }
  }

  const handleUpdateService = (id) => {
    const serviceName = serviceForm.getValues().serviceName
    if (serviceName.trim() === "") {
      serviceForm.setError("serviceName", {
        type: "manual",
        message: "Service name is required",
      })
      return
    }

    dispatch(
      updateServicesAction({
        userType,
        data: {
          id,
          serviceName,
        },
        cb: () => {
          setEditingService(null)
          getServices()
        },
      }),
    )
  }

  const handleUpdateSubject = (id) => {
    const data = subjectForm.getValues()
    if (data.subject.trim() === "") {
      subjectForm.setError("subject", {
        type: "manual",
        message: "Subject name is required",
      })
      return
    }
    if (data.service === "") {
      subjectForm.setError("service", {
        type: "manual",
        message: "Service is required",
      })
      return
    }
    data.service = data.service?._id || data.service

    dispatch(
      updateSubjectAction({
        userType: user?.userType?.toLowerCase(),
        data,
        cb: () => {
          setEditingSubject(null)
          getSubject()
        },
      }),
    )
  }

  return (
    <DashboardLayout>
      <div className="min-h-screen bg-gray-50 p-6">
        <div className="max-w-[1400px] mx-auto space-y-6">
          {/* Header */}
          <div className="bg-[#00234a] text-white p-6 rounded-lg shadow-lg">
            <h1 className="text-2xl font-bold text-white">Assignment Services Management</h1>
            <p className="text-gray-300 mt-2">Manage your academic services, subjects, and pricing</p>
          </div>

          {/* Controls */}
          <div className="flex flex-col sm:flex-row gap-4 justify-between items-center bg-white p-4 rounded-lg shadow">
            <div className="relative w-full sm:w-96">
              <input
                type="text"
                placeholder="Search services or subjects..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg"
              />
              <svg
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
          </div>

          {/* Tabs */}
          <div className="bg-white rounded-lg shadow">
            <div className="flex border-b">
              <button
                className={`flex-1 py-2 px-4 text-center ${
                  activeTab === "services"
                    ? "border-b-2 border-[#00234a] text-[#00234a] font-semibold"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("services")}
              >
                Services
              </button>
              <button
                className={`flex-1 py-2 px-4 text-center ${
                  activeTab === "subjects"
                    ? "border-b-2 border-[#00234a] text-[#00234a] font-semibold"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("subjects")}
              >
                Subjects
              </button>
            </div>

            {/* Services Tab Content */}
            {activeTab === "services" && (
              <div className="p-4">
                <div className="flex justify-end mb-4">
                  <button
                    className="bg-[#00234a] text-white px-4 py-2 rounded hover:bg-[#003a7a]"
                    onClick={() => {
                      subjectForm.reset()
                      setIsServiceDialogOpen(true)
                    }}
                  >
                    Add New Service
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse text-sm">
                    <thead>
                      <tr className="bg-[#00234a] text-white">
                        <th className="px-4 py-3 text-left">Service Name</th>
                        <th className="px-4 py-3 text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {filteredServices?.map((service) => (
                        <tr key={service._id} className="hover:bg-gray-50 transition-colors">
                          <td className="px-4 py-2">
                            {editingService === service._id ? (
                              <input
                                type="text"
                                {...serviceForm.register("serviceName", {
                                  required: "Service name is required",
                                })}
                                defaultValue={service.serviceName}
                                className="w-full px-2 py-1 border rounded"
                              />
                            ) : (
                              service.serviceName
                            )}
                          </td>
                          <td className="px-4 py-2">
                            <div className="flex justify-center gap-2">
                              {editingService === service._id ? (
                                <>
                                  <button
                                    className="bg-green-600 text-white px-2 py-1 rounded text-sm hover:bg-green-700"
                                    onClick={() => handleUpdateService(service._id)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="border border-gray-300 px-2 py-1 rounded text-sm hover:bg-gray-100"
                                    onClick={() => setEditingService(null)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="border border-gray-300 px-2 py-1 rounded text-sm hover:bg-gray-100"
                                    onClick={() => {
                                      setEditingService(service._id)
                                      serviceForm.setValue("serviceName", service.serviceName)
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="bg-red-600 text-white px-2 py-1 rounded text-sm hover:bg-red-700"
                                    onClick={() => handleDeleteService(service._id)}
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {filteredServices?.length === 0 && (
                  <div className="text-center py-8 text-gray-500">No services found matching your search criteria</div>
                )}
              </div>
            )}

            {/* Subjects Tab Content */}
            {activeTab === "subjects" && (
              <div className="p-4">
                <div className="flex justify-end mb-4">
                  <button
                    className="bg-[#00234a] text-white px-4 py-2 rounded hover:bg-[#003a7a]"
                    onClick={() => setIsSubjectDialogOpen(true)}
                  >
                    Add New Subject
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse text-sm">
                    <thead>
                      <tr className="bg-[#00234a] text-white">
                        <th className="px-4 py-3 text-left">Subject Name</th>
                        <th className="px-4 py-3 text-left">Service</th>
                        <th className="px-4 py-3 text-right">Service Charge</th>
                        <th className="px-4 py-3 text-right">QC Charge</th>
                        <th className="px-4 py-3 text-right">Page Per Word Charge</th>
                        <th className="px-4 py-3 text-right">Urgency Charge</th>
                        <th className="px-4 py-3 text-right">Off Price</th>
                        <th className="px-4 py-3 text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {filteredSubjects?.map((subject) => (
                        <tr key={subject._id} className="hover:bg-gray-50 transition-colors">
                          <td className="px-4 py-2">
                            {editingSubject === subject._id ? (
                              <input
                                type="text"
                                {...subjectForm.register("subject", {
                                  required: "Subject name is required",
                                })}
                                defaultValue={subject.subject}
                                className="w-full px-2 py-1 border rounded"
                              />
                            ) : (
                              subject.subject
                            )}
                          </td>
                          <td className="px-4 py-2">
                            {editingSubject === subject._id ? (
                              <select
                                {...subjectForm.register("service", {
                                  required: "Service is required",
                                })}
                                defaultValue={subject.service._id}
                                className="w-full px-2 py-1 border rounded"
                              >
                                {services?.map((service) => (
                                  <option key={service._id} value={service._id}>
                                    {service.serviceName}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              subject.service.serviceName
                            )}
                          </td>
                          <td className="px-4 py-2 text-right">
                            {editingSubject === subject._id ? (
                              <input
                                type="number"
                                {...subjectForm.register("serviceCharge", {
                                  required: "Service charge is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={subject.serviceCharge}
                                className="w-full px-2 py-1 border rounded text-right"
                              />
                            ) : (
                              `${subject.serviceCharge}%`
                            )}
                          </td>
                          <td className="px-4 py-2 text-right">
                            {editingSubject === subject._id ? (
                              <input
                                type="number"
                                {...subjectForm.register("qcCharge", {
                                  required: "QC charge is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={subject.qcCharge}
                                className="w-full px-2 py-1 border rounded text-right"
                              />
                            ) : (
                              `${subject.qcCharge}%`
                            )}
                          </td>
                          <td className="px-4 py-2 text-right">
                            {editingSubject === subject._id ? (
                              <input
                                type="number"
                                step="0.01"
                                {...subjectForm.register("pagePerWordCharge", {
                                  required: "Page per word charge is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={subject.pagePerWordCharge}
                                className="w-full px-2 py-1 border rounded text-right"
                              />
                            ) : (
                              subject.pagePerWordCharge
                            )}
                          </td>
                          <td className="px-4 py-2 text-right">
                            {editingSubject === subject._id ? (
                              <input
                                type="number"
                                {...subjectForm.register("urgencyCharge", {
                                  required: "Urgency charge is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={subject.urgencyCharge}
                                className="w-full px-2 py-1 border rounded text-right"
                              />
                            ) : (
                              `${subject.urgencyCharge}%`
                            )}
                          </td>
                          <td className="px-4 py-2 text-right">
                            {editingSubject === subject._id ? (
                              <input
                                type="number"
                                {...subjectForm.register("offPrice", {
                                  required: "Off price is required",
                                  min: {
                                    value: 0,
                                    message: "Must be a positive number",
                                  },
                                })}
                                defaultValue={subject.offPrice}
                                className="w-full px-2 py-1 border rounded text-right"
                              />
                            ) : (
                              `INR ${subject.offPrice}`
                            )}
                          </td>
                          <td className="px-4 py-2">
                            <div className="flex justify-center gap-2">
                              {editingSubject === subject._id ? (
                                <>
                                  <button
                                    className="bg-green-600 text-white px-2 py-1 rounded text-sm hover:bg-green-700"
                                    onClick={() => handleUpdateSubject(subject._id)}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="border border-gray-300 px-2 py-1 rounded text-sm hover:bg-gray-100"
                                    onClick={() => setEditingSubject(null)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="border border-gray-300 px-2 py-1 rounded text-sm hover:bg-gray-100"
                                    onClick={() => {
                                      setEditingSubject(subject._id)
                                      subjectForm.reset(subject)
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="bg-red-600 text-white px-2 py-1 rounded text-sm hover:bg-red-700"
                                    onClick={() => handleDeleteSubject(subject._id)}
                                  >
                                    Delete
                                  </button>
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {filteredSubjects.length === 0 && (
                  <div className="text-center py-8 text-gray-500">No subjects found matching your search criteria</div>
                )}
              </div>
            )}
          </div>

          {/* Add Service Dialog */}
          {isServiceDialogOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Add New Service</h2>
                <form onSubmit={serviceForm.handleSubmit(handleAddService)} className="space-y-4">
                  <div>
                    <label htmlFor="serviceName" className="block text-sm font-medium text-gray-700">
                      Service Name
                    </label>
                    <input
                      type="text"
                      id="serviceName"
                      {...serviceForm.register("serviceName", {
                        required: "Service name is required",
                      })}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    />
                    {serviceForm.formState.errors.serviceName && (
                      <p className="mt-1 text-sm text-red-600">{serviceForm.formState.errors.serviceName.message}</p>
                    )}
                  </div>
                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
                      onClick={() => setIsServiceDialogOpen(false)}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="px-4 py-2 bg-[#00234a] text-white rounded hover:bg-[#003a7a]">
                      Add Service
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* Add Subject Dialog */}
          {isSubjectDialogOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-y-auto">
              <div className="bg-white p-6 rounded-lg w-full max-w-2xl m-4">
                <h2 className="text-2xl font-bold mb-6 text-[#00234a]">Add New Subject</h2>
                <form onSubmit={subjectForm.handleSubmit(handleAddSubject)} className="space-y-6">
                 
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                
                  
                    <div>
                      <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                        Subject Name
                      </label>
                      <input
                        type="text"
                        id="subject"
                        {...subjectForm.register("subject", {
                          required: "Subject name is required",
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.subject && (
                        <p className="mt-1 text-sm text-red-600">{subjectForm.formState.errors.subject.message}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-1">
                        Service
                      </label>
                      <select
                        id="service"
                        {...subjectForm.register("service", {
                          required: "Service is required",
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      >
                        <option value="">Select a service</option>
                        {services?.map((service) => (
                          <option key={service._id} value={service._id}>
                            {service.serviceName}
                          </option>
                        ))}
                      </select>
                      {subjectForm.formState.errors.service && (
                        <p className="mt-1 text-sm text-red-600">{subjectForm.formState.errors.service.message}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="serviceCharge" className="block text-sm font-medium text-gray-700 mb-1">
                        Service Charge (%)
                      </label>
                      <input
                        type="number"
                        id="serviceCharge"
                        {...subjectForm.register("serviceCharge", {
                          required: "Service charge is required",
                          min: { value: 0, message: "Must be a positive number" },
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.serviceCharge && (
                        <p className="mt-1 text-sm text-red-600">
                          {subjectForm.formState.errors.serviceCharge.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="qcCharge" className="block text-sm font-medium text-gray-700 mb-1">
                        QC Charge (%)
                      </label>
                      <input
                        type="number"
                        id="qcCharge"
                        {...subjectForm.register("qcCharge", {
                          required: "QC charge is required",
                          min: { value: 0, message: "Must be a positive number" },
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.qcCharge && (
                        <p className="mt-1 text-sm text-red-600">{subjectForm.formState.errors.qcCharge.message}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="pagePerWordCharge" className="block text-sm font-medium text-gray-700 mb-1">
                        Page Per Word Charge
                      </label>
                      <input
                        type="number"
                        step="0.01"
                        id="pagePerWordCharge"
                        {...subjectForm.register("pagePerWordCharge", {
                          required: "Page per word charge is required",
                          min: { value: 0, message: "Must be a positive number" },
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.pagePerWordCharge && (
                        <p className="mt-1 text-sm text-red-600">
                          {subjectForm.formState.errors.pagePerWordCharge.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="urgencyCharge" className="block text-sm font-medium text-gray-700 mb-1">
                        Urgency Charge (%)
                      </label>
                      <input
                        type="number"
                        id="urgencyCharge"
                        {...subjectForm.register("urgencyCharge", {
                          required: "Urgency charge is required",
                          min: { value: 0, message: "Must be a positive number" },
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.urgencyCharge && (
                        <p className="mt-1 text-sm text-red-600">
                          {subjectForm.formState.errors.urgencyCharge.message}
                        </p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="offPrice" className="block text-sm font-medium text-gray-700 mb-1">
                        Off Price
                      </label>
                      <input
                        type="number"
                        id="offPrice"
                        {...subjectForm.register("offPrice", {
                          required: "Off price is required",
                          min: { value: 0, message: "Must be a positive number" },
                        })}
                        className="w-full border border-gray-300 rounded-md shadow-sm p-2 focus:ring-[#00234a] focus:border-[#00234a]"
                      />
                      {subjectForm.formState.errors.offPrice && (
                        <p className="mt-1 text-sm text-red-600">{subjectForm.formState.errors.offPrice.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end gap-4 mt-6">
                    <button
                      type="button"
                      className="px-6 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                      onClick={() => setIsSubjectDialogOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-6 py-2 bg-[#00234a] text-white rounded-md hover:bg-[#003a7a] transition-colors"
                    >
                      Add Subject
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  )
}

