import { Tooltip } from "@mui/material";
import LeadActionButtons from "../LeadActionButtons";
import {
  isMissedExpertDeadline,
  returnDateWithTime,
  truncateString,
} from "../../../utils/functions/functions";
import ExpertRequestOption from "./expert/ExpertRequestOption";
import Buttons from "../../../utils/buttons/Buttons";
import { Fragment } from "react";
import { Eye } from "lucide-react";

export const ColumnOrderList = (
  startChat,
  afterSave,
  setShowExpertAssign,
  isOpenLeadOnly,
  user,
  setClientId,
  setRequestLeadId,
  removeFromRequestExpert,
  isMangerOrAdmin
) => {
  const isMangerOrOperator =
    user?.userType === "OPERATOR" ||
    user?.userType === "MANAGER" ||
    user?.userType === "ADMIN" ||
    user?.userType === "SUPER_ADMIN";
  const isAdmin =
    user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN";
  const isManager = user?.userType === "MANAGER";
  const isExpert = user?.userType === "EXPERT";
  
  const isMissed = (row) => {
    return isMissedExpertDeadline(row?.expertDeadline, row?.orderStatus)
      ? "text-danger"
      : "";
  };

  const columns = [
    {
      name: "",
      selector: (row) => row.orderId, // Provide something sortable
      cell: (row) => (
        <LeadActionButtons
          page="order"
          afterSave={afterSave}
          lead={row}
          isOpenLeadOnly={isOpenLeadOnly}
          setShowExpertAssign={setShowExpertAssign}
        />
      ),
      width: "59px",
      sortable: true,
    },
    {
      name: "Order Id",
      selector: (row) => row?.orderId || "NA",
      cell: (row) => (
        <td
          className={`pointer ${isMissed(row)}`}
          onClick={() => {
            !isOpenLeadOnly &&
              startChat({ leadDetail: row, userId: user?._id });
          }}
        >
          {row?.orderId || "NA"}{" "}
        </td>
      ),
      width: "100px",
      sortable: true,
    },
    isMangerOrOperator && {
      name: "Client",
      selector: (row) => row?.client?.name || "NA", // Return name for sorting
      cell: (row) => (
        <td
          className={`pointer ${isMissed(row)}`}
          onClick={() =>isMangerOrAdmin && setClientId(row?.client)}
        >
          {row?.client?.name}
        </td>
      ),
      width: "119px",
      sortable: true,
    },
    {
      name: "Subject",
      selector: (row) => row?.subject || "NA", // Return string for sorting
      cell: (row) => (
        <span className={isMissed(row)}>
          <Tooltip title={row?.subject}>
            {truncateString(row?.subject, 12)}
          </Tooltip>
        </span>
      ),
      sortable: true,
      width: "124px",
    },
    {
      name: "Status",
      selector: (row) => row?.orderStatus || "NA", // Return status for sorting
      cell: (row) => (
        <ul className={`chip-row show custom-chip text-center`}>
          <li className={`chip-child ${row.orderStatus}`}>
            {`${row.orderStatus}${
              row?.orderStatus === "DELIVERED" && row?.paymentType !== "FULL"
                ? "(P)"
                : ""
            }`}
          </li>
        </ul>
      ),
      center: true,
      width: "159px",
      sortable: true,
    },
    {
      name: isMangerOrOperator ? "Client Deadline" : "Deadline",
      selector: (row) =>
        user?.userType === "EXPERT"
          ? row?.expertDeadline
          : row?.clientDeadline,
      cell: (row) => (
        <Tooltip
          title={returnDateWithTime(
            user?.userType === "EXPERT"
              ? row?.expertDeadline
              : row?.clientDeadline
          )}
        >
          {returnDateWithTime(
            user?.userType === "EXPERT"
              ? row?.expertDeadline
              : row?.clientDeadline
          )}
        </Tooltip>
      ),
      width: "179px",
      sortable: true,
    },
    isMangerOrOperator && {
      name: "Expert Deadline",
      selector: (row) => row?.expertDeadline || "NA",
      cell: (row) => (
        <Tooltip title={returnDateWithTime(row?.expertDeadline)}>
          {returnDateWithTime(row?.expertDeadline)}
        </Tooltip>
      ),
      width: "179px",
      sortable: true,
    },
    isMangerOrOperator && {
      name: "Expert",
      selector: (row) => row?.expert?.name || "NA",
      cell: (row) =>
        row?.expert?.name || (
          <ExpertRequestOption
            lead={row}
            leadId={row?._id}
            expertRequest={row?.expertRequest}
            afterSave={afterSave}
          />
        ),
      width: "139px",
      sortable: true,
    },
    (isAdmin || isManager) && {
      name: "Generate By",
      selector: (row) => row?.createdBy?.name || "NA",
      width: "139px",
      sortable: true,
    },
    isExpert &&
      isOpenLeadOnly && {
        name: "Expert",
        selector: (row) => row?.expertRequest || "NA",
        cell: (row) => (
          <Buttons
            onClick={() => row?.expertRequest?removeFromRequestExpert(row): setRequestLeadId(row)}
            title={
              row?.expertRequest ? "Cancel Request" : "Request For Assign"
            }
          />
        ),
        width: "200px",
        sortable: true,
      },
      isMangerOrOperator && {
        name: "Created At",
        selector: (row) => row?.createdAt || "NA",
        cell: (row) => (
          <Tooltip title={returnDateWithTime(row?.createdAt)}>
            {returnDateWithTime(row?.createdAt)}
          </Tooltip>
        ),
        width: "179px",
        sortable: true,
      },

      isExpert && {
        name: "Amount",
        selector: (row) => row?.expertAmount || "NA",
        cell: (row) => row?.expertAmount+ " INR",
        width: "100px",
        sortable: true,
      },
      (isExpert || isMangerOrOperator) && {
        name: "Penalty",
        selector: (row) => row?.expertAmount || "NA",
        cell: (row) => (
          <Fragment>
           {row?.penality?.amount || 0 + " INR"}
           {row?.penality?.reason && <Tooltip title={row?.penality?.reason}>
           <Eye size={15} style={{marginLeft:4,cursor:"pointer"}} />
           </Tooltip>}
          </Fragment>
        ),
        width: "100px",
        sortable: true,
      },
      isExpert && {
        name: "Client",
        selector: (row) => row?.client?.name || "NA",
        cell: (row) => row?.client?.name,
        width: "200px",
        sortable: true,
      },
      isExpert && {
        name: "Word Count",
        selector: (row) => row?.wordCount || "NA",
        cell: (row) => row?.wordCount,
        width: "100px",
        sortable: true,
      },
  ];

  return columns?.filter(Boolean);
};
