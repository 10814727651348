/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ClientHeader from "../../../components/layout-new/HeaderNew";
import {
  createClientOrderAction,
  getServicesAction,
  getSubjectByCategoryAction,
  getSubjectPricingAction,
} from "../../../services/action/common";
import ProgressSteps from "./order-creation/ProgressSteps";
import BenefitsSection from "./order-creation/BenefitsSection";
import SeasonalOfferBanner from "./order-creation/SeasonalOfferBanner";
import OrderSummary from "./order-creation/OrderSummary";
import WriterSelectionStep from "./order-creation/WriterSelectionStep";
import FormFields from "./order-creation/FormFields";
import { Toaster } from "../../../components/Toaster";
// const DUMMY_COUPON = "EXTRA25";

export default function NewOrders({leadDetails}) {
  const [currentStep, setCurrentStep] = useState(1);
  const [couponCode, setCouponCode] = useState("");
  const { user } = useSelector((state) => state.authenticationReducer);
  const { subjectsCategory, services } = useSelector(
    (state) => state.commonReducer
  );
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      service: "",
      serviceName: "",
      subject: "",
      subjectName: "",
      pages: 1,
      urgency: "30days",
      writerLevel: "silver",
      handPickedWriter: false,
      requestPayment: "FULL",
    },
  });

  const onSubmit = (data) => {
    dispatch(
      createClientOrderAction({
        userType: user?.userType?.toLowerCase(),
        data,
        cb: (res) => {
          window.open(res?.url);
        },
      })
    );
  };

  const getSubjectBySubjectId = ({ serviceId }) => {
    if (!serviceId) {
      return null;
    }
    dispatch(getSubjectByCategoryAction({ data: { serviceId } }));
  };

  const getWatch = watch();
  const getPricing = () => {
    if (getWatch?.subject) {
      let payload = {
        subjectId: getWatch?.subject,
        time: getWatch?.urgency,
        couponCode: couponCode,
        pages: getWatch?.pages,
        plan: getWatch?.writerLevel,
        step: 2,
      };
      dispatch(getSubjectPricingAction({ data: payload }));
    }
  };

  useEffect(() => {
    getPricing();
  }, [
    getWatch?.writerLevel,
    getWatch?.subject,
    getWatch?.urgency,
    couponCode,
    getWatch?.pages,
  ]);

  useEffect(() => {
    dispatch(getServicesAction({}));
  }, [dispatch]);

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleApplyCouponCode = () => {
    if (!getWatch?.subject) {
      Toaster.error("Please select subject");
    } else {
      setCouponCode("SUMMER2025");
    }
  };

  return (
    <Fragment>
      {/* <ExpertAvatarLoader message={loadingMessage} isLoading={true}/> */}
      {/* <LoaderAnimatedAssignment isLoading={true} /> */}

      {!leadDetails && <ClientHeader />}
      <div className={`min-h-screen bg-gray-50 py-2 ${leadDetails?"mt-2":""}`}>
        {!leadDetails && <SeasonalOfferBanner handleApplyCouponCode={handleApplyCouponCode} />}

        <div className="max-w-6xl mx-auto mb-8 px-4">
          <ProgressSteps currentStep={currentStep} />
        </div>

        <div className="max-w-6xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="lg:col-span-2">
              <div className="bg-white rounded-lg shadow-sm p-6">
                {currentStep === 1 ? (
                  <FormFields
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                    services={services}
                    setValue={setValue}
                    getSubjectBySubjectId={getSubjectBySubjectId}
                    subjectsCategory={subjectsCategory}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    setCurrentStep={setCurrentStep}
                    leadDetails={leadDetails}
                  />
                ) : (
                  <WriterSelectionStep
                    setValue={setValue}
                    handleBack={handleBack}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    register={register}
                    errors={errors}
                    watch={watch}
                  />
                )}
              </div>
            </div>

            <div className="lg:col-span-1">
              <OrderSummary
                // couponApplied={couponApplied}
                watch={watch}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                currentStep={currentStep}
                // handleApplyCoupon={handleApplyCoupon}
                formState={formState}
                setCurrentStep={setCurrentStep}
                onSubmit={onSubmit}
                setValue={setValue}
                handleSubmit={handleSubmit}
                leadDetails={leadDetails}
              />
            </div>
          </div>

          <BenefitsSection />
        </div>
      </div>
    </Fragment>
  );
}
