import  React from "react"
import { useState } from "react"
import { X } from "lucide-react"

const PaymentConfirmation = ({onDone,setValue, isOpen, onClose, totalAmount }) => {
  const [paymentType, setPaymentType] = useState("FULL")
  const [partialAmount, setPartialAmount] = useState("")
  const [error, setError] = useState("")

  if (!isOpen) return null

  const handlePartialAmountChange = (e) => {
    const value = e.target.value

    setValue("requestPayment",value)
    setPartialAmount(value)
    const numericTotal = Number.parseFloat(totalAmount?.replace(/[^0-9.-]+/g, ""))
    
    if (Number.parseFloat(value) < numericTotal * 0.5) {
      setError("Partial payment must be at least 50% of the total amount")
    } else {
      setError("")
    }
  }

  const handleSubmit = () => {
    if (paymentType === "PARTIAL" && error) {
      return
    }
    onDone()
    // Handle payment submission logic here
    // onClose(paymentType === "FULL"? paymentType: partialAmount)
  }

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[9999] p-4">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Confirm Payment</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Payment Type</label>
            <select
              value={paymentType}
              onChange={(e) => {
                setValue("requestPayment",e.target.value)
                setPaymentType(e.target.value)}}
              className="w-full border border-gray-300 rounded-md p-2"
            >
              <option value="FULL">Full Payment</option>
              <option value="PARTIAL">Partial Payment</option>
            </select>
          </div>
          {paymentType === "PARTIAL" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Partial Amount</label>
              <input
                type="number"
                value={partialAmount}
                onChange={handlePartialAmountChange}
                className="w-full border border-gray-300 rounded-md p-2"
                placeholder="Enter amount"
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>
          )}
          <div className="text-right">
            <span className="font-medium">Total Amount: {totalAmount}</span>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-6"
        >
          Confirm Payment
        </button>
      </div>
    </div>
  )
}

export default PaymentConfirmation

