"use client"

import { useState, useRef, useCallback, useEffect } from "react"
import { X, User, History, ChevronRight, Search, Eye, Filter } from "lucide-react"
import { useSelector } from "react-redux"
import UserDetailsModal from "./UserDetailsModal"
import ClientReport from "../client-report/ClientReport"

const OrderListReport = ({
  amountType,
  selectedMetric,
  metrics,
  fetchOrderList,
  setPageData,
  pageData,
  setSearch,
  search,
  selectedDateFilter,
  handleDateFilterChange,
  isOpenModal = false,
  onClose,
  setSelectedMetric
}) => {
  const { reports, orderListReport } = useSelector((state) => state.commonReducer)
  const [expandedHistory, setExpandedHistory] = useState(null)
  // const [selectedUser, setSelectedUsers] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)
  const [visibleColumns, setVisibleColumns] = useState({
    source: true,
    orderID: true,
    status: true,
    client: true,
    expert: true,
    orderDate: true,
    deadline: true,
    orderAmount: true,
    paymentRecieved: true,
    balanceAmount: true,
    expertAmount: true,
  })
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState("TOTAL")
  const [selectedClientType, setSelectedClientType] = useState("All")
  const [isFiltersOpen, setIsFiltersOpen] = useState(false)
  const tableRef = useRef(null)

  const observer = useRef()
  const lastOrderElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && orderListReport?.orders.length < orderListReport?.totalResults) {
          fetchMoreOrders({
            orderStatus: selectedStatus,
            clientType: selectedClientType,
          })
        }
      })
      if (node) observer.current.observe(node)
    },
    [orderListReport?.orders.length, orderListReport?.totalResults, selectedClientType, selectedStatus],
  )

  const fetchMoreOrders = () => {
    fetchOrderList({
      page: pageData.page + 1,
      orderStatus: selectedStatus,
      clientType: selectedClientType,
    })
    setPageData((prev) => ({ ...prev, page: prev.page + 1 }))
  }

  const toggleColumnVisibility = (column) => {
    if (["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column)) {
      return // These columns cannot be toggled when any of them is selected
    }
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }))
  }

  const isColumnVisible = (column) => {
    if (["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(amountType || selectedMetric)) {
      return (
        ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) || visibleColumns[column]
      )
    }
    return visibleColumns[column]
  }

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case "DELIVERED":
        return "bg-[#00FF9D]/10 text-[#00FF9D] border border-[#00FF9D]/20"
      case "ASSIGNED":
        return "bg-[#9D00FF]/10 text-[#9D00FF] border border-[#9D00FF]/20"
      case "IN PROGRESS":
        return "bg-[#00A3FF]/10 text-[#00A3FF] border border-[#00A3FF]/20"
      case "PENDING":
        return "bg-[#FFD100]/10 text-[#FFD100] border border-[#FFD100]/20"
      default:
        return "bg-gray-500/10 text-gray-400 border border-gray-500/20"
    }
  }

  const handleUserClick = (userId) => {
    setSelectedUser(userId)
  }

  const statusOptions = [
    "All",
    "OPEN",
    "ASSIGNED",
    "UNDERQC",
    "DELIVERED",
    "REOPEN",
    "ONHOLD",
    "REFUND",
    "MISSED_DEADLINE",
  ]
  const clientTypeOptions = ["All", "REPEAT", "PAID", "REFERAL", "ORGANIC"]

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".filters-container")) {
        setIsFiltersOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    (isOpenModal || selectedMetric) && (
      <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-start justify-center z-50 p-2 overflow-y-auto">
        <div className="bg-[#001b3d] rounded-lg w-full max-w-[98vw] mx-auto relative shadow-2xl mt-2">
          {/* Header */}
          <div className="p-2 border-b border-blue-800/30 bg-blue-900/20">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <div className="p-1 bg-blue-500/20 rounded-lg border border-blue-500/30">
                  <User className="h-3 w-3 text-blue-400" />
                </div>
                <h2 className="text-sm font-semibold text-white">
                  {metrics(reports?.current)?.find((m) => m.id === selectedMetric)?.title} Details
                </h2>
              </div>
              <button onClick={onClose} className="p-1 hover:bg-blue-800/30 rounded-lg transition-colors">
                <X className="h-3 w-3 text-white/70 hover:text-white" />
              </button>
            </div>

            {/* Filters */}
            <div className="flex flex-wrap gap-2 items-center">
              <div className="relative flex-1 min-w-[200px]">
                <Search className="absolute left-2 top-1/2 -translate-y-1/2 h-3 w-3 text-blue-300/50" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    fetchOrderList({
                      tempSearch: e.target.value,
                      firstTime: true,
                      page: 1,
                    })
                    setSearch(e.target.value)
                    setPageData({ page: 1 })
                  }}
                  placeholder="Search orders..."
                  className="w-full pl-7 pr-2 py-1 text-xs rounded-md bg-blue-800/20 border border-blue-700/30 text-white placeholder-blue-300/50 focus:outline-none focus:border-blue-500/50"
                />
              </div>
              <div className="flex gap-1 flex-wrap">
                {["all", "today", "yesterday", "previous7days"].map((filter) => (
                  <button
                    key={filter}
                    onClick={() => handleDateFilterChange(filter)}
                    className={`px-2 py-1 rounded-md text-xs font-medium ${
                      selectedDateFilter === filter
                        ? "bg-blue-600/80 text-white"
                        : "bg-blue-800/20 text-blue-200 hover:bg-blue-700/30 hover:text-white"
                    }`}
                  >
                    {filter.charAt(0).toUpperCase() + filter.slice(1).replace(/([A-Z])/g, " $1")}
                  </button>
                ))}
              </div>
              <div className="relative filters-container">
                <button
                  className="px-2 py-1 rounded-md text-xs font-medium bg-blue-800/20 text-blue-200 hover:bg-blue-700/30 hover:text-white flex items-center gap-1"
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                >
                  <Filter className="h-3 w-3" />
                  Filters
                </button>
                {isFiltersOpen && (
                  <div className="absolute top-full right-0 mt-1 bg-blue-900 border border-blue-700/30 rounded-md shadow-lg z-10 w-48">
                    <div className="p-2">
                      <label className="block text-xs text-blue-200 mb-1">Status</label>
                      <select
                        value={selectedStatus}
                        onChange={(e) => {
                          fetchOrderList({
                            orderStatus: e.target.value,
                            page: 1,
                            firstTime: true,
                          })
                          setSelectedStatus(e.target.value)
                          setPageData({ page: 1 })
                        }}
                        className="w-full bg-blue-800/20 text-white text-xs py-1 px-2 rounded"
                      >
                        {statusOptions.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="p-2 border-t border-blue-700/30">
                      <label className="block text-xs text-blue-200 mb-1">Client Type</label>
                      <select
                        value={selectedClientType}
                        onChange={(e) => {
                          fetchOrderList({
                            clientType: e.target.value,
                            page: 1,
                            firstTime: true,
                          })
                          setSelectedClientType(e.target.value)
                          setPageData({ page: 1 })
                        }}
                        className="w-full bg-blue-800/20 text-white text-xs py-1 px-2 rounded"
                      >
                        {clientTypeOptions.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full p-2 bg-blue-900/20 border-b border-blue-800/30">
            <div className="flex items-center gap-2 overflow-x-auto">
              <span className="text-xs text-blue-200 whitespace-nowrap">Toggle columns:</span>
              <div className="flex flex-wrap gap-1">
                {Object.keys(visibleColumns).map((column) => (
                  <button
                    key={column}
                    onClick={() => toggleColumnVisibility(column)}
                    className={`px-2 py-0.5 rounded text-[10px] ${
                      isColumnVisible(column) ? "bg-blue-600/50 text-white" : "bg-blue-800/20 text-blue-300"
                    } ${
                      ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) &&
                      ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(
                        amountType || selectedMetric,
                      )
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-700/30"
                    }`}
                    disabled={
                      ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) &&
                      ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(
                        amountType || selectedMetric,
                      )
                    }
                  >
                    {column.charAt(0).toUpperCase() + column.slice(1).replace(/([A-Z])/g, " $1")}
                  </button>
                ))}
              </div>
            </div>
            <span className="text-xs text-blue-200 whitespace-nowrap">
              Total Records: {orderListReport?.totalResults || 0}
            </span>
          </div>

          {/* Table Container */}
          <div
            className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent"
            ref={tableRef}
          >
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-blue-900/30">
                  <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                    #
                  </th>
                  {isColumnVisible("orderID") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Order ID
                    </th>
                  )}
                  {isColumnVisible("status") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Status
                    </th>
                  )}
                  {isColumnVisible("client") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Client
                    </th>
                  )}
                  {isColumnVisible("expert") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Expert
                    </th>
                  )}
                  {isColumnVisible("orderDate") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Order Date
                    </th>
                  )}
                  {isColumnVisible("deadline") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Deadline
                    </th>
                  )}
                  {isColumnVisible("orderAmount") && (
                    <th
                      className={`py-1.5 px-2 text-right text-[10px] font-medium border-b border-blue-800/30 whitespace-nowrap ${
                        (amountType || selectedMetric) === "orderAmount" ? "bg-blue-600/50 text-white" : "text-blue-200"
                      }`}
                    >
                      Total
                    </th>
                  )}
                  {isColumnVisible("paymentRecieved") && (
                    <th
                      className={`py-1.5 px-2 text-right text-[10px] font-medium border-b border-blue-800/30 whitespace-nowrap ${
                        (amountType || selectedMetric) === "paymentRecieved"
                          ? "bg-blue-600/50 text-white"
                          : "text-blue-200"
                      }`}
                    >
                      Received
                    </th>
                  )}
                  {isColumnVisible("balanceAmount") && (
                    <th
                      className={`py-1.5 px-2 text-right text-[10px] font-medium border-b border-blue-800/30 whitespace-nowrap ${
                        (amountType || selectedMetric) === "balanceAmount"
                          ? "bg-blue-600/50 text-white"
                          : "text-blue-200"
                      }`}
                    >
                      Balance
                    </th>
                  )}
                  {isColumnVisible("expertAmount") && (
                    <th
                      className={`py-1.5 px-2 text-right text-[10px] font-medium border-b border-blue-800/30 whitespace-nowrap ${
                        (amountType || selectedMetric) === "expertAmount"
                          ? "bg-blue-600/50 text-white"
                          : "text-blue-200"
                      }`}
                    >
                      Expert Amount
                    </th>
                  )}
                  {isColumnVisible("source") && (
                    <th className="py-1.5 px-2 text-left text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                      Source
                    </th>
                  )}
                  <th className="py-1.5 px-2 text-center text-[10px] font-medium text-blue-200 border-b border-blue-800/30 whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-blue-800/30">
                {orderListReport?.orders.map((order, index) => (
                  <tr
                    key={order.id}
                    ref={index === orderListReport.orders.length - 1 ? lastOrderElementRef : null}
                    className={`hover:bg-blue-800/20 transition-colors ${
                      order.isMissedExpertDeadline ? "bg-red-500/20" : ""
                    }`}
                    onMouseEnter={() => setHoveredRow(order.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <td className="py-1.5 px-2 text-[10px] text-blue-200 align-middle whitespace-nowrap">
                      {index + 1}
                    </td>
                    {isColumnVisible("orderID") && (
                      <td className="py-1.5 px-2 text-[10px] text-white/90 font-medium align-middle whitespace-nowrap">
                        {order.id}
                      </td>
                    )}
                    {isColumnVisible("status") && (
                      <td className="py-1.5 px-2 align-middle whitespace-nowrap">
                        <span
                          className={`px-2 py-0.5 rounded-md text-[10px] font-medium inline-block ${getStatusColor(
                            order.status,
                          )}`}
                        >
                          {order.status}
                        </span>
                      </td>
                    )}
                    {isColumnVisible("client") && (
                      <td className="py-1.5 px-2 align-middle whitespace-nowrap">
                        <button
                          onClick={() => handleUserClick(order?.clientId)}
                          className="text-[10px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                        >
                          {order.customer}
                          <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                        </button>
                      </td>
                    )}
                    {isColumnVisible("expert") && (
                      <td className="py-1.5 px-2 align-middle whitespace-nowrap">
                        <button
                          onClick={() => handleUserClick(order.expert?.id)}
                          className="text-[10px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                        >
                          {order.expert.name}
                          <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                        </button>
                      </td>
                    )}
                    {isColumnVisible("orderDate") && (
                      <td className="py-1.5 px-2 text-[10px] text-blue-200 align-middle whitespace-nowrap">
                        {order.date}
                      </td>
                    )}
                    {isColumnVisible("deadline") && (
                      <td className="py-1.5 px-2 text-[10px] text-blue-200 align-middle whitespace-nowrap">
                        {order.deadline}
                      </td>
                    )}
                    {isColumnVisible("orderAmount") && (
                      <td
                        className={`py-1.5 px-2 text-[10px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "orderAmount"
                            ? "bg-blue-600/50 text-white"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.total}
                      </td>
                    )}
                    {isColumnVisible("paymentRecieved") && (
                      <td
                        className={`py-1.5 px-2 text-[10px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "paymentRecieved"
                            ? "bg-blue-600/50 text-white"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.received}
                      </td>
                    )}
                    {isColumnVisible("balanceAmount") && (
                      <td
                        className={`py-1.5 px-2 text-[10px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "balanceAmount"
                            ? "bg-blue-600/50 text-white"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.balanceAmount}
                      </td>
                    )}
                    {isColumnVisible("expertAmount") && (
                      <td
                        className={`py-1.5 px-2 text-[10px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "expertAmount"
                            ? "bg-blue-600/50 text-white"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.expertAmount}
                      </td>
                    )}
                    {isColumnVisible("source") && (
                      <td className="py-1.5 px-2 text-[10px] text-blue-200 align-middle whitespace-nowrap">
                        {order.source}
                      </td>
                    )}
                    <td className="py-1.5 px-2 align-middle whitespace-nowrap">
                      <button
                        onClick={() => setExpandedHistory(expandedHistory === order.id ? null : order.id)}
                        className="flex items-center gap-1.5 text-blue-300 hover:text-white mx-auto"
                      >
                        <History className="h-3.5 w-3.5" />
                        <ChevronRight
                          className={`h-3 w-3 transition-transform ${expandedHistory === order.id ? "rotate-90" : ""}`}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* History Modal */}
          {expandedHistory && (
            <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[60]">
              <div className="bg-blue-900 rounded-lg p-4 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto border border-blue-700/30 shadow-2xl">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <div className="p-1.5 bg-blue-500/20 rounded-lg border border-blue-500/30">
                      <History className="h-3 w-3 text-blue-400" />
                    </div>
                    <h3 className="text-sm font-semibold text-white">Order History</h3>
                  </div>
                  <button
                    onClick={() => setExpandedHistory(null)}
                    className="p-1.5 hover:bg-blue-800/30 rounded-lg transition-colors"
                  >
                    <X className="h-3 w-3 text-blue-200 hover:text-white" />
                  </button>
                </div>
                <div className="space-y-3">
                  {orderListReport?.orders
                    .find((order) => order.id === expandedHistory)
                    ?.history.map((event, index) => (
                      <div key={index} className="flex gap-3 items-start">
                        <div className="relative">
                          <div className="w-2 h-2 rounded-full bg-blue-500 mt-1.5 z-10 relative" />
                          {index !==
                            orderListReport?.orders.find((order) => order.id === expandedHistory)?.history.length -
                              1 && (
                            <div className="absolute top-2.5 left-1 bottom-0 w-px bg-gradient-to-b from-blue-500 to-transparent" />
                          )}
                        </div>
                        <div className="flex-1 bg-white/5 p-2.5 rounded-lg border border-white/10">
                          <p className="text-xs text-white/90">{event.event}</p>
                          <p className="text-[11px] text-white/50 mt-0.5">
                            {event.date} - {event.status}
                          </p>
                          {event.user?.name && (
                            <button
                              onClick={() => handleUserClick(event.user.id)}
                              className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors mt-1 flex items-center gap-1.5"
                            >
                              {event.user.name} ({event.user.userType})
                              <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedUser && <ClientReport   isOpenModal={selectedUser}
            metrics={metrics}
            selectedUser={selectedUser}
            onClose={() => setSelectedUser(null)}
            selectedMetric={selectedMetric}
            setSelectedUser={setSelectedUser}
            setSelectedMetric={setSelectedMetric}
            setSearch={setSearch}
            search={search} />}
      </div>
    )
  )
}

export default OrderListReport

