import {  Controller } from "react-hook-form"
import Select from "react-select/creatable"
import { courses, universities } from "../../../constants/commonConstant"






const ClientQualification = ({register,errors,control}) => {
  return (
    <div className="container">
        <div className="row">
          <div className="col-md-4 mb-3">
            <label htmlFor="university" className="form-label">
              University<span className="text-danger">*</span>
            </label>
            <Controller
              name="university"
              control={control}
              rules={{ required: "University is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={universities}
                  className={errors.university ? "is-invalid" : ""}
                  placeholder="Select University"
                />
              )}
            />
            {errors.university && <div className="invalid-feedback">{errors.university.message}</div>}
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="course" className="form-label">
              Course<span className="text-danger">*</span>
            </label>
            <Controller
              name="course"
              control={control}
              rules={{ required: "Course is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={courses}
                  className={errors.course ? "is-invalid" : ""}
                  placeholder="Select Course"
                />
              )}
            />
            {errors.course && <div className="invalid-feedback">{errors.course.message}</div>}
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="year" className="form-label">
              Year<span className="text-danger">*</span>
            </label>
            <input
              id="year"
              type="number"
              className={`form-control ${errors.year ? "is-invalid" : ""}`}
              {...register("year", {
                required: "Year is required",
                pattern: {
                  value: /^(19|20)\d{2}$/,
                  message: "Please enter a valid year (e.g., 2023)",
                },
              })}
            />
            {errors.year && <div className="invalid-feedback">{errors.year.message}</div>}
          </div>
        </div>
    </div>
  )
}

export default ClientQualification

