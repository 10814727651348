import { Fragment, useRef, useState } from "react";
import CustomModal from "../../../utils/modals/CustomModal";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Buttons from "../../../utils/buttons/Buttons";
import Icons from "../../../utils/Icons";
import ImageUrlList from "../../../manager/leads/components/ImageUrlList";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../services/action/common";
import { getLeadsAction, updateLeadsActionManager } from "../../../../services/action/manager";
import { Toaster } from "../../../Toaster";

const DeliveryBox = ({ sendMessage, deliveryBox, setDeliveryBox }) => {
  const [selectedData, setSelectedData] = useState({
    isAiVerified: false,
    isPlag: false,
    isGrammerCheck: false,
    isTotalWordCount: false,
    isRubric: false,
  });
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [imageUrls, setImageUrls] = useState([]);
  // const [isFullFilledDelivery,setIsFullFilledDelivery]=useState(false)

  const { isImageLoading } = useSelector((state) => state.commonReducer);
  const {user}=useSelector((state)=>state.authenticationReducer)
  const {chatList} =useSelector((state)=>state.managerReducer)
  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSelectedData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const uploadImage = (e) => {
    const callBackImage = (res) => {
      let tempData = [...imageUrls];
      setImageUrls([...tempData, ...res?.imageUrls]);
    };
    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const onSubmit = () => {
    if(imageUrls?.length<1){
       Toaster.error("File is required!")
       return false;
    }
    const messageContent = `
       <p class="list-p" id=ai-${selectedData?.isAiVerified}>${
      selectedData?.isAiVerified
        ? "<span class='correct'>√</span>"
        : "<span class='wrong'>✘</span>"
    } 0% AI verified </p>
       <p class="list-p" id=plag-${selectedData?.isPlag}>${
      selectedData?.isPlag
        ? "<span class='correct'>√</span>"
        : "<span class='wrong'>✘</span>"
    } Plagiarism is below 15% </p>
       <p class="list-p" id=grammar-${selectedData?.isGrammerCheck}>${
      selectedData?.isGrammerCheck
        ? "<span class='correct'>√</span>"
        : "<span class='wrong'>✘</span>"
    } Grammar check done </p>
       <p class="list-p" id=word-${selectedData?.isTotalWordCount}>${
      selectedData?.isTotalWordCount
        ? "<span class='correct'>√</span>"
        : "<span class='wrong'>✘</span>"
    } Total word count requirements met </p>
       <p class="list-p" id=rubric-${selectedData?.isRubric}>${
      selectedData?.isRubric
        ? "<span class='correct'>√</span>"
        : "<span class='wrong'>✘</span>"
    } Rubric followed </p>
    `;
    let messageFile = "";
    imageUrls?.map((item) => {
      messageFile =
        messageFile +
        `<a target='_blank' href=${item}><i class="fa fa-file" aria-hidden="true"></i></a>`;
    });

    const message = `<div class="delivery-chat-box submit-to-admin-as-final-order">
    ${messageContent} <p class="file-list">${messageFile} </p></div>`;
    sendMessage(message,false,["EXPERT","OPERATOR","MANAGER"])

   const userType=user?.userType?.toLowerCase()

    const obj={
        _id:chatList?.data?.leadId,
        orderStatus:"DONE",
        isSendEmailToAdmin:true,
        checkIfPaymentDoneThenDeliver:true,
        imageUrls
    }

    const callBack=()=>{
      dispatch(getLeadsAction({userType}))
    }
    setDeliveryBox({modal:false})
    dispatch(updateLeadsActionManager({userType,data:obj,cb:callBack}))
  };

  const Content = () => {
    return (
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="isAiVerified"
              checked={selectedData.isAiVerified}
              onChange={handleChange}
            />
          }
          label="0% AI verified"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isPlag"
              checked={selectedData.isPlag}
              onChange={handleChange}
            />
          }
          label="Plagiarism is below 15%"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isGrammerCheck"
              checked={selectedData.isGrammerCheck}
              onChange={handleChange}
            />
          }
          label="Grammar check done"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isTotalWordCount"
              checked={selectedData.isTotalWordCount}
              onChange={handleChange}
            />
          }
          label="Total word count requirements met"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isRubric"
              checked={selectedData.isRubric}
              onChange={handleChange}
            />
          }
          label="Rubric followed"
        />
        <div
          className="delivery-box-input-file"
          onClick={() => fileRef.current?.click()}
        >
          <p className="m-0 text-center">Upload File <label className="text-danger">*</label></p>
          {isImageLoading && (
            <p className="m-0 text-center">
              <Icons title={"loader"} color="#0a376a" />
            </p>
          )}
        </div>
        <input
          type="file"
          onChange={uploadImage}
          className="display-none"
          ref={fileRef}
          multiple={true}
        />
       {!!imageUrls?.length&& <ImageUrlList
          items={imageUrls}
          onDelete={(image) => {
            let filterDocument = imageUrls?.filter((item) => item !== image);
            setImageUrls(filterDocument);
          }}
        />}
        <Buttons title={"Submit"} onClick={onSubmit} />
      </FormGroup>
    );
  };

  return (
    <Fragment>
      <CustomModal
        open={deliveryBox?.modal}
        title={"Delivery Box"}
        zIndex={10000}
        size="md"
        content={Content}
        onClose={() => setDeliveryBox({})}
      />
    </Fragment>
  );
};

export default DeliveryBox;
