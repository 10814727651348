import React, { Fragment, useState } from "react";
import CustomModal from "../../utils/modals/CustomModal";
import UpdateCurrencyForm from "./UpdateCurrencyForm";

const CurrencyBtn = () => {
  const [open, customForm] = useState(false);
  return (
    <Fragment>
      <CustomModal
        open={open}
        content={() => <UpdateCurrencyForm />}
        onClose={() => customForm(false)}
      />
      <a href="#-" onClick={()=>customForm(true)} className="btn btn-white mr-3 top-1/2 ml-2">
        Update Currency
      </a>
    </Fragment>
  );
};

export default CurrencyBtn;
