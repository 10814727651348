"use client"

import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { motion } from "framer-motion"
import { Mail, Lock, ArrowRight, FileText, Users, GraduationCap, CheckCircle, PlaySquare, Apple } from "lucide-react"
import { getUserAction, loginAction, verifyGoogleTokenAction } from "../../services/action/authentication"
import { checkLogin } from "../../components/utils/functions/functions"
import GoogleSignupButton from "./social-auth/GoogleSignUpButton"

const Login = () => {
  const [obj, setObj] = useState({ email: "", password: "" })
  const [errors, setErrors] = useState({ email: "", password: "" })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isLogin, user } = useSelector((state) => state.authenticationReducer)

  useEffect(() => {
    const tokens = localStorage.getItem("token")
    if (tokens) {
      dispatch(getUserAction())
    }
  }, [dispatch])

  useEffect(() => {
    checkLogin(navigate, user)
  }, [user, navigate])

  const callBackSuccess = (res) => {
    checkLogin(navigate, res)
  }

  const loginNow = (e) => {
    e.preventDefault()
    const tempError = { ...errors }
    if (!obj.email) tempError.email = "Email is required!"
    else tempError.email = ""
    if (!obj.password) tempError.password = "Password is required!"
    else tempError.password = ""

    if (tempError.email || tempError.password) {
      setErrors(tempError)
      return false
    }
    dispatch(loginAction({ data: obj, cb: callBackSuccess }))
  }

  const handleCallbackResponse = (response) => {
    if (!response.credential) {
      console.error("No credential returned from Google")
      return
    }
    try {
      const payload = { token: response.credential }
      dispatch(
        verifyGoogleTokenAction({
          data: payload,
          cb: (res) => {
            if (res?.userType) {
              localStorage.setItem("token", res?.token)
              checkLogin(navigate, res)
            } else {
              const userObject = JSON.parse(atob(response?.credential.split(".")[1]))
              localStorage.setItem("emailVerifyToken", JSON.stringify(userObject))
              navigate("/auth/google")
            }
          },
        }),
      )
    } catch (error) {
      console.error("Error decoding token or extracting user data:", error)
    }
  }

  const partnerWebsites = [
    { url: "https://casestudyhelp.uk/", name: "Case Study Help" },
    { url: "https://www.assignmentwriter.au/", name: "Assignment Writer" },
    { url: "https://www.courseworkhelp.uk/", name: "Coursework Help" },
    { url: "https://www.assessmenthelp.uk/", name: "Assessment Help" },
    { url: "https://www.programmingassignmenthelp.uk/", name: "Programming Help" },
    { url: "https://www.bestsophelp.com/", name: "Best SOP Help" },
  ]

  const stats = [
    { icon: FileText, count: "603,715+", text: "Delivered Documents" },
    { icon: Users, count: "4,192+", text: "Academic Experts" },
    { icon: GraduationCap, count: "100%", text: "Ph.D. Writers" },
    { icon: CheckCircle, count: "FREE", text: "Plagiarism Report" },
  ]

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-white relative overflow-hidden">
      {/* Background Animation */}
      <svg className="absolute inset-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "rgb(0,128,255)", stopOpacity: 0.2 }} />
            <stop offset="100%" style={{ stopColor: "rgb(0,194,255)", stopOpacity: 0.2 }} />
          </linearGradient>
        </defs>
        <motion.path
          d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          fill="url(#grad1)"
          initial={{
            d: "M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
          }}
          animate={{
            d: [
              "M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
              "M0,64L48,85.3C96,107,192,149,288,154.7C384,160,480,128,576,128C672,128,768,160,864,165.3C960,171,1056,149,1152,144C1248,139,1344,149,1392,154.7L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z",
            ],
          }}
          transition={{
            repeat: Number.POSITIVE_INFINITY,
            repeatType: "reverse",
            duration: 20,
            ease: "easeInOut",
          }}
        />
      </svg>

      {/* Main Content */}
      <div className="flex-1 flex flex-col lg:flex-row max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Left Side - Login Form */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center py-12 lg:py-16 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img
              className="mx-auto h-12 w-auto"
              src="/images/logo.png"
              alt="ClickinPedia Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Welcome back</h2>
            <p className="mt-2 text-center text-sm text-gray-600">Sign in to continue your journey</p>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <form className="space-y-6" onSubmit={loginNow}>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Email address"
                    value={obj.email}
                    onChange={(e) => setObj({ ...obj, email: e.target.value })}
                  />
                </div>
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.email}
                  </p>
                )}
              </div>

              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Password"
                    value={obj.password}
                    onChange={(e) => setObj({ ...obj, password: e.target.value })}
                  />
                </div>
                {errors.password && (
                  <p className="mt-2 text-sm text-red-600" id="password-error">
                    {errors.password}
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Link to="/reset-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                    Forgot your password?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {isLogin ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <>
                      Sign in
                      <ArrowRight className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                    </>
                  )}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">Or continue with</span>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <GoogleSignupButton onSignupSuccess={handleCallbackResponse} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Stats and Partner Logos */}
        <div className="w-full lg:w-1/2 flex flex-col justify-center py-12 lg:py-16 lg:px-8">
          <div className="space-y-8">
            {/* Stats */}
            <div className="grid grid-cols-2 gap-4">
              {stats.map((item, index) => (
                <motion.div
                  key={index}
                  className="bg-white p-4 rounded-lg shadow-sm flex flex-col items-center justify-center text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <motion.div className="text-indigo-600 mb-2" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                    <item.icon className="h-8 w-8" />
                  </motion.div>
                  <p className="text-2xl font-bold">{item.count}</p>
                  <p className="text-sm text-gray-500">{item.text}</p>
                </motion.div>
              ))}
            </div>

            {/* Partner Logos */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4 text-center">Trusted by Industry Leaders</h3>
              <div className="grid grid-cols-3 gap-4">
                {partnerWebsites.map((partner, index) => (
                  <motion.a
                    key={index}
                    href={partner.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center p-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <span className="text-sm font-medium text-gray-900">{partner.name}</span>
                  </motion.a>
                ))}
              </div>
            </div>

            {/* Download Apps */}
            <div>
              <h3 className="text-lg font-medium text-gray-900 mb-4 text-center">Download our apps</h3>
              <div className="flex justify-center space-x-4">
                <motion.a
                  href="https://play.google.com/store/apps/details?id=com.clickinpedia.com&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <PlaySquare className="h-5 w-5 mr-2" />
                  Play Store
                </motion.a>
                <motion.a
                  href="https://apps.apple.com/us/app/clickinpedia/id6739730196"
                  target="_blank"
                  rel="noopener noreferrer"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  <Apple className="h-5 w-5 mr-2" />
                  App Store
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

