export const pagesWordsList = [
    { label: "1 Page / 250 Words", value: "1" },
    { label: "2 Pages / 500 Words", value: "2" },
    { label: "3 Pages / 750 Words", value: "3" },
    { label: "4 Pages / 1000 Words", value: "4" },
    { label: "5 Pages / 1250 Words", value: "5" },
    { label: "6 Pages / 1500 Words", value: "6" },
    { label: "7 Pages / 1750 Words", value: "7" },
    { label: "8 Pages / 2000 Words", value: "8" },
    { label: "9 Pages / 2250 Words", value: "9" },
    { label: "10 Pages / 2500 Words", value: "10" },
    { label: "20 Pages / 5000 Words", value: "20" },
    { label: "30 Pages / 7500 Words", value: "30" },
    { label: "40 Pages / 10000 Words", value: "40" },
    { label: "50 Pages / 12500 Words", value: "50" },
    { label: "60 Pages / 15000 Words", value: "60" },
    { label: "70 Pages / 17500 Words", value: "70" },
    { label: "80 Pages / 20000 Words", value: "80" },
    { label: "90 Pages / 22500 Words", value: "90" },
    { label: "100 Pages / 25000 Words", value: "100" },
    { label: "110 Pages / 27500 Words", value: "110" },
    { label: "120 Pages / 30000 Words", value: "120" },
    { label: "130 Pages / 32500 Words", value: "130" },
    { label: "140 Pages / 35000 Words", value: "140" },
    { label: "150 Pages / 37500 Words", value: "150" },
    { label: "160 Pages / 40000 Words", value: "160" },
    { label: "170 Pages / 42500 Words", value: "170" },
    { label: "180 Pages / 45000 Words", value: "180" },
    { label: "190 Pages / 47500 Words", value: "190" },
    { label: "200 Pages / 50000 Words", value: "200" },
    { label: "210 Pages / 52500 Words", value: "210" },
    { label: "220 Pages / 55000 Words", value: "220" },
    { label: "230 Pages / 57500 Words", value: "230" },
    { label: "240 Pages / 60000 Words", value: "240" },
    { label: "250 Pages / 62500 Words", value: "250" },
  ];
  

  export const timeDurations = [
    { label: "4 Hours", value: "4hr" },
    { label: "8 Hours", value: "8hr" },
    { label: "12 Hours", value: "12hr" },
    { label: "1 Day", value: "1day" },
    { label: "2 Days", value: "2days" },
    { label: "3 Days", value: "3days" },
    { label: "4 Days", value: "4days" },
    { label: "5 Days", value: "5days" },
    { label: "6 Days", value: "6days" },
    { label: "7 Days", value: "7days" },
    { label: "8 Days", value: "8days" },
    { label: "9 Days", value: "9days" },
    { label: "10 Days", value: "10days" },
    { label: "11 Days", value: "11days" },
    { label: "12 Days", value: "12days" },
    { label: "13 Days", value: "13days" },
    { label: "14 Days", value: "14days" },
    { label: "15 Days", value: "15days" },
    { label: "16 Days", value: "16days" },
    { label: "17 Days", value: "17days" },
    { label: "18 Days", value: "18days" },
    { label: "19 Days", value: "19days" },
    { label: "20 Days", value: "20days" },
    { label: "21 Days", value: "21days" },
    { label: "22 Days", value: "22days" },
    { label: "23 Days", value: "23days" },
    { label: "24 Days", value: "24days" },
    { label: "25 Days", value: "25days" },
    { label: "26 Days", value: "26days" },
    { label: "27 Days", value: "27days" },
    { label: "28 Days", value: "28days" },
    { label: "29 Days", value: "29days" },
    { label: "30 Days", value: "30days" }
  ];
  