export const appActions = {
  //Authentication
  LOGIN: "LOGIN",
  GET_USER:"GET_USER",
  GET_ALL_USER_ACTION:"GET_ALL_USER_ACTION",
  CREATE_USER:"CREATE_USER",
  DELETE_USER_MANAGER:"DELETE_USER_MANAGER",
  UPDATE_USER_MANAGER:"UPDATE_USER_MANAGER",
  UPLOAD_IMAGE:"UPLOAD_IMAGE",
  GET_CLIENT_LIST_OPTION_MANAGER:"GET_CLIENT_LIST_OPTION_MANAGER",
  GENERATE_LEAD_MANAGER:"GENERATE_LEAD_MANAGER",
  GET_GENERATE_LEAD_ADMIN:"GET_GENERATE_LEAD_ADMIN",
  UPDATE_LEAD_MANAGER:"UPDATE_LEAD_MANAGER",
  START_CHAT:"START_CHAT",
  GET_CHAT:"GET_CHAT",
  GET_DASHBOARD:"GET_DASHBOARD",
  CLOSE_CHAT:"CLOSE_CHAT",
  GET_EXPERT_LIST_OPTION:"GET_EXPERT_LIST_OPTION",
  GET_NOTIFICATION_LIST:"GET_NOTIFICATION_LIST",
  UPDATE_NOTIFICATION:"UPDATE_NOTIFICATION",
  UPDATE_NOTIFICATION_STATUS:"UPDATE_NOTIFICATION_STATUS",
  GENERATE_PAYMENT_LINK:"GENERATE_PAYMENT_LINK",
  LOGOUT:"LOGOUT",
  GET_COUNTRY_LIST:"GET_COUNTRY_LIST",
  UPLOAD_AUDIO:"UPLOAD_AUDIO",
  CHANGE_CHAT_VISIBITY:"CHANGE_CHAT_VISIBITY",
  RESET_PASSWORD:"RESET_PASSWORD",
  UPDATE_PASSWORD:"UPDATE_PASSWORD",
  SENT_PAYMENT_LINK_WHATSAPP:"SENT_PAYMENT_LINK_WHATSAPP",
  CHANGE_CALL_HIPPO_PERMISSION:"CHANGE_CALL_HIPPO_PERMISSION",
  GET_OPERATOR_MANAGER_LIST_ACTION:"GET_OPERATOR_MANAGER_LIST_ACTION",
  ORDER_DELIVERED_ACTION:"ORDER_DELIVERED_ACTION",
  SEND_BALANCED_AMOUNT_TO_CLIENT:"SEND_BALANCED_AMOUNT_TO_CLIENT",
  REQUEST_FOR_ASSIGN_LEAD:"REQUEST_FOR_ASSIGN_LEAD",
  GET_ALL_LIVE_USER_LIST:"GET_ALL_LIVE_USER_LIST",
  GET_OPEN_USER_CHAT_LIST:"GET_OPEN_USER_CHAT_LIST",
  GET_OPEN_NOTIFICATION:"GET_OPEN_NOTIFICATION",
  START_OPEN_CHAT:"START_OPEN_CHAT",
  CLOSE_OPEN_CHAT:"CLOSE_OPEN_CHAT",
  SET_NOTIFICATION_PERMISSION:"SET_NOTIFICATION_PERMISSION",
  GET_REPORTS:"GET_REPORTS",
  GET_LEAD_DETAILS:"GET_LEAD_DETAILS",
  GET_EXPERT_QUERIES:"GET_EXPERT_QUERIES",
  UPDATE_QUERY_STATUS:"UPDATE_QUERY_STATUS",
  UPDATE_CURRENCY:"UPDATE_CURRENCY",
  GET_CURRENCY_LIST:"GET_CURRENCY_LIST",
  UNASSIGNED_LEAD:"UNASSIGNED_LEAD",
  VERIFY_GOOGLE_TOKEN:"VERIFY_GOOGLE_TOKEN",
  SIGNUP:"SIGNUP",
  GET_ORDER_ACTIVITY:"GET_ORDER_ACTIVITY",
  SET_PENALTY:"SET_PENALTY",
  GET_WELCOME_SCREEN_DATA:"GET_WELCOME_SCREEN_DATA",
  ON_UPDATE_ANDROID_DESIGN:"ON_UPDATE_ANDROID_DESIGN",
  UPDATE_REFERAL_AMOUNT:"UPDATE_REFERAL_AMOUNT",
  GET_REFERAL_AMOUNT:"GET_REFERAL_AMOUNT",
  VERIFY_COUPON_CODE:"VERIFY_COUPON_CODE",
  UPDATE_CLIENT_WALLET:"UPDATE_CLIENT_WALLET",
  ALL_LIVE_LEADS:"ALL_LIVE_LEADS",
  GET_LIVE_LEADS_DETAILS:"GET_LIVE_LEADS_DETAILS",
  LIVE_ADS_STATUS_UPDATE:"LIVE_ADS_STATUS_UPDATE",
  GET_LEAD_ACTIVITY:"GET_LEAD_ACTIVITY",
  GET_REPORT_ORDER_LIST:"GET_REPORT_ORDER_LIST",
  GET_USER_DETAILS:"GET_USER_DETAILS",
  GET_USER_REPORTLIST:"GET_USER_REPORTLIST",
  GET_LIVE_USER_TITLE_COUNT:"GET_LIVE_USER_TITLE_COUNT",
  UPDATE_LIVE_USER:"UPDATE_LIVE_USER",
  UPDATE_AI_MODE:"UPDATE_AI_MODE",
  DELETE_USER_ACTION:"DELETE_USER_ACTION",
  UPDATE_DETAILS:"UPDATE_DETAILS",
  CHECK_CLIENT_RAZOR_PAY_PAYMENT:"CHECK_CLIENT_RAZOR_PAY_PAYMENT",
  CREATE_SERVICES:"CREATE_SERVICES",
  GET_SERVICES:"GET_SERVICES",
  UPDATE_SERVICE:"UPDATE_SERVICE",
  DELETE_SERVICE:"DELETE_SERVICE",
  CREATE_SUBJECT:"CREATE_SUBJECT",
  GET_SUBJECT:"GET_SUBJECT",
  UPDATE_SUBJECT:"UPDATE_SUBJECT",
  DELETE_SUBJECT:"DELETE_SUBJECT",
  GET_SUBJECT_BY_CATEGORY:"GET_SUBJECT_BY_CATEGORY",
  GET_SUBJECT_PRICING:"GET_SUBJECT_PRICING",
  CREATE_CLIENT_ORDER:"CREATE_CLIENT_ORDER"
};
