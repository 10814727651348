"use client"

import { useState, useRef, useCallback, useEffect } from "react"
import { X, User, History, ChevronRight, Search, Eye, ChevronDown } from "lucide-react"
import { useDispatch, useSelector } from "react-redux"
import UserDetailsModal from "../components/UserDetailsModal"
import { getReportOrderListAction } from "../../../services/action/common"

const ClientReport = ({
  amountType,
  selectedMetric,
  metrics,
  isOpenModal = false,
  onClose,
  setSelectedUser,
  selectedUser
}) => {
  const [search,setSearch]=useState("")
  const { reports, orderListReport } = useSelector((state) => state.commonReducer)
  const [expandedHistory, setExpandedHistory] = useState(null)
  // const [selectedUser, setSelectedUser] = useState(null)
  const [hoveredRow, setHoveredRow] = useState(null)
  const {user} =useSelector((state)=>state.authenticationReducer)
  const [visibleColumns, setVisibleColumns] = useState({
    source: true,
    orderID: true,
    status: true,
    client: true,
    expert: true,
    orderDate: true,
    deadline: true,
    orderAmount: true,
    paymentRecieved: true,
    balanceAmount: true,
    expertAmount: true,
  })
  const [selectedStatus, setSelectedStatus] = useState("TOTAL")
  const [selectedClientType, setSelectedClientType] = useState("All")
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false)
  const [isClientTypeDropdownOpen, setIsClientTypeDropdownOpen] = useState(false)
  const tableRef = useRef(null)
  const [pageData, setPageData] = useState({
    page: 1,
  });
  // const [selectedMetric, setSelectedMetric] = useState(null);
  // const [selectedMonth, setSelectedMonth] = useState(
  //   new Date().toLocaleString("default", { month: "long" })
  // );

  const dispatch =useDispatch();
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");

  const fetchOrderList = ({
    orderStatus = selectedMetric,
    // specificMonth = selectedMonth,
    page = pageData?.page,
    firstTime,
    tempSearch = search,
    dateFilter = selectedDateFilter,
    amountType = "all",
    clientType="ALL",
    userId=isOpenModal
  }) => {
    const payload = {
      page,
      search: tempSearch,
      // specificMonth: specificMonth === "All" ? "" : specificMonth,
      orderType:
        orderStatus === "total"
          ? "TOTAL"
          : orderStatus === "missed"
          ? "MISSED_DEADLINE"
          : orderStatus === "progress"
          ? "ASSIGNED"
          : orderStatus === "completed"
          ? "DELIVERED"
          :"TOTAL",
      amountType:amountType,
      dateFilter,
      clientType,
      userId
    };
    dispatch(
      getReportOrderListAction({
        userType: user?.userType,
        data: payload,
        firstTime: firstTime,
      })
    );
  };


  const observer = useRef()
  const lastOrderElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        // console.log("selectedStatus",selectedStatus)
        if (entries[0].isIntersecting && orderListReport?.orders.length < orderListReport?.totalResults) {
          fetchMoreOrders({
            orderStatus: selectedStatus,
            clientType: selectedClientType,
          })
        }
      })
      if (node) observer.current.observe(node)
    },
    [orderListReport?.orders.length, orderListReport?.totalResults],
  )

  const fetchMoreOrders = () => {
    fetchOrderList({
      page: pageData.page + 1,
      orderStatus: selectedStatus,
      clientType: selectedClientType,
    })
    setPageData((prev) => ({ ...prev, page: prev.page + 1 }))
  }

  const toggleColumnVisibility = (column) => {
    if (["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column)) {
      return // These columns cannot be toggled when any of them is selected
    }
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }))
  }

  const isColumnVisible = (column) => {
    if (["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(amountType || selectedMetric)) {
      return (
        ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) || visibleColumns[column]
      )
    }
    return visibleColumns[column]
  }

  const getStatusColor = (status) => {
    switch (status.toUpperCase()) {
      case "DELIVERED":
        return "bg-[#00FF9D]/10 text-[#00FF9D] border border-[#00FF9D]/20"
      case "ASSIGNED":
        return "bg-[#9D00FF]/10 text-[#9D00FF] border border-[#9D00FF]/20"
      case "IN PROGRESS":
        return "bg-[#00A3FF]/10 text-[#00A3FF] border border-[#00A3FF]/20"
      case "PENDING":
        return "bg-[#FFD100]/10 text-[#FFD100] border border-[#FFD100]/20"
      default:
        return "bg-gray-500/10 text-gray-400 border border-gray-500/20"
    }
  }

  const handleUserClick = (userId) => {
    setSelectedUser(userId)
  }

  const statusOptions = [
    "All",
    "OPEN",
    "ASSIGNED",
    "UNDERQC",
    "DELIVERED",
    "REOPEN",
    "ONHOLD",
    "REFUND",
    "MISSED_DEADLINE",
    // "CLIENTDEADLINE",
  ]
  const clientTypeOptions = ["All", "REPEAT", "PAID", "REFERAL", "ORGANIC"]

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown-container")) {
        setIsStatusDropdownOpen(false)
        setIsClientTypeDropdownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])



  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    fetchOrderList({
      dateFilter: filter,
      firstTime: true,
      page: 1,
    });
    setPageData({ page: 1 });
  };

  useEffect(()=>{
    if(isOpenModal){
   fetchOrderList({userId:isOpenModal,firstTime:true})
    }
  },[isOpenModal])

  return (
    (isOpenModal || selectedMetric) && (
      <div className="fixed inset-0 bg-black/70 backdrop-blur-sm flex items-start justify-center z-50 p-2 overflow-y-auto">
        <div className="bg-[#001b3d] rounded-lg w-full max-w-[98vw] mx-auto relative shadow-2xl mt-2">
          {/* Header */}
          <div className="p-3 border-b border-white/10 bg-white/5">
            <div className="flex items-center justify-between mb-3">
              <div className="flex items-center gap-2">
                <div className="p-1.5 bg-blue-500/20 rounded-lg border border-blue-500/30">
                  <User className="h-4 w-4 text-blue-400" />
                </div>
                <h2 className="text-base font-semibold text-white">
                  {metrics(reports?.current)?.find((m) => m.id === selectedMetric)?.title} Details
                </h2>
              </div>
              <button onClick={onClose} className="p-1.5 hover:bg-white/10 rounded-lg transition-colors">
                <X className="h-4 w-4 text-white/70 hover:text-white" />
              </button>
            </div>

            {/* Filters */}
            <div className="flex flex-wrap gap-2 items-center">
              <div className="relative flex-1 min-w-[250px]">
                <Search className="absolute left-2.5 top-1/2 -translate-y-1/2 h-3.5 w-3.5 text-white/50" />
                <input
                  type="text"
                  value={search}
                  onChange={(e) => {
                    fetchOrderList({
                      tempSearch: e.target.value,
                      firstTime: true,
                      page: 1,
                    })
                    setSearch(e.target.value)
                    setPageData({ page: 1 })
                  }}
                  placeholder="Search orders..."
                  className="w-full pl-8 pr-3 py-1.5 text-xs rounded-md bg-white/5 border border-white/10 text-white placeholder-white/50 focus:outline-none focus:border-blue-500/50"
                />
              </div>
              <div className="flex gap-1.5 flex-wrap">
                {["all", "today", "yesterday", "previous7days"].map((filter) => (
                  <button
                    key={filter}
                    onClick={() => handleDateFilterChange(filter)}
                    className={`px-3 py-1.5 rounded-md text-xs font-medium ${
                      selectedDateFilter === filter
                        ? "bg-blue-500/80 text-white"
                        : "bg-white/5 text-white/70 hover:bg-white/10 hover:text-white"
                    }`}
                  >
                    {filter.charAt(0).toUpperCase() + filter.slice(1).replace(/([A-Z])/g, " $1")}
                  </button>
                ))}
              </div>
              <div className="relative dropdown-container">
                <button
                  className="px-3 py-1.5 rounded-md text-xs font-medium bg-white/5 text-white/70 hover:bg-white/10 hover:text-white flex items-center gap-1"
                  onClick={() => setIsStatusDropdownOpen(!isStatusDropdownOpen)}
                >
                  Status: {selectedStatus} <ChevronDown className="h-3 w-3" />
                </button>
                {isStatusDropdownOpen && (
                  <div className="absolute top-full left-0 mt-1 bg-[#001b3d] border border-white/10 rounded-md shadow-lg z-10">
                    {statusOptions.map((status) => (
                      <button
                        key={status}
                        onClick={() => {
                          fetchOrderList({
                            orderStatus: status,
                            page: 1,
                            firstTime: true,
                          })
                          setSelectedStatus(status)
                          setPageData({ page: 1 })
                          setIsStatusDropdownOpen(false)
                        }}
                        className="block w-full text-left px-3 py-1.5 text-xs text-white/70 hover:bg-white/10 hover:text-white"
                      >
                        {status}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="relative dropdown-container">
                <button
                  className="px-3 py-1.5 rounded-md text-xs font-medium bg-white/5 text-white/70 hover:bg-white/10 hover:text-white flex items-center gap-1"
                  onClick={() => setIsClientTypeDropdownOpen(!isClientTypeDropdownOpen)}
                >
                  Client Type: {selectedClientType} <ChevronDown className="h-3 w-3" />
                </button>
                {isClientTypeDropdownOpen && (
                  <div className="absolute top-full left-0 mt-1 bg-[#001b3d] border border-white/10 rounded-md shadow-lg z-10">
                    {clientTypeOptions.map((type) => (
                      <button
                        key={type}
                        onClick={() => {
                          fetchOrderList({
                            clientType: type,
                            page: 1,
                            firstTime: true,
                          })
                          setSelectedClientType(type)
                          setPageData({ page: 1 })
                          setIsClientTypeDropdownOpen(false)
                        }}
                        className="block w-full text-left px-3 py-1.5 text-xs text-white/70 hover:bg-white/10 hover:text-white"
                      >
                        {type}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end w-full p-2 bg-white/5 border-b border-white/10">
            <span className="text-xs text-white/70">Total Records: {orderListReport?.totalResults || 0}</span>
          </div>

          {/* Column visibility toggle menu */}
          <div className="flex items-center gap-2 p-2 bg-white/5 border-b border-white/10">
            <span className="text-xs text-white/70">Toggle columns:</span>
            {Object.keys(visibleColumns).map((column) => (
              <button
                key={column}
                onClick={() => toggleColumnVisibility(column)}
                className={`px-2 py-1 rounded text-xs ${
                  isColumnVisible(column) ? "bg-blue-500/20 text-blue-400" : "bg-white/5 text-white/50"
                } ${
                  ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) &&
                  ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(
                    amountType || selectedMetric,
                  )
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-white/10"
                }`}
                disabled={
                  ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(column) &&
                  ["orderAmount", "paymentRecieved", "balanceAmount", "expertAmount"].includes(
                    amountType || selectedMetric,
                  )
                }
              >
                {column.charAt(0).toUpperCase() + column.slice(1).replace(/([A-Z])/g, " $1")}
              </button>
            ))}
          </div>

          {/* Table Container */}
          <div
            className="w-full overflow-x-auto scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent"
            ref={tableRef}
          >
            <table className="w-full border-collapse">
              <thead>
                <tr className="bg-white/5">
                  <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                    #
                  </th>
                  {isColumnVisible("orderID") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Order ID
                    </th>
                  )}
                  {isColumnVisible("status") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Status
                    </th>
                  )}
                  {isColumnVisible("client") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Client
                    </th>
                  )}
                  {isColumnVisible("expert") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Expert
                    </th>
                  )}
                  {isColumnVisible("orderDate") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Order Date
                    </th>
                  )}
                  {isColumnVisible("deadline") && (
                    <th className="py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                      Deadline
                    </th>
                  )}
                  {isColumnVisible("orderAmount") && (
                    <th
                      className={`py-2 px-3 text-right text-[11px] font-medium border-b border-white/10 whitespace-nowrap ${
                        (amountType || selectedMetric) === "orderAmount"
                          ? "bg-blue-500/20 text-blue-400"
                          : "text-white/70"
                      }`}
                    >
                      Total
                    </th>
                  )}
                  {isColumnVisible("paymentRecieved") && (
                    <th
                      className={`py-2 px-3 text-right text-[11px] font-medium border-b border-white/10 whitespace-nowrap ${
                        (amountType || selectedMetric) === "paymentRecieved"
                          ? "bg-blue-500/20 text-blue-400"
                          : "text-white/70"
                      }`}
                    >
                      Received
                    </th>
                  )}
                  {isColumnVisible("balanceAmount") && (
                    <th
                      className={`py-2 px-3 text-right text-[11px] font-medium border-b border-white/10 whitespace-nowrap ${
                        (amountType || selectedMetric) === "balanceAmount"
                          ? "bg-blue-500/20 text-blue-400"
                          : "text-white/70"
                      }`}
                    >
                      Balance
                    </th>
                  )}
                  {isColumnVisible("expertAmount") && (
                    <th
                      className={`py-2 px-3 text-right text-[11px] font-medium border-b border-white/10 whitespace-nowrap ${
                        (amountType || selectedMetric) === "expertAmount"
                          ? "bg-blue-500/20 text-blue-400"
                          : "text-white/70"
                      }`}
                    >
                      Expert Amount
                    </th>
                  )}
                  {isColumnVisible("source") && (
                    <th
                      className={`py-2 px-3 text-left text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap`}
                    >
                      Source
                    </th>
                  )}
                  <th className="py-2 px-3 text-center text-[11px] font-medium text-white/70 border-b border-white/10 whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-white/10">
                {orderListReport?.orders.map((order, index) => (
                  <tr
                    key={order.id}
                    ref={index === orderListReport.orders.length - 1 ? lastOrderElementRef : null}
                    className={`hover:bg-white/5 transition-colors ${
                      order.isMissedExpertDeadline ? "bg-red-500/20" : ""
                    }`}
                    onMouseEnter={() => setHoveredRow(order.id)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">
                      {index + 1}
                    </td>
                    {isColumnVisible("orderID") && (
                      <td className="py-1.5 px-3 text-[11px] text-white/90 font-medium align-middle whitespace-nowrap">
                        {order.id}
                      </td>
                    )}
                    {isColumnVisible("status") && (
                      <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                        <span
                          className={`px-2 py-0.5 rounded-md text-[10px] font-medium inline-block ${getStatusColor(
                            order.status,
                          )}`}
                        >
                          {order.status}
                        </span>
                      </td>
                    )}
                    {isColumnVisible("client") && (
                      <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                        <button
                          onClick={() => setSelectedUser(order?.clientId)}
                          className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                        >
                          {order.customer}
                          <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                        </button>
                      </td>
                    )}
                    {isColumnVisible("expert") && (
                      <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                        <button
                          onClick={() => setSelectedUser(order.expert?.id)}
                          className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors flex items-center gap-1.5"
                        >
                          {order.expert.name}
                          <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                        </button>
                      </td>
                    )}
                    {isColumnVisible("orderDate") && (
                      <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">
                        {order.date}
                      </td>
                    )}
                    {isColumnVisible("deadline") && (
                      <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">
                        {order.deadline}
                      </td>
                    )}
                    {isColumnVisible("orderAmount") && (
                      <td
                        className={`py-1.5 px-3 text-[11px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "orderAmount"
                            ? "bg-blue-500/20 text-blue-400"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.total}
                      </td>
                    )}
                    {isColumnVisible("paymentRecieved") && (
                      <td
                        className={`py-1.5 px-3 text-[11px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "paymentRecieved"
                            ? "bg-blue-500/20 text-blue-400"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.received}
                      </td>
                    )}
                    {isColumnVisible("balanceAmount") && (
                      <td
                        className={`py-1.5 px-3 text-[11px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "balanceAmount"
                            ? "bg-blue-500/20 text-blue-400"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.balanceAmount}
                      </td>
                    )}
                    {isColumnVisible("expertAmount") && (
                      <td
                        className={`py-1.5 px-3 text-[11px] font-medium text-right align-middle whitespace-nowrap ${
                          (amountType || selectedMetric) === "expertAmount"
                            ? "bg-blue-500/20 text-blue-400"
                            : "text-white/90"
                        }`}
                      >
                        {order.payments.expertAmount}
                      </td>
                    )}

                    {isColumnVisible("source") && (
                      <td className="py-1.5 px-3 text-[11px] text-white/70 align-middle whitespace-nowrap">
                        {order.source}
                      </td>
                    )}
                    <td className="py-1.5 px-3 align-middle whitespace-nowrap">
                      <button
                        onClick={() => setExpandedHistory(expandedHistory === order.id ? null : order.id)}
                        className="flex items-center gap-1.5 text-white/50 hover:text-white mx-auto"
                      >
                        <History className="h-3.5 w-3.5" />
                        <ChevronRight
                          className={`h-3 w-3 transition-transform ${expandedHistory === order.id ? "rotate-90" : ""}`}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* History Modal */}
          {expandedHistory && (
            <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-[60]">
              <div className="bg-[#001b3d] rounded-lg p-4 max-w-2xl w-full mx-4 max-h-[80vh] overflow-y-auto border border-white/10 shadow-2xl">
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-2">
                    <div className="p-1.5 bg-blue-500/20 rounded-lg border border-blue-500/30">
                      <History className="h-4 w-4 text-blue-400" />
                    </div>
                    <h3 className="text-sm font-semibold text-white">Order History</h3>
                  </div>
                  <button
                    onClick={() => setExpandedHistory(null)}
                    className="p-1.5 hover:bg-white/10 rounded-lg transition-colors"
                  >
                    <X className="h-4 w-4 text-white/70 hover:text-white" />
                  </button>
                </div>
                <div className="space-y-3">
                  {orderListReport?.orders
                    .find((order) => order.id === expandedHistory)
                    ?.history.map((event, index) => (
                      <div key={index} className="flex gap-3 items-start">
                        <div className="relative">
                          <div className="w-2 h-2 rounded-full bg-blue-500 mt-1.5 z-10 relative" />
                          {index !==
                            orderListReport?.orders.find((order) => order.id === expandedHistory)?.history.length -
                              1 && (
                            <div className="absolute top-2.5 left-1 bottom-0 w-px bg-gradient-to-b from-blue-500 to-transparent" />
                          )}
                        </div>
                        <div className="flex-1 bg-white/5 p-2.5 rounded-lg border border-white/10">
                          <p className="text-xs text-white/90">{event.event}</p>
                          <p className="text-[11px] text-white/50 mt-0.5">
                            {event.date} - {event.status}
                          </p>
                          {event.user?.name && (
                            <button
                              onClick={() => handleUserClick(event.user.id)}
                              className="text-[11px] text-blue-400 hover:text-blue-300 transition-colors mt-1 flex items-center gap-1.5"
                            >
                              {event.user.name} ({event.user.userType})
                              <Eye className="h-3 w-3 opacity-0 group-hover:opacity-100" />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>

        {/* {selectedUser && <UserDetailsModal userId={selectedUser} onClose={() => setSelectedUser(null)} />} */}
      </div>
    )
  )
}

export default ClientReport

