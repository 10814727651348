import { Fragment, useEffect, useRef, useCallback, useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import "simplebar/dist/simplebar.min.css";
import useMounted from "../../hooks/useMounted";
import QuickMenuDesktop from "./notification/QuickMenuDesktop";
import QuickMenuMobile from "./notification/QuickMenuMobile";
import socket from "../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationListAction,
  getOperatorOrManagerListAction,
  updateNotificationAction,
} from "../../services/action/common";
import { getUserAction } from "../../services/action/authentication";

const MANAGER_ROLES = ["MANAGER", "ADMIN", "SUPER_ADMIN"];

// Create AudioContext instance
let audioContext = null;

const QuickMenu = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticationReducer);
  // const audioRef = useRef(null);
  // const newLeadRef = useRef(null);
  const notificationBufferRef = useRef(null);
  const newLeadBufferRef = useRef(null);
  const hasMounted = useMounted();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const userType = useMemo(() => user?.userType?.toLowerCase(), [user?.userType]);

  // Initialize audio context and load sounds
  const initializeAudio = useCallback(async () => {
    try {
      if (!audioContext) {
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
      }

      // Load notification sound
      if (!notificationBufferRef.current) {
        const notificationResponse = await fetch('/assets/ringtone.wav');
        const notificationArrayBuffer = await notificationResponse.arrayBuffer();
        notificationBufferRef.current = await audioContext.decodeAudioData(notificationArrayBuffer);
      }

      // Load new lead sound
      if (!newLeadBufferRef.current) {
        const newLeadResponse = await fetch('/assets/ads-leads.wav');
        const newLeadArrayBuffer = await newLeadResponse.arrayBuffer();
        newLeadBufferRef.current = await audioContext.decodeAudioData(newLeadArrayBuffer);
      }
    } catch (error) {
      console.error('Error initializing audio:', error);
    }
  }, []);

  // Play sound using AudioContext
  const playSound = useCallback(async (buffer) => {
    try {
      if (!audioContext) {
        await initializeAudio();
      }

      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }

      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioContext.destination);
      source.start(0);
    } catch (error) {
      console.error('Error playing sound:', error);
    }
  }, [initializeAudio]);

  // Handle notification socket event
  const handleNotification = useCallback(({
    orderId,
    notificationId: _id,
    subject,
    messageId,
    senderId,
    sendTime,
    leadId,
    senderName,
    message,
    messageType,
    userId,
    visitorId,
    messageNewMessageCount,
  }) => {
    if (messageType === "EXTERNAL") {
      dispatch(getNotificationListAction({ userType }));
    } else {
      dispatch(
        updateNotificationAction({
          orderId,
          _id,
          subject,
          visitorId,
          messageId,
          senderId,
          sendTime,
          leadId,
          senderName,
          message,
          messageType,
          userId,
          messageNewMessageCount,
        })
      );
    }

    if (notificationBufferRef.current) {
      playSound(notificationBufferRef.current);
    }
  }, [dispatch, userType, playSound]);

  // Handle new lead socket event
  const handleNewLead = useCallback(() => {
    dispatch(getUserAction());
    if (newLeadBufferRef.current) {
      playSound(newLeadBufferRef.current);
    }
  }, [dispatch, playSound]);

  // Initialize audio on component mount
  useEffect(() => {
    initializeAudio();
    
    // Add click event listener to initialize audio context
    const handleUserInteraction = () => {
      if (audioContext && audioContext.state === 'suspended') {
        audioContext.resume();
      }
    };
    
    document.addEventListener('click', handleUserInteraction);
    return () => {
      document.removeEventListener('click', handleUserInteraction);
    };
  }, [initializeAudio]);

  // Fetch notifications for non-marketing users
  useEffect(() => {
    if (user?.userType !== "MARKETING") {
      dispatch(getNotificationListAction({ userType }));
    }
  }, [dispatch, user?.userType, userType]);

  // Fetch operator/manager list for authorized roles
  useEffect(() => {
    if (MANAGER_ROLES.includes(user?.userType)) {
      dispatch(getOperatorOrManagerListAction({ userType }));
    }
  }, [dispatch, user?.userType, userType]);

  // Socket event listeners
  useEffect(() => {
    socket.on("send_notification", handleNotification);
    socket.on("new_lead_ads", handleNewLead);

    return () => {
      socket.off("send_notification", handleNotification);
      socket.off("new_lead_ads", handleNewLead);
    };
  }, [handleNotification, handleNewLead]);

  return (
    <Fragment>
      {hasMounted && (isDesktop ? <QuickMenuDesktop /> : <QuickMenuMobile />)}
    </Fragment>
  );
};

export default QuickMenu;