import axios from "axios";
import api from "./apiInstance";

export const commonApi = {
  LOGIN: ({ data }) => api.post("/login", data),
  SIGNUP:({data})=>api.post("/register",data),
  GET_USER:()=>api.get("/user"),
  GET_DASHBOARD:({userType})=>api.get(`/${userType}/get-dashboard`),
  GET_EXPERT_LIST_OPTION:({userType})=>api.get(`/${userType}/expert-list-option`),
  GET_NOTIFICATION_LIST:({userType})=>api.get(`/${userType}/get-notification`),
  UPDATE_NOTIFICATION_STATUS:({userType,data})=>api.post(`/${userType}/update-notification-status`,data),
  GENERATE_PAYMENT_LINK:({userType,data})=>api.post(`/${userType}/create-payment-link`,data),
  GET_COUNTRY_LIST:()=>axios.get("https://restcountries.com/v3.1/all"),
  UPLOAD_AUDIO:({data})=>api.post("/upload-file",data),
  RESET_PASSWORD:({ data }) => api.post("/reset-password", data),
  UPDATE_PASSWORD:({ data }) => api.put("/update-password", data),
  SENT_PAYMENT_LINK_WHATSAPP:({userType,data})=>api.post(`/${userType}/send-payment-link-to-client`,data),
  CHANGE_CALL_HIPPO_PERMISSION:({userType,data})=>api.post(`/${userType}/switch-call-hippo-permission`,data),
  GET_OPERATOR_MANAGER_LIST_ACTION:({userType})=>api.get(`/${userType}/operator-or-manager-list-option`),
  REQUEST_FOR_ASSIGN_LEAD:({userType,data})=>api.put(`/${userType}/request-assign-lead`,data),
  GET_ALL_LIVE_USER_LIST:({userType,filter})=>api.post(`/${userType}/get-all-live-user`,filter),
  GET_OPEN_USER_CHAT_LIST:({data})=>api.post(`/get-open-user-chat`,data),
  GET_OPEN_NOTIFICATION:({data})=>api.post(`/get-open/notication`,data),
  GET_REPORTS:({userType,data})=>api.post(`/${userType}/get-report`,data),
  GET_LEAD_DETAILS:({userType,data})=>api.post(`/${userType}/get-lead-detail`,data),
  GET_EXPERT_QUERIES:({userType,data,page})=>api.post(`/${userType}/get-queries?page=${page}`,data),
  UPDATE_QUERY_STATUS:({userType,data})=>api.put(`/${userType}/update-query-status`,data),
  UPDATE_CURRENCY:({userType,data})=>api.put(`/${userType}/update-currenccies`,data),
  GET_CURRENCY_LIST:({userType,data})=>api.get(`/${userType}/get-currency`,data),
  UNASSIGNED_LEAD:({userType,data})=>api.post(`/${userType}/remove-assign-lead`,data),
  VERIFY_GOOGLE_TOKEN:({data})=>api.post(`/google/verify-email`,data),
  GET_ORDER_ACTIVITY:({userType,data})=>api.get(`/${userType}/get-order-activity/${data?.leadId}`),
  SET_PENALTY:({userType,data})=>api.post(`/${userType}/set-penalty`,data),
  GET_WELCOME_SCREEN_DATA:({data})=>api.get(`/get-welcome-screen?screenName=${data?.screenName}`),
  ON_UPDATE_ANDROID_DESIGN:({userType,data})=>api.put(`/${userType}/update-mobile-screen`,data),
  UPDATE_REFERAL_AMOUNT:({userType,data})=>api.put(`/${userType}/update-referal`,data),
  GET_REFERAL_AMOUNT:({userType})=>api.get(`/${userType}/get-referal-amount`),
  VERIFY_COUPON_CODE: ({ data }) => api.post(`/verify-coupon`, data),
  UPDATE_CLIENT_WALLET:({userType,data})=> api.put(`/${userType}/update/client-wallet`, data),
  ALL_LIVE_LEADS:({userType,data})=> api.post(`/${userType}/facebook/live-ads`, data),
  GET_LIVE_LEADS_DETAILS:({userType,data})=> api.post(`/${userType}/live/get-lead-detail`, data),
  LIVE_ADS_STATUS_UPDATE:({userType,data})=> api.post(`/${userType}/live/form-lead`, data),
  GET_LEAD_ACTIVITY:({userType,data})=>api.get(`/${userType}/get-lead-activity/${data?.leadId}`),
  GET_REPORT_ORDER_LIST:({userType,data})=>api.post(`/${userType}//report/order-list/`,data),
  GET_USER_DETAILS:({userType,data})=>api.post(`/${userType}/report/user-details/`,data),
  GET_USER_REPORTLIST:({userType,data})=>api.post(`/${userType}/report/user-list/`,data),
  GET_LIVE_USER_TITLE_COUNT:({userType})=>api.get(`/${userType}/live-user/chat-header/`),
  UPDATE_LIVE_USER:({userType,data})=> api.put(`/${userType}/live-user/update-user`, data),
  UPDATE_AI_MODE:({userType,data})=> api.put(`/${userType}/update-ai-status`, data),
  DELETE_USER_ACTION:({userType})=> api.delete(`/${userType}/delete-account`),
  UPDATE_DETAILS:({userType,data})=> api.put(`/${userType}/update-details`,data),
  CHECK_CLIENT_RAZOR_PAY_PAYMENT:({data})=> api.post(`/razorpay/callback`,data),
  CREATE_SERVICES:({userType,data})=> api.post(`/${userType}/create-services`,data),
  GET_SERVICES:({userType,data})=> userType?api.post(`/${userType}/get-services`,data):api.post(`/get-services`,data),
  UPDATE_SERVICE:({userType,data})=> api.put(`/${userType}/update-services`,data),
  DELETE_SERVICE:({userType,id})=> api.delete(`/${userType}/delete-service/${id}`),

  CREATE_SUBJECT:({userType,data})=> api.post(`/${userType}/create-subject`,data),
  GET_SUBJECT:({userType,data})=> api.post(`/${userType}/get-subject`,data),
  UPDATE_SUBJECT:({userType,data})=> api.put(`/${userType}/update-subject`,data),
  DELETE_SUBJECT:({userType,id})=> api.delete(`/${userType}/delete-subject/${id}`),
  GET_SUBJECT_BY_CATEGORY:({data})=> api.post(`/get-subject-by-category`,data),
  GET_SUBJECT_PRICING:({data})=> api.post(`/check-subject-pricing`,data),
  CREATE_CLIENT_ORDER:({userType,data})=> api.post(`/${userType}/create-order`,data),
  
}