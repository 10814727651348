"use client";

import { useRef, useState } from "react";
import { Delete, HelpCircle } from "lucide-react";
import {
  pagesWordsList,
  timeDurations,
} from "../../../../components/utils/constant/ServiceSubject";
import { uploadImageAction } from "../../../../services/action/common";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../../../components/utils/Icons";
import PaymentConfirmation from "./payment/PaymentConfirmation";
import { validateForm } from "../../../../components/utils/functions/functions";

// FormFields Component
const FormFields = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  services,
  setValue,
  getSubjectBySubjectId,
  subjectsCategory,
  uploadedFiles,
  setUploadedFiles,
  setCurrentStep,
  leadDetails,
}) => {
  const dispatch = useDispatch();
  const fileRef = useRef(null);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { isImageLoading, selectedSubject } = useSelector(
    (state) => state.commonReducer
  );

  const getFileType = (fileName) => {
    const extension = fileName.split(".").pop()?.toLowerCase() || "";
    const imageTypes = ["jpg", "jpeg", "png", "gif", "webp"];
    const docTypes = ["doc", "docx", "pdf", "txt"];
    const videoTypes = ["mp4", "mov", "avi", "webm"];

    if (imageTypes.includes(extension)) return "image";
    if (docTypes.includes(extension)) return "doc";
    if (videoTypes.includes(extension)) return "video";
    return "file";
  };

  const handleFileUpload = (e) => {
    const callBackImage = (res) => {
      if (res?.imageUrls && res.imageUrls.length > 0) {
        const newFiles = res.imageUrls.map((url) => ({
          url,
          name: url.split("/").pop() || "",
          type: getFileType(url),
        }));
        setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setValue("document", [
          ...res.imageUrls,
          ...uploadedFiles?.map((item) => item.url),
        ]); // Update form value
      }
    };

    const formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("images", e.target.files[i]);
    }
    dispatch(uploadImageAction({ data: formData, cb: callBackImage }));
    e.target.value = null;
  };

  const deleteFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, i) => i !== index);
      setValue("document", newFiles); // Update form value
      return newFiles;
    });
  };

  const handleContinue = (data) => {
    if (validateForm({ data, setValue })) {
      setCurrentStep(2);
    }
  };

  return (
    <div className="space-y-6 px-0">
      <PaymentConfirmation
        isOpen={isPaymentModalOpen}
        setValue={setValue}
        onClose={() => setIsPaymentModalOpen(false)}
        onDone={() => onSubmit()}
        totalAmount={selectedSubject?.finalPrice}
      />
      {/* Service and Subject Selection Row */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div className="flex items-center gap-1.5 mb-2">
            <span className="text-[15px] text-gray-600">
              Your selected service<span className="text-danger">*</span>
            </span>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("service", { required: "Service is required" })}
            className={`w-full border ${
              errors.service ? "border-red-500" : "border-gray-200"
            } rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white`}
            onChange={(e) => {
              const selectedService = services.find(
                (s) => s._id === e.target.value
              );
              setValue("service", e.target.value);
              setValue(
                "serviceName",
                selectedService ? selectedService.serviceName : ""
              );
              getSubjectBySubjectId({ serviceId: e.target.value });
            }}
          >
            <option value="">Select a service</option>
            {services?.map((item) => (
              <option key={item?._id} value={item?._id}>
                {item?.serviceName}
              </option>
            ))}
          </select>
          {errors.service && (
            <p className="text-red-500 text-sm mt-1">
              {errors.service.message}
            </p>
          )}
        </div>

        <div>
          <div className="flex items-center gap-1.5 mb-2">
            <span className="text-[15px] text-gray-600">
              Select Subject<span className="text-danger">*</span>
            </span>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("subject", { required: "Subject is required" })}
            className={`w-full border ${
              errors.subject ? "border-red-500" : "border-gray-200"
            } rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white`}
            onChange={(e) => {
              const selectedSubject = subjectsCategory?.find(
                (s) => s.value === e.target.value
              );
              setValue("subject", e.target.value);
              setValue(
                "subjectName",
                selectedSubject ? selectedSubject.label : ""
              );
            }}
          >
            <option value="">Select Subject</option>
            {subjectsCategory?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
          {errors.subject && (
            <p className="text-red-500 text-sm mt-1">
              {errors.subject.message}
            </p>
          )}
        </div>
      </div>

      {/* Pages, Urgency, and Deadline Row */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div>
          <div className="flex items-center gap-1.5 mb-2">
            <span className="text-[15px] text-gray-600">
              Select Pages/Words<span className="text-danger">*</span>
            </span>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("pages")}
            className="w-full border border-gray-200 rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white"
          >
            {pagesWordsList?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <div className="flex items-center gap-1.5 mb-2">
            <span className="text-[15px] text-gray-600">
              Select Urgency<span className="text-danger">*</span>
            </span>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <select
            {...register("urgency")}
            className="w-full border border-gray-200 rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white"
          >
            {timeDurations?.map((item) => (
              <option key={item?.value} value={item?.value}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <div className="flex items-center gap-1.5 mb-2">
            <span className="text-[15px] text-gray-600">
              Deadline<span className="text-danger">*</span>
            </span>
            <HelpCircle className="w-4 h-4 text-gray-400" />
          </div>
          <input
            type="datetime-local"
            {...register("clientDeadline", {
              required: "Deadline is required",
            })}
            className={`w-full border ${
              errors.clientDeadline ? "border-red-500" : "border-gray-200"
            } rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent bg-white`}
            placeholder="mm/dd/yyyy"
          />
          {errors.clientDeadline && (
            <p className="text-red-500 text-sm mt-1">
              {errors.clientDeadline.message}
            </p>
          )}
        </div>
      </div>

      {/* Topic Input */}
      <div>
        <div className="flex items-center gap-1.5 mb-2">
          <span className="text-[15px] text-gray-600">
            Enter Topic<span className="text-danger">*</span>
          </span>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <input
          type="text"
          {...register("topic", { required: "Topic is required" })}
          className={`w-full border ${
            errors.topic ? "border-red-500" : "border-gray-200"
          } rounded-md h-[42px] px-3 text-[15px] focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
          placeholder="Enter your topic"
        />
        {errors.topic && (
          <p className="text-red-500 text-sm mt-1">{errors.topic.message}</p>
        )}
      </div>

      {/* Requirements Textarea */}
      <div>
        <div className="flex items-center gap-1.5 mb-2">
          <span className="text-[15px] text-gray-600">
            Specify Your Requirements Here<span className="text-danger">*</span>
          </span>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <textarea
          {...register("requirement", {
            required: "Requirements are required",
          })}
          className={`w-full border ${
            errors.requirement ? "border-red-500" : "border-gray-200"
          } rounded-md p-3 text-[15px] h-32 focus:ring-2 focus:ring-[#2563EB] focus:border-transparent`}
          placeholder="The more instructions you provide, the better our writers can craft a paper that meets your needs."
        />
        {errors.requirement && (
          <p className="text-red-500 text-sm mt-1">
            {errors.requirement.message}
          </p>
        )}
      </div>

      {/* File Upload */}
      <div>
        <div className="flex items-center gap-1.5 mb-2">
          <span className="text-[15px] text-gray-600">Upload</span>
          <HelpCircle className="w-4 h-4 text-gray-400" />
        </div>
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-4">
          <input
            type="file"
            ref={fileRef}
            onChange={handleFileUpload}
            className="hidden"
            id="file-upload"
            multiple
            accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx,.mp4,.mov"
          />
          <label htmlFor="file-upload" className="block cursor-pointer">
            <div className="w-full rounded-lg p-3 text-center border border-[#2563EB] text-[#2563EB] hover:bg-blue-50 transition-colors">
              Upload Files
            </div>
          </label>

          {isImageLoading && (
            <div className="mt-4 flex items-center justify-center text-sm text-gray-500">
              <Icons title="loader" className="w-4 h-4 animate-spin mr-2" />
              Uploading...
            </div>
          )}

          {uploadedFiles.length > 0 && (
            <div className="mt-4 space-y-2">
              {uploadedFiles.map((file, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-gray-50 p-2 rounded-lg group relative"
                >
                  <div className="flex items-center gap-3">
                    <Icons
                      title={
                        file.type === "image"
                          ? "image"
                          : file.type === "doc"
                          ? "file-text"
                          : file.type === "video"
                          ? "video"
                          : "file"
                      }
                      className={`w-5 h-5 ${
                        file.type === "image"
                          ? "text-blue-500"
                          : file.type === "doc"
                          ? "text-yellow-500"
                          : file.type === "video"
                          ? "text-purple-500"
                          : "text-gray-500"
                      }`}
                    />
                    <span className="text-sm text-gray-600 truncate max-w-[200px]">
                      {file.name}
                    </span>
                  </div>
                  <button
                    type="button"
                    onClick={() => deleteFile(index)}
                    className="absolute -top-2 -right-2 bg-white rounded-full p-1 shadow-sm hover:bg-red-50"
                  >
                    <Delete title="x" className="w-4 h-4 text-red-500" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Terms Checkbox */}
      <div className="flex items-start gap-2">
        <input
          type="checkbox"
          id="terms"
          {...register("terms", { required: "You must accept the terms" })}
          className={`mt-1 rounded border-gray-300 ${
            errors.terms ? "border-red-500" : ""
          }`}
        />
        <label htmlFor="terms" className="text-[15px] text-gray-600">
          I agree with{" "}
          <a
            target="blank"
            href="https://www.clickinpedia.io/privacy"
            className="text-gray-600 underline"
          >
            Privacy Policy
          </a>
          ,{" "}
          <a
            target="blank"
            href="https://www.clickinpedia.io/terms"
            className="text-gray-600 underline"
          >
            Terms of Use
          </a>{" "}
          and{" "}
          <a
            target="blank"
            href="https://www.clickinpedia.io/privacy"
            className="text-gray-600 underline"
          >
            Money Back Guarantee
          </a>{" "}
          and also accept{" "}
          <a
            target="blank"
            href="https://www.clickinpedia.io/policy"
            className="text-gray-600 underline"
          >
            Cookie Policy
          </a>
          .
        </label>
      </div>
      {errors.terms && (
        <p className="text-red-500 text-sm mt-1">{errors.terms.message}</p>
      )}

      {/* Submit Button */}
      {leadDetails ? (
        <button
          type="button"
          className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300"
        >
          Order Created
        </button>
      ) : (
        <button
          onClick={handleSubmit(handleContinue)}
          type="button"
          className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300"
        >
          Continue to Select Writer
        </button>
      )}
    </div>
  );
};

export default FormFields;
