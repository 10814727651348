import React from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"
import { checkClientRazorpayAction, getUserAction } from "../../../services/action/authentication"
import { Download, ArrowRight } from "lucide-react"
import { returnDateWithTime } from "../../../components/utils/functions/functions"
import ScreenLoader from "../../../components/utils/ScreenLoader"
const CheckPaymentStatus = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [paymentDetails, setPaymentDetails] = useState(null)
  const [paymentStatus, setPaymentStatus] = useState(null)
  const [finalData, setFinalData] = useState({
    lead: {},
    payments: {},
  })
  const { user } = useSelector((state) => state.authenticationReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const paymentData = {
      leadId: searchParams.get("leadId") || undefined,
      razorpay_payment_id: searchParams.get("razorpay_payment_id") || "",
      razorpay_payment_link_id: searchParams.get("razorpay_payment_link_id") || "",
      razorpay_payment_link_reference_id: searchParams.get("razorpay_payment_link_reference_id") || "",
      razorpay_payment_link_status: searchParams.get("razorpay_payment_link_status") || "",
      razorpay_signature: searchParams.get("razorpay_signature") || "",
    }

    const requiredFields = [
      paymentData.razorpay_payment_id,
      paymentData.razorpay_payment_link_id,
      paymentData.razorpay_payment_link_status,
      paymentData.razorpay_signature,
    ]
    const isValid = requiredFields.every((value) => value !== "")

    if (isValid) {
      setPaymentDetails(paymentData)
    } else {
      console.error("Invalid or incomplete payment details:", paymentData)
    }
  }, [location.search])

  useEffect(() => {
    const verifyPayment = async () => {
      if (user?._id && paymentDetails) {
        try {
          const cb = (res) => {
            setLoading(false)
            setPaymentStatus("success")
            setFinalData(res)
          }
          const errorCb = () => {
            setLoading(false)
            setPaymentStatus("failed")
          }
          dispatch(checkClientRazorpayAction({ data: paymentDetails, cb, errorCb }))
        } catch (error) {
          setPaymentStatus("failed")
          setLoading(false)
        }
      }
    }

    verifyPayment()
  }, [user, paymentDetails, dispatch])

  useEffect(() => {
    dispatch(getUserAction({}))
  }, [dispatch])

  if (loading) {
    return (
    <ScreenLoader/>
    )
  }

  if (paymentStatus) {
    const paymentDetails = finalData.payments
    const leadDetails = finalData.lead

    const details = {
      name: user?.name || "N/A",
      email: user?.email || "N/A",
      paymentDate: returnDateWithTime(paymentDetails?.createdAt),
      paymentId: paymentDetails?.transationOrderId || "N/A",
      orderId: leadDetails?.orderId || "N/A",
      subject: leadDetails?.subject || "N/A",
      totalPayment: leadDetails?.totalPayment?.toFixed(2) || "0.00",
      balancePayment: leadDetails?.balanceAmount?.toFixed(2) || "0.00",
      paidAmount: paymentDetails?.amount?.toFixed(2) || "0.00",
      paymentMode: paymentDetails?.paymentMethod ||"Online",
      deadline: returnDateWithTime(leadDetails?.clientDeadline),
      plan: leadDetails?.plan || "N/A",
      currency: paymentDetails?.currency || "USD",
    }

    const statusColor = paymentStatus === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"

    const downloadPDF = () => {
      const token = localStorage.getItem("token")
      const paymentLinkId = paymentDetails?.paymentLinkId
      const leadId = leadDetails?._id
      window.open(
        `http://localhost:9000/download-order-reciept?leadId=${leadId}&paymentLinkId=${paymentLinkId}&token=${token}`,
        "_self",
      )
    }
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-600 to-blue-800 p-4">
        <div id="payment-receipt" className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-auto">
          <div className="p-6 md:p-8">
            <div className="flex flex-col md:flex-row justify-between items-start mb-8">
              <div className="mb-4 md:mb-0">
                <h1 className="text-2xl font-bold text-gray-800">Clickinpedia</h1>
                <p className="text-sm text-gray-600">Payment Receipt</p>
              </div>
              <div className={`px-4 py-2 rounded-full text-sm font-medium ${statusColor}`}>
                {paymentStatus === "success" ? "Payment Successful" : "Payment Failed"}
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-6 mb-8">
              <div>
                <h2 className="text-sm font-semibold text-gray-600 mb-2">Client Details</h2>
                <p className="text-sm">{details.name}</p>
                <p className="text-sm">{details.email}</p>
              </div>
              <div>
                <h2 className="text-sm font-semibold text-gray-600 mb-2">Payment Information</h2>
                <p className="text-sm">Date: {details.paymentDate}</p>
                <p className="text-sm">Payment ID: {details.paymentId}</p>
                <p className="text-sm">Order ID: {details.orderId}</p>
              </div>
            </div>

            <div className="overflow-x-auto mb-8">
              <table className="w-full">
                <thead>
                  <tr className="border-b border-gray-200">
                    <th className="text-left py-2 text-sm font-semibold text-gray-600">Description</th>
                    <th className="text-right py-2 text-sm font-semibold text-gray-600">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="py-2 text-sm">{details.subject}</td>
                    <td className="py-2 text-sm text-right">
                      {details.paidAmount} {details.currency}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-200">
                    <td className="py-2 text-sm font-semibold">Total Payment</td>
                    <td className="py-2 text-sm font-semibold text-right">
                      {details.totalPayment} {details.currency}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 text-sm">Paid Amount</td>
                    <td className="py-2 text-sm text-right">
                      {details.paidAmount} {details.currency}
                    </td>
                  </tr>
                  <tr className="border-t border-gray-200">
                    <td className="py-2 text-sm font-semibold">Balance Payment</td>
                    <td className="py-2 text-sm font-semibold text-right">
                      {details.balancePayment} {details.currency}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="grid md:grid-cols-2 gap-6 mb-8 text-sm">
              <div>
                <p className="mb-2">
                  <span className="font-semibold">Payment Mode:</span> {details.paymentMode}
                </p>
                <p className="mb-2">
                  <span className="font-semibold">Subject:</span> {details.subject}
                </p>
                <p>
                  <span className="font-semibold">Deadline:</span> {details.deadline}
                </p>
              </div>
              <div>
                <p className="mb-2">
                  <span className="font-semibold">Plan:</span> {details.plan}
                </p>
                <p>
                  <span className="font-semibold">Currency:</span> {details.currency}
                </p>
              </div>
            </div>

            {paymentStatus !== "success" && (
              <div className="bg-amber-50 border border-amber-200 rounded-lg p-4 text-sm text-amber-800 mb-8">
                If the amount was deducted from your account, it will be refunded within 3-7 business days.
              </div>
            )}

            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <p className="text-xs text-gray-600 order-2 md:order-1">Thank you for choosing Clickinpedia!</p>
              <div className="flex flex-col md:flex-row gap-4 order-1 md:order-2 w-full md:w-auto">
                <button
                  onClick={() => navigate("/client/dashboard")}
                  className="flex items-center justify-center gap-2 px-6 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors w-full md:w-auto"
                >
                  Go to Dashboard
                  <ArrowRight className="w-5 h-5" />
                </button>
                <button
                  className={`flex items-center justify-center gap-2 px-6 py-2 text-white rounded-lg transition-colors w-full md:w-auto ${
                    paymentStatus === "success" ? "bg-green-600 hover:bg-green-700" : "bg-blue-600 hover:bg-blue-700"
                  }`}
                  onClick={downloadPDF}
                >
                  <Download className="w-5 h-5" />
                  Download Receipt
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default CheckPaymentStatus

