"use client"

import { useState } from "react"
import { Calendar } from "lucide-react"

const DateRangePicker = ({ onRangeSelected }) => {
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault()
    if (startDate && endDate) {
      onRangeSelected(new Date(startDate), new Date(endDate))
    }
  }

  return (
    <div className="bg-[#0A2547] p-4 rounded-lg shadow-lg">
      <form onSubmit={handleSubmit} className="flex flex-wrap items-end gap-4">
        <div className="flex-1 min-w-[200px]">
          <label htmlFor="start-date" className="block text-sm font-medium text-gray-400 mb-1">
            Start Date
          </label>
          <div className="relative">
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="bg-[#001B3D] text-white text-sm py-2 px-3 pr-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
            />
            <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          </div>
        </div>
        <div className="flex-1 min-w-[200px]">
          <label htmlFor="end-date" className="block text-sm font-medium text-gray-400 mb-1">
            End Date
          </label>
          <div className="relative">
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="bg-[#001B3D] text-white text-sm py-2 px-3 pr-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
            />
            <Calendar className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white text-sm py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
        >
          Apply
        </button>
      </form>
    </div>
  )
}

export default DateRangePicker

