import React, { Fragment, useEffect, useState } from "react";
import SelectBox from "../../../utils/select/SelectBox";
import { useDispatch, useSelector } from "react-redux";
import { getClientListOptionAction } from "../../../../services/action/manager";
import CountryDropdown from "../../../utils/country-dropdown/CountryDropdown";

const BasicClientDetail = ({ data, register, getWatch, errors, setValue }) => {
  const { clientOption } = useSelector((state) => state.managerReducer);
  const { user } = useSelector((state) => state.authenticationReducer);
  const [clientEmail, setClientEmail] = useState("");
  const isManager = user?.userType === "MANAGER";
  const isAdminOrSuperAdmin =
    user?.userType === "ADMIN" || user?.userType === "SUPER_ADMIN";
    const isOperator=user?.userType==="OPERATOR";
  const dispatch = useDispatch();
  useEffect(() => {
    const userType = user?.userType?.toLowerCase();
    if (userType && userType !== "client") {
      dispatch(getClientListOptionAction({ userType }));
    } else if (userType === "client") {
      setClientEmail(user?.email);
    }
  }, [user, dispatch]);

  const selectedData = clientOption?.data?.find(
    (item) => item.value === getWatch?.client
  );
  console.log("data",data)

  return (
    <div className="col-12">
      <div className="row">
        {(isManager ||
          user?.userType === "CLIENT" ||
          !data ||
          isAdminOrSuperAdmin) && (
          <Fragment>
            {user?.userType !== "CLIENT" && (
              <div className="col-sm-6">
                <label className={`form-label ${errors?.client ? "text-danger":""}`}>
                  Select Client<span className="text-danger">*</span>
                </label>
                <input
                  type="hidden"
                  {...register("client", { required: true })}
                />
                <SelectBox
                  creatable={true}
                  options={clientOption?.data || []}
                  value={selectedData}
                  onChange={(obj) => {
                    setValue("countryCode", obj?.countryCode);
                    setValue("client", obj?.value);
                    setValue("number", obj?.number);
                    setValue("email", obj?.email);
                    setValue("name", obj?.name);
                  }}
                />
              </div>
            )}

            {user?.userType === "CLIENT" && (
              <div className="col-sm-6">
                <label className={`form-label ${errors?.name ? "text-danger":""}`}>
                  Email<span className="text-danger">*</span>
                </label>
                <input
                  value={clientEmail}
                  type="text"
                  disabled={true}
                  className="form-control"
                />
              </div>
            )}

            <div className="col-sm-6">
              <label className={`form-label ${errors?.number ? "text-danger":""}`}>
                Contact No <span className="text-danger">*</span>
              </label>
              <CountryDropdown
                value={getWatch?.countryCode}
                disabled={data || user?.userType === "CLIENT"}
                onChange={(e) => setValue("countryCode", e?.countryCode)}
              />
              <input
                {...register("number", { required: true })}
                type="text"
                disabled={
                  (data || user?.userType === "CLIENT") && !isAdminOrSuperAdmin
                }
                style={{
                  paddingLeft: getWatch?.countryCode?.length > 3 ? 59 : 40,
                }}
                className="form-control"
              />
            </div>
          </Fragment>
        )}

        <div className="col-sm-6">
          <label className={`form-label ${errors?.name ? "text-danger":""}`}>
            Name<span className="text-danger">*</span>
          </label>
          <input
            {...register("name", { required: true })}
            type="text"
            disabled={
              (data || user?.userType === "CLIENT") && !isAdminOrSuperAdmin
            }
            className="form-control"
          />
        </div>
        <div className="col-sm-6">
          <label className={`form-label ${errors?.category ? "text-danger":""}`}>
            Category<span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            {...register("category", {
              required: true,
            })}
            disabled={(!isManager && !isOperator && !isAdminOrSuperAdmin && data)}
          >
            <option></option>
            <option value={"Assignment"}>Assignment</option>
            <option value={"SOP"}>SOP</option>
            <option className="Other">Other</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default BasicClientDetail;
