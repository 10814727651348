import React, { useEffect, useState } from 'react'
import Icons from '../../utils/Icons'

const Assignement=["Assignment Help","Dissertation Help","Homework Help","Coursework Help","Assessment Help","Thesis Help","Essay Help","Research Proposal","Exam Help","Sample","Other"];
const SOP = [
  "SOP Assistance",  
  "LOI Writing Help",  
  "LOR Writing Help",  
  "Resume Editing",  
  "CV Writing Help",  
  "Other Documents"
];

const InitialChatSelection = ({onSelect,setShowInitialMessage}) => {
  const [options,setOptions]=useState([]);

  

  useEffect(()=>{
    const location=window.location
    const params = new URLSearchParams(location.search);
    const serviceType = params.get("serviceType"); 
    if(serviceType=="SOP"){
      setOptions(SOP)
    }else{
       setOptions(Assignement)
    }
  },[])

  return (
    <div className='subject-selection'>
         <p className='first-heading'>Do you <b>Need Help</b>? <span style={{marginTop:"-10px",display:"inline-block",marginLeft:"35px"}} onClick={()=>setShowInitialMessage(false)}> <Icons title={"cross"} color="black" /></span></p>
         <ul>
            {options?.map((item)=>(
              <li onClick={()=>onSelect(item,true)}>{item}</li>
            ))}
         </ul>
        
    </div>
  )
}

export default InitialChatSelection