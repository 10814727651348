"use client";

import { ArrowLeft, Crown, Diamond, Users } from "lucide-react";
import PaymentConfirmation from "./payment/PaymentConfirmation";
import { useState } from "react";
import { useSelector } from "react-redux";

const WriterSelectionStep = ({
  setValue,
  handleBack,
  handleSubmit,
  onSubmit,
  watch,
}) => {
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const { selectedSubject } = useSelector((state) => state.commonReducer);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <PaymentConfirmation
        isOpen={isPaymentModalOpen}
        setValue={setValue}
        onClose={() => setIsPaymentModalOpen(false)}
        onDone={handleSubmit(onSubmit)}
        totalAmount={selectedSubject?.finalPrice}
      />
      <button
        onClick={handleBack}
        className="mb-6 text-sm text-[#2563EB] hover:text-[#1d4ed8] transition duration-300 flex items-center gap-2"
      >
        <ArrowLeft className="w-4 h-4" />
        Back to Order Details
      </button>

        <h1 className="text-2xl font-bold text-gray-900 mb-2">
          Select your writer's level
        </h1>
        <p className="text-gray-600 mb-8">
          We'll match you with one of our 1,366 writers specializing in your
          subject. You'll be able to chat with them and compare prices before
          hiring the one you like.
        </p>

        <div className="space-y-4">
          {/* Platinum Writer Option */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "platinum"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Diamond className="w-8 h-8 text-[#2563EB]" />
                <div>
                  <h3 className="text-xl font-semibold mb-1">Platinum</h3>
                  <p className="text-gray-700">
                    Hire one of our <span className="font-bold">TOP 20</span>{" "}
                    highest-rated writers in your subject.
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Due to high demand, these writers cost an extra 20%.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel", "platinum")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "platinum"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Select
              </button>
            </div>
          </div>

          {/* Gold Writer Option */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "gold"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Crown className="w-8 h-8 text-yellow-500" />
                <div>
                  <div className="flex items-center gap-2 mb-1">
                    <h3 className="text-xl font-semibold">Gold</h3>
                    <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded">
                      Popular
                    </span>
                  </div>
                  <p className="text-gray-700">
                    Hire one of our <span className="font-bold">TOP 50</span>{" "}
                    highest-rated writers in your subject.
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Due to high demand, these writers cost an extra 10%.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel", "gold")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "gold"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Select
              </button>
            </div>
          </div>

          {/* Silver Writer Option */}
          <div
            className={`border rounded-lg p-6 transition-all duration-300 ${
              watch("writerLevel") === "silver"
                ? "border-[#2563EB] bg-blue-50"
                : "hover:border-gray-400"
            }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex gap-4">
                <Users className="w-8 h-8 text-gray-600" />
                <div>
                  <h3 className="text-xl font-semibold mb-1">Silver</h3>
                  <p className="text-gray-700">
                    You'll see the list of all available writers specializing in
                    your subject.
                  </p>
                  <p className="text-sm text-gray-500 mt-2">
                    Standard pricing, no additional cost.
                  </p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setValue("writerLevel","silver")}
                className={`px-8 py-2 rounded-md text-sm font-medium transition duration-300 ${
                  watch("writerLevel") === "silver"
                    ? "bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white"
                    : "border border-[#2563EB] text-[#2563EB] hover:bg-blue-50"
                }`}
              >
                Select
              </button>
            </div>
          </div>

          {/* Hand-picked Writer Toggle */}
          {/* <div className="border rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200">
                <input type="checkbox" className="peer sr-only" {...register("handPickedWriter")} id="handPicked" />
                <span
                  className={`inline-block h-5 w-5 transform rounded-full bg-white transition ${
                    watch("handPickedWriter") ? "translate-x-6" : "translate-x-1"
                  }`}
                />
              </div>
              <label htmlFor="handPicked" className="text-gray-700">
                Let us hand-pick the best writer for you. It'll cost an extra{" "}
                <span className="font-semibold">$9.99</span>.
              </label>
            </div>
          </div>
        </div> */}

          <div className="border-l-4 border-[#2563EB] pl-4 mt-6">
            <p className="text-gray-600 text-sm">
              All our professionals have the necessary qualifications and
              experience, so they always follow instructions and meet deadlines.
            </p>
          </div>

          <button
            onClick={() => setIsPaymentModalOpen(true)}
            type="button"
            className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300"
          >
            Continue to Payment
          </button>
        </div>
    </div>
  );
};

export default WriterSelectionStep;
