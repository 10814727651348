import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Star,
  User,
  MessageSquare,
  Users,
  Gift,
  LogOut,
  Info,
  Eye,
  EyeOff,
  Check,
  Mail,
  MapPin,
  School,
  Book,
  Lock,
  ChevronDown,
} from "lucide-react"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import { logoutAction } from "../../../services/action/authentication"

const countryCodes = [
  { code: "+1", country: "USA" },
  { code: "+44", country: "UK" },
  { code: "+91", country: "India" },
  { code: "+86", country: "China" },
  { code: "+81", country: "Japan" },
  { code: "+49", country: "Germany" },
  { code: "+33", country: "France" },
  { code: "+7", country: "Russia" },
  { code: "+55", country: "Brazil" },
  { code: "+61", country: "Australia" },
];

export default function ClientProfile() {
  const { user } = useSelector((state) => state.authenticationReducer);
  const [activeTab, setActiveTab] = useState("general");
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [universities, setUniversities] = useState([]);
  const [fieldsOfStudy, setFieldsOfStudy] = useState([]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    fetchCountries();
    fetchFieldsOfStudy();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await axios.get("https://restcountries.com/v3.1/all");
      setCountries(
        response.data.map((country) => ({
          name: country.name.common,
          code: country.cca2,
        }))
      );
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchStates = async (countryName) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?country=${countryName}&featureType=state&format=json`
      );
      setStates(
        response.data.map((state) => ({
          name: state.display_name.split(",")[0],
          code: state.place_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const fetchCities = async (stateName, countryName) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?state=${stateName}&country=${countryName}&featureType=city&format=json`
      );
      setCities(
        response.data.map((city) => ({
          name: city.display_name.split(",")[0],
          code: city.place_id,
        }))
      );
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const fetchUniversities = async (country) => {
    try {
      const response = await axios.get(
        `http://universities.hipolabs.com/search?country=${country}`
      );
      setUniversities(
        response.data.map((uni) => ({
          name: uni.name,
          code: uni.alpha_two_code,
        }))
      );
    } catch (error) {
      console.error("Error fetching universities:", error);
    }
  };

  const fetchFieldsOfStudy = async () => {
    try {
      const response = await axios.get(
        "https://jsonplaceholder.typicode.com/users"
      );
      setFieldsOfStudy(
        response.data.map((user) => ({
          name: user.company.bs,
          code: user.id.toString(),
        }))
      );
    } catch (error) {
      console.error("Error fetching fields of study:", error);
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    // Handle form submission
    // You would typically send this data to your backend API
  };

  const dispatch=useDispatch();
  const navigate=useNavigate();
  const logout=()=>{
    localStorage.clear();
    dispatch(logoutAction({}))
    navigate("/")

    
  }

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 mt-2">
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-[280px_1fr]">
        {/* Sidebar */}
        <div className="h-fit rounded-xl border border-slate-200 bg-white shadow">
          <div className="border-b bg-gradient-to-br from-[#2196F3] to-[#1976D2] p-4 text-white">
            <h1 className="text-2xl font-semibold text-white">
              My Clickinpedia
            </h1>
          </div>

          <div className="p-6 space-y-6">
            <div className="flex items-start gap-4">
              <img
                src={user?.image || "https://i.pravatar.cc/64"}
                alt="User avatar"
                className="h-16 w-16 rounded-full object-cover ring-2 ring-white shadow-lg"
              />
              <div>
                <h2 className="text-lg font-medium text-slate-900">
                  {user?.name}
                </h2>
                <div className="flex gap-1">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="h-4 w-4 text-slate-200" />
                  ))}
                </div>
              </div>
            </div>

            <div className="grid grid-cols-3 gap-4 border-y border-slate-200 py-4">
              <div className="text-center">
                <p className="text-2xl font-semibold text-slate-900">0</p>
                <p className="text-xs text-slate-500 whitespace-nowrap">
                  Completed
                </p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-semibold text-red-500">4</p>
                <p className="text-xs text-slate-500 whitespace-nowrap">
                  Cancelled
                </p>
              </div>
              <div className="text-center">
                <p className="text-2xl font-semibold text-[#2196F3]">0</p>
                <p className="text-xs text-slate-500 whitespace-nowrap">
                  In progress
                </p>
              </div>
            </div>

            <nav className="space-y-1">
              <button
                onClick={() => setActiveTab("general")}
                className={`flex w-full items-center gap-3 rounded-lg px-3 py-2.5 text-left text-sm transition-colors hover:bg-slate-100 ${
                  activeTab === "general"
                    ? "text-[#2196F3] font-medium"
                    : "text-slate-600"
                }`}
              >
                <User className="h-5 w-5" />
                <span>Profile</span>
              </button>
              <a
                href="#-"
                className="flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm text-slate-600 transition-colors hover:bg-slate-100"
              >
                <MessageSquare className="h-5 w-5" />
                <span>Testimonials</span>
              </a>
              <Link
                to="/client/share"
                className="flex items-center gap-3 rounded-lg px-3 py-2.5 text-sm text-slate-600 transition-colors hover:bg-slate-100"
              >
                <Users className="h-5 w-5" />
                <span>Refer a friend</span>
              </Link>
            </nav>

            <div className="space-y-4">
              <div className="flex items-center gap-3 text-slate-700">
                <Gift className="h-5 w-5" />
                <span>Clickinpedia Rewards</span>
              </div>
              <div className="rounded-lg bg-slate-50 p-4">
                <div className="flex items-start gap-4">
                  <div className="flex h-12 w-12 items-center justify-center rounded-full bg-slate-200">
                    <Gift className="h-6 w-6 text-slate-400" />
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-slate-600">
                      Rewards not available now
                    </p>
                    <a
                      href="#-"
                      className="text-sm text-[#2196F3] hover:underline"
                    >
                      Place your first order
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-4">
              <button onClick={logout} className="flex w-full items-center gap-3 rounded-lg px-3 py-2 text-slate-700 transition-colors hover:bg-slate-100">
                <LogOut className="h-5 w-5" />
                <span>Logout</span>
              </button>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="space-y-6">
          {/* Tabs */}
          <div className="inline-flex h-12 items-center justify-center rounded-lg bg-white p-1 shadow-sm">
            {["general", "contact", "security"].map((tab) => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`inline-flex items-center justify-center whitespace-nowrap rounded-md px-6 py-3 text-sm font-medium transition-all focus:outline-none ${
                  activeTab === tab
                    ? "bg-white text-[#2196F3] shadow"
                    : "text-slate-600 hover:bg-slate-50"
                }`}
              >
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>

          {/* General Tab */}
          {activeTab === "general" && (
            <div className="rounded-xl border border-slate-200 bg-white p-6 shadow">
              <div className="mb-6 flex items-center gap-3">
                <User className="h-8 w-8 text-[#2196F3]" />
                <h2 className="text-2xl font-semibold text-slate-900">
                  General information
                </h2>
              </div>

              <div className="mb-8 rounded-lg border-l-4 border-[#2196F3] bg-blue-50/50 p-4">
                <p className="text-slate-600">
                  Feel free to update your profile below any time. Your privacy
                  is always guaranteed, and your data is protected. This page is
                  visible only to our support team.
                </p>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid gap-6 md:grid-cols-2"
              >
                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-slate-700">
                    Full Name
                    <Info className="h-4 w-4 text-[#2196F3]" />
                  </label>
                  <input
                    type="text"
                    {...register("fullName", {
                      required: "Full name is required",
                    })}
                    defaultValue={user?.name || ""}
                    className="h-12 w-full rounded-lg border border-slate-200 px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                  />
                  {errors.fullName && (
                    <p className="text-sm text-red-500">
                      {errors.fullName.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-slate-700">
                    Email
                    <Mail className="h-4 w-4 text-[#2196F3]" />
                  </label>
                  <input
                    type="email"
                    value={user?.email || "satendrasahani20@gmail.com"}
                    readOnly
                    className="h-12 w-full rounded-lg border border-slate-200 bg-slate-50 px-4 py-2 text-sm"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-slate-700">
                    Gender
                  </label>
                  <div className="relative">
                    <select
                      {...register("gender", {
                        required: "Please select your gender",
                      })}
                      defaultValue={user?.gender || ""}
                      className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                    >
                      <option value="">Select your gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Other</option>
                    </select>
                    <ChevronDown className="pointer-events-none absolute right-4 top-3.5 h-5 w-5 text-slate-400" />
                  </div>
                  {errors.gender && (
                    <p className="text-sm text-red-500">
                      {errors.gender.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-slate-700">
                    Phone Number
                  </label>
                  <div className="flex">
                    <Controller
                      name="countryCode"
                      control={control}
                      defaultValue={user?.countryCode || "+1"}
                      render={({ field }) => (
                        <select
                          {...field}
                          className="h-12 rounded-l-lg border border-r-0 border-slate-200 bg-white px-2 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                        >
                          {countryCodes.map((country) => (
                            <option key={country.code} value={country.code}>
                              {country.code} ({country.country})
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    <input
                      type="tel"
                      {...register("phoneNumber", {
                        required: "Phone number is required",
                      })}
                      defaultValue={user?.phoneNumber || ""}
                      className="h-12 w-full rounded-r-lg border border-slate-200 px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                    />
                  </div>
                  {errors.phoneNumber && (
                    <p className="text-sm text-red-500">
                      {errors.phoneNumber.message}
                    </p>
                  )}
                </div>

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="mt-4 inline-flex items-center justify-center rounded-lg bg-[#2196F3] px-6 py-3 text-sm font-medium text-white shadow transition-colors hover:bg-[#1976D2] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/50"
                  >
                    <Check className="mr-2 h-5 w-5" />
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Contact Tab */}
          {activeTab === "contact" && (
            <div className="rounded-xl border border-slate-200 bg-white p-6 shadow">
              <div className="mb-6 flex items-center gap-3">
                <MapPin className="h-8 w-8 text-[#2196F3]" />
                <h2 className="text-2xl font-semibold text-slate-900">
                  Contact and school information
                </h2>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                className="grid gap-6 md:grid-cols-2"
              >
                <div className="space-y-2">
                  <label className="text-sm font-medium text-slate-700">
                    Country
                  </label>
                  <Controller
                    name="country"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select your country" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          fetchStates(e.target.selectedOptions[0].text);
                          setValue("state", "");
                          setValue("city", "");
                          fetchUniversities(e.target.selectedOptions[0].text);
                        }}
                        className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                      >
                        <option value="">Select your country</option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.country && (
                    <p className="text-sm text-red-500">
                      {errors.country.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-slate-700">
                    State
                  </label>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select your state" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          fetchCities(
                            e.target.selectedOptions[0].text,
                            watch("country")
                          );
                          setValue("city", "");
                        }}
                        className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                      >
                        <option value="">Select your state</option>
                        {states.map((state) => (
                          <option key={state.code} value={state.code}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state && (
                    <p className="text-sm text-red-500">
                      {errors.state.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="text-sm font-medium text-slate-700">
                    City
                  </label>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select your city" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                      >
                        <option value="">Select your city</option>
                        {cities.map((city) => (
                          <option key={city.code} value={city.code}>
                            {city.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.city && (
                    <p className="text-sm text-red-500">
                      {errors.city.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-slate-700">
                    <School className="h-5 w-5 text-[#2196F3]" />
                    University
                  </label>
                  <Controller
                    name="university"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select your university" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                      >
                        <option value="">Select your university</option>
                        {universities.map((university) => (
                          <option key={university.code} value={university.code}>
                            {university.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.university && (
                    <p className="text-sm text-red-500">
                      {errors.university.message}
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <label className="flex items-center gap-2 text-sm font-medium text-slate-700">
                    <Book className="h-5 w-5 text-[#2196F3]" />
                    Field of study
                  </label>
                  <Controller
                    name="fieldOfStudy"
                    control={control}
                    defaultValue=""
                    rules={{ required: "Please select your field of study" }}
                    render={({ field }) => (
                      <select
                        {...field}
                        className="h-12 w-full appearance-none rounded-lg border border-slate-200 bg-white px-4 py-2 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                      >
                        <option value="">Select your field of study</option>
                        {fieldsOfStudy.map((field) => (
                          <option key={field.code} value={field.code}>
                            {field.name}
                          </option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.fieldOfStudy && (
                    <p className="text-sm text-red-500">
                      {errors.fieldOfStudy.message}
                    </p>
                  )}
                </div>

                <div className="col-span-2">
                  <button
                    type="submit"
                    className="mt-4 inline-flex items-center justify-center rounded-lg bg-[#2196F3] px-6 py-3 text-sm font-medium text-white shadow transition-colors hover:bg-[#1976D2] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/50"
                  >
                    <Check className="mr-2 h-5 w-5" />
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          )}

          {/* Security Tab */}
          {activeTab === "security" && (
            <div className="rounded-xl border border-slate-200 bg-white p-6 shadow">
              <div className="mb-6 flex items-center gap-3">
                <Lock className="h-8 w-8 text-[#2196F3]" />
                <h2 className="text-2xl font-semibold text-slate-900">
                  Change password
                </h2>
              </div>

              <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                {/* Left Column - Password Form */}
                <div className="space-y-6">
                  <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div className="space-y-2">
                      <label className="text-sm font-medium text-slate-700">
                        Verify current password
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword.current ? "text" : "password"}
                          {...register("currentPassword", {
                            required: "Current password is required",
                          })}
                          className="h-12 w-full rounded-lg border border-slate-200 px-4 py-2 pr-12 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowPassword((prev) => ({
                              ...prev,
                              current: !prev.current,
                            }))
                          }
                          className="absolute right-3 top-3 text-slate-400 hover:text-[#2196F3]"
                        >
                          {showPassword.current ? (
                            <EyeOff className="h-5 w-5" />
                          ) : (
                            <Eye className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                      {errors.currentPassword && (
                        <p className="text-sm text-red-500">
                          {errors.currentPassword.message}
                        </p>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm font-medium text-slate-700">
                        New password
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword.new ? "text" : "password"}
                          {...register("newPassword", {
                            required: "New password is required",
                          })}
                          className="h-12 w-full rounded-lg border border-slate-200 px-4 py-2 pr-12 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowPassword((prev) => ({
                              ...prev,
                              new: !prev.new,
                            }))
                          }
                          className="absolute right-3 top-3 text-slate-400 hover:text-[#2196F3]"
                        >
                          {showPassword.new ? (
                            <EyeOff className="h-5 w-5" />
                          ) : (
                            <Eye className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                      {errors.newPassword && (
                        <p className="text-sm text-red-500">
                          {errors.newPassword.message}
                        </p>
                      )}
                    </div>

                    <div className="space-y-2">
                      <label className="text-sm font-medium text-slate-700">
                        Confirm password
                      </label>
                      <div className="relative">
                        <input
                          type={showPassword.confirm ? "text" : "password"}
                          {...register("confirmPassword", {
                            required: "Please confirm your new password",
                            validate: (value) =>
                              value === watch("newPassword") ||
                              "The passwords do not match",
                          })}
                          className="h-12 w-full rounded-lg border border-slate-200 px-4 py-2 pr-12 text-sm focus:border-[#2196F3] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/20"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            setShowPassword((prev) => ({
                              ...prev,
                              confirm: !prev.confirm,
                            }))
                          }
                          className="absolute right-3 top-3 text-slate-400 hover:text-[#2196F3]"
                        >
                          {showPassword.confirm ? (
                            <EyeOff className="h-5 w-5" />
                          ) : (
                            <Eye className="h-5 w-5" />
                          )}
                        </button>
                      </div>
                      {errors.confirmPassword && (
                        <p className="text-sm text-red-500">
                          {errors.confirmPassword.message}
                        </p>
                      )}
                    </div>

                    <div className="rounded-lg border border-slate-200 bg-slate-50 p-4">
                      <h3 className="mb-2 font-medium text-slate-900">
                        Password Requirements:
                      </h3>
                      <ul className="space-y-1 text-sm text-slate-600">
                        {[
                          "Minimum 6 symbols",
                          "Maximum 20 symbols",
                          "Letters and numbers only",
                          "Passwords should match",
                          "At least 1 number required",
                          "At least 1 letter required",
                        ].map((requirement, index) => (
                          <li key={index} className="flex items-center gap-2">
                            <div className="h-1.5 w-1.5 rounded-full bg-[#2196F3]" />
                            <span>{requirement}</span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <button
                      type="submit"
                      className="inline-flex w-full items-center justify-center rounded-lg bg-[#2196F3] px-6 py-3 text-sm font-medium text-white shadow transition-colors hover:bg-[#1976D2] focus:outline-none focus:ring-2 focus:ring-[#2196F3]/50"
                    >
                      <Lock className="mr-2 h-5 w-5" />
                      Update Password
                    </button>
                  </form>
                </div>

                {/* Right Column - Animated Advertisement */}
                <div className="relative overflow-hidden rounded-2xl bg-gradient-to-br from-[#2196F3] to-[#1976D2] p-6 text-white">
                  <div className="relative z-10 flex h-full flex-col items-center justify-center space-y-4 text-center">
                    <div className="rounded-full bg-white/20 p-4">
                      <Lock className="h-8 w-8" />
                    </div>
                    <h3 className="text-2xl font-bold text-white">
                      Premium Security Features
                    </h3>
                    <p className="max-w-sm text-white/90">
                      Upgrade now to get advanced security features, including
                      two-factor authentication, password history, and breach
                      monitoring.
                    </p>
                    <div className="space-y-3">
                      <button className="group relative w-full overflow-hidden rounded-lg bg-white px-6 py-3 text-sm font-medium text-[#2196F3] transition-all duration-300 hover:scale-105">
                        Order Now - Save 20%
                        <div className="absolute inset-0 -z-10 translate-x-[-100%] bg-gradient-to-r from-transparent via-white/25 to-transparent opacity-0 transition-all duration-500 group-hover:translate-x-[100%] group-hover:opacity-100" />
                      </button>
                      <button className="w-full rounded-lg border border-white/30 px-6 py-3 text-sm font-medium text-white backdrop-blur-sm transition-colors hover:bg-white/20">
                        Learn More
                      </button>
                    </div>
                  </div>
                  {/* Animated background elements */}
                  <div
                    className="absolute left-0 top-0 h-64 w-64 rounded-full bg-white/10 blur-3xl animate-pulse"
                    style={{ animationDuration: "7s" }}
                  />
                  <div
                    className="absolute bottom-0 right-0 h-64 w-64 rounded-full bg-white/10 blur-3xl animate-pulse"
                    style={{ animationDuration: "7s", animationDelay: "2s" }}
                  />
                  <div
                    className="absolute right-1/2 top-1/2 h-64 w-64 rounded-full bg-white/10 blur-3xl animate-pulse"
                    style={{ animationDuration: "7s", animationDelay: "4s" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
