"use client"

import { useState, useRef, useCallback, useEffect } from "react"
import { X, User, Search, Eye, ChevronDown, Package, CheckCircle, Clock, AlertCircle, Users } from "lucide-react"
import { useSelector } from "react-redux"
import ClientReport from "../client-report/ClientReport"
import DateRangePicker from "../client-report/DateRangePicker"
import { returnDateWithTime } from "../../utils/functions/functions"

const UserReportList = ({
  selectedMetric,
  setSelectedMetric,
  fetchOrderList,
  setPageData,
  pageData,
  setSearch,
  search,
  setSelectedDateFilter,
  dateRange,
}) => {
  const { reports, userReportList } = useSelector((state) => state.commonReducer)
  const [selectedUser, setSelectedUser] = useState(null)
  const [customDateRange, setCustomDateRange] = useState({ start: null, end: null })
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState({
    name: true,
    email: true,
    userType: true,
    status: true,
    lastLogin: true,
    registrationDate: true,
    orderCount: true,
    generatedAmount: true,
  })

  const observer = useRef()
  const lastUserElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && userReportList?.data?.length < userReportList?.totalUsers) {
          fetchMoreOrders()
        }
      })
      if (node) observer.current.observe(node)
    },
    [userReportList?.data?.length, userReportList?.totalUsers],
  )

  const fetchMoreOrders = () => {
    fetchOrderList({ page: pageData.page + 1, dateRange, customDateRange })
    setPageData((prev) => ({ ...prev, page: prev.page + 1 }))
  }

  useEffect(() => {
    setPageData({ page: 1 })
  }, [setPageData])

  const getStatusColor = (status) => {
    switch (status?.toUpperCase()) {
      case "ACTIVE":
        return "bg-green-500 text-white"
      case "NOT ACTIVE":
        return "bg-red-500 text-white"
      default:
        return "bg-gray-500 text-white"
    }
  }

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }))
  }

  const handleDateRangeChange = (range) => {
    setSelectedDateFilter(range)
    if (range !== "custom") {
      fetchOrderList({ dateFilter: range, page: 1, firstTime: true })
      setPageData({ page: 1 })
      setShowDatePicker(false)
    } else {
      setShowDatePicker(true)
    }
  }

  const handleCustomDateRangeChange = (start, end) => {
    setCustomDateRange({ start, end })
    fetchOrderList({ dateFilter: "custom", customDateRange: { start, end }, page: 1, firstTime: true })
    setPageData({ page: 1 })
    setShowDatePicker(false)
  }

  const metrics = (data) => {
    return [
      {
        id: "total",
        title: "Total Orders",
        value: data?.totalOrders,
        icon: Package,
        color: "bg-blue-500",
      },
      {
        id: "completed",
        title: "Completed Orders",
        value: data?.completedOrder,
        icon: CheckCircle,
        color: "bg-green-500",
      },
      {
        id: "missed",
        title: "Missed Deadline Orders",
        value: data?.missedDeadlineCount,
        icon: AlertCircle,
        color: "bg-red-500",
      },
      {
        id: "progress",
        title: "Orders In Progress",
        value: data?.inProgressOrder,
        icon: Clock,
        color: "bg-yellow-500",
      },
    ]
  }

  return (
    selectedMetric && (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50 p-4">
        <div className="bg-[#001B3D] rounded-lg w-full max-w-7xl mx-auto relative overflow-hidden max-h-[95vh] flex flex-col shadow-2xl">
          {/* Header Section */}
          <div className="p-4 flex-shrink-0 border-b border-blue-800">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="p-1.5 bg-blue-600 rounded-full">
                  <User className="h-4 w-4 text-white" />
                </div>
                <h2 className="text-lg font-bold text-white">
                  {metrics(reports?.current)?.find((m) => m.id === selectedMetric)?.title} Details
                </h2>
              </div>
              <button
                onClick={() => setSelectedMetric(null)}
                className="text-gray-400 hover:text-white transition-colors p-1 rounded-full hover:bg-blue-800"
              >
                <X className="h-4 w-4" />
              </button>
            </div>

            <div className="flex flex-wrap items-center gap-2 mb-4">
              <div className="relative flex-grow max-w-xs">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value)
                    fetchOrderList({ tempSearch: e.target.value, firstTime: true, page: 1, dateRange, customDateRange })
                  }}
                  className="bg-[#0A2547] text-white text-xs py-1.5 px-2 pr-8 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 w-full"
                />
                <Search className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 h-3 w-3" />
              </div>
              <div className="relative flex-grow max-w-xs">
                <select
                  value={dateRange}
                  onChange={(e) => handleDateRangeChange(e.target.value)}
                  className="bg-[#0A2547] text-white text-xs py-1.5 px-2 pr-8 rounded focus:outline-none focus:ring-1 focus:ring-blue-500 appearance-none w-full"
                >
                  <option value="all">All Time</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="previous7days">Last 7 Days</option>
                </select>
                <ChevronDown className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 h-3 w-3 pointer-events-none" />
              </div>
              <div className="flex items-center text-gray-300 text-xs">
                <Users className="h-3 w-3 mr-1" />
                <span>Total: {userReportList?.totalUsers || 0}</span>
              </div>
            </div>

            {showDatePicker && (
              <div className="mb-4">
                <DateRangePicker onRangeSelected={handleCustomDateRangeChange} />
              </div>
            )}

            <div className="flex flex-wrap gap-1">
              {Object.entries(visibleColumns).map(([column, isVisible]) => (
                <button
                  key={column}
                  onClick={() => toggleColumn(column)}
                  className={`px-2 py-0.5 rounded-full text-xs font-medium transition-colors ${
                    isVisible ? "bg-blue-600 text-white" : "bg-[#0A2547] text-gray-300"
                  }`}
                >
                  {column.charAt(0).toUpperCase() + column.slice(1)}
                </button>
              ))}
            </div>
          </div>

          {/* User Report Section */}
          <div className="overflow-y-auto flex-grow p-4">
            <table className="w-full text-left text-xs text-gray-300">
              <thead>
                <tr className="bg-[#0A2547] text-gray-200">
                  <th className="py-2 px-3 font-semibold">#</th>
                  {visibleColumns.name && <th className="py-2 px-3 font-semibold">Name</th>}
                  {visibleColumns.email && <th className="py-2 px-3 font-semibold">Email</th>}
                  {visibleColumns.userType && <th className="py-2 px-3 font-semibold">User Type</th>}
                  {visibleColumns.status && <th className="py-2 px-3 font-semibold">Status</th>}
                  {visibleColumns.lastLogin && <th className="py-2 px-3 font-semibold">Last Login</th>}
                  {visibleColumns.registrationDate && <th className="py-2 px-3 font-semibold">Reg. Date</th>}
                  {visibleColumns.orderCount && <th className="py-2 px-3 font-semibold">Orders</th>}
                  {visibleColumns.generatedAmount && <th className="py-2 px-3 font-semibold">Amount</th>}
                  <th className="py-2 px-3 text-center font-semibold">Actions</th>
                </tr>
              </thead>
              <tbody>
                {userReportList?.data?.map((user, index) => (
                  <tr
                    key={user.id}
                    ref={index === userReportList?.data?.length - 1 ? lastUserElementRef : null}
                    className="hover:bg-[#0F2F5B] transition-colors"
                  >
                    <td className="py-2 px-3">{index + 1}</td>
                    {visibleColumns.name && <td className="py-2 px-3">{user.name}</td>}
                    {visibleColumns.email && <td className="py-2 px-3">{user.email}</td>}
                    {visibleColumns.userType && <td className="py-2 px-3">{user?.userType}</td>}
                    {visibleColumns.status && (
                      <td className="py-2 px-3">
                        <span className={`px-2 py-0.5 rounded-full text-xs font-medium ${getStatusColor(user.status)}`}>
                          {user.status}
                        </span>
                      </td>
                    )}
                    {visibleColumns.lastLogin && (
                      <td className="py-2 px-3">{returnDateWithTime(user.lastLogin) || "N/A"}</td>
                    )}
                    {visibleColumns.registrationDate && (
                      <td className="py-2 px-3">{returnDateWithTime(user.registrationDate) || "N/A"}</td>
                    )}
                    {visibleColumns.orderCount && <td className="py-2 px-3">{user.totalOrders || 0}</td>}
                    {visibleColumns.generatedAmount && (
                      <td className="py-2 px-3">${user.generatedAmount?.toFixed(2) || "0.00"}</td>
                    )}
                    <td className="py-2 px-3 text-center">
                      <button
                        onClick={() => {
                          setSelectedUser(user?.userId)
                        }}
                        className="text-blue-400 hover:text-blue-300 flex items-center justify-center gap-1 transition-colors duration-200"
                      >
                        <Eye className="h-3 w-3" /> View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {selectedUser && (
          <ClientReport
            isOpenModal={selectedUser}
            metrics={metrics}
            selectedUser={selectedUser}
            onClose={() => setSelectedUser(null)}
            selectedMetric={selectedMetric}
            setSelectedUser={setSelectedUser}
            setSelectedMetric={setSelectedMetric}
            setSearch={setSearch}
            search={search}
          />
        )}
      </div>
    )
  )
}

export default UserReportList

