// import node module libraries
import { Menu } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { Nav, Navbar, Form } from "react-bootstrap";

// import sub components
import QuickMenu from "../QuickMenu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getLeadDetailAction,
  startChatAction,
  startOpenChatAction,
} from "../../../services/action/common";

const NavbarTop = (props) => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const visitorId = queryParams?.get("visitorId");
  const leadId = queryParams.get("leadId");
  const dynamicStyle = {
	bg: (() => {
	  switch (true) {
		case location.pathname?.includes("/report/user"):
		case location.pathname?.includes("/report/order"):
      return "!bg-[#001b3d]"
      	case location.pathname?.includes("/clients"):
      return "!bg-[#001b3d]"
    case location.pathname?.includes("/live-users"):
      return "!bg-[#00234a]"
		case location.pathname?.includes("/dashboard"):
      return "!bg-[#001B3D]";
		case location.pathname?.includes("/share"):
		  return "!bg-[#001B3D]";
		case location.pathname?.includes("/report/revenue"):
		  return "!bg-[#001B3D]";
		default:
		  return "";
	  }
	})(),
	text: (() => {
	  switch (true) {
		case location.pathname?.includes("/report/user"):
		case location.pathname?.includes("/report/order"):
		case location.pathname?.includes("/report/revenue"):
		case location.pathname?.includes("/dashboard"):
      case location.pathname?.includes("/clients"):
		case location.pathname?.includes("/share"):
      case location.pathname?.includes("/live-users"):
		  return "text-white";
      
		default:
		  return "";
	  }
	})(),
  };
  
  useEffect(() => {
    // if (user) {
    if (visitorId) {
      dispatch(startOpenChatAction({ _id: visitorId }));
    }
    if (leadId) {
      if (user?.userType !== "CLIENT") {
        dispatch(
          getLeadDetailAction({ userType: user?.userType, data: { leadId } })
        );
      }
      dispatch(
        startChatAction({
          leadDetail: {
            _id: leadId,
          },
          userId: user?._id,
        })
      );
    }
    // }
  }, [dispatch, leadId, visitorId]);
  return (
    <Navbar expanded="lg" className={`navbar-classic navbar navbar-expand-lg ${dynamicStyle.bg}`}>
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <Link
            href="#"
            id="nav-toggle"
            className="nav-icon me-2 icon-xs"
            onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}
          >
            <Menu size="18px" />
          </Link>
          <div className="ms-lg-3 d-none d-md-none d-lg-block">
            {/* Search Form */}
            {props?.onSearch && (
              <Form className="d-flex align-items-center">
                <Form.Control
                  type="search"
                  onChange={(e) =>
                    props?.onSearch && props?.onSearch(e.target.value)
                  }
                  className="dashboard-search"
                  placeholder="Search"
                />
              </Form>
            )}
          </div>
        </div>
        {/* Quick Menu */}
        <Nav className="navbar-right-wrap ms-2 d-flex nav-top-wrap">
        <h5 className={`title ${dynamicStyle.text}`}>
            {user?.name || user?.user?.name} ({user?.userType || user?.user?.userType})
          </h5>
          <QuickMenu />
        </Nav>
      </div>
    </Navbar>
  );
};

export default NavbarTop;
