import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PushNotificationApp from "../PushNotificationApp";
import PushNotificationListener from "../components/web-push/PushNotificationListener";
import CheckChatRouter from "../components/web-push/CheckChatRouter";
import DashboardSkeleton from "../components/skeleton/DashBoardSkeleton";
import DeleteAccountButton from "../components/DeleteAccountButton";

import Login from "../view/auth/Login";
import Logout from "../view/auth/Logout";
import Error from "../view/Error";
import OperatorList from "../view/manager/OperatorList";
import ClientList from "../view/manager/ClientList";
import ExpertList from "../view/manager/ExpertList";
import LeadList from "../view/manager/LeadList";
import Orderlist from "../view/manager/Orderlist";
import Share from "../../src/view/live-leads/component/ShareApplication";
import OperatorDashboard from "../view/operator/Operator";
import LeadListOperator from "../view/operator/LeadList";
import OrderListOperator from "../view/operator/OrderList";
import ClientDashboard from "../view/client-new-design/ClientDashboard";
import LeadListClient from "../view/client/LeadList";
import OrderListClient from "../view/client/OrderList";
import OrderListExpert from "../view/experts/OrderListExpert";
import ExpertDashboard from "../view/experts/ExpertDashboard";
import ResetPassword from "../view/auth/ResetPassword";
import UpdatePassword from "../view/auth/UpdatePassword";
import AccountDeleted from "../components/DeleteAccount";
import OperatorListSA from "../view/super-admin/OperatorListSA";
import ClientListSA from "../view/super-admin/ClientListSA";
import ExpertListSA from "../view/super-admin/ExpertListSA";
import LeadListSA from "../view/super-admin/LeadListSA";
import OrderlistSA from "../view/super-admin/OrderlistSA";
import ManagerList from "../view/super-admin/ManagerList";
import AdminList from "../view/super-admin/AdminList";
import OpenLeadExpert from "../view/experts/OpenLeadExpert";
import MarketingSA from "../view/super-admin/MarketingSA";
import OrderReport from "../components/reports/OrderReport";
import UsersReport from "../components/reports/UsersReport";
import Queries from "../components/queries/Queries";
import RevenueReport from "../components/reports/RevenueReport";
import AndroidApp from "../view/android/AndroidApp";
import LoginWithGoogle from "../view/auth/social-auth/LoginWithGoogle";
import ClientDashboardDesign from "../view/android/ClientDashboardDesign";
import CouponCodeManagement from "../view/coupon-code/CouponCodeManagement";
import LiveLeads from "../view/live-leads/LiveLeads";
import LiveUserNewDashboard from "../components/Live-chat-users/LiveUserNewDashboard";
import Chat1 from "../components/Chat1";
import CheckUserLoggedOrNot from "./CheckUserLoggedOrNot";
import NewOrders from "../view/client-new-design/orders/NewOrders";
import ExpertListOpen from "../view/client-new-design/experts/ExpertList";
import ClientHeader from "../components/layout-new/HeaderNew";
import ClientProfile from "../view/client-new-design/orders/ClientProfile";
import CheckPaymentStatus from "../view/client-new-design/payment/CheckPaymentStatus";
import ManageServices from "../components/manage-services/ManageServices";
import AppShare from "../components/app-share/AppShare";

const ChatWithUsLazy = lazy(() => import("../view/chat/ChatWithUs"));
// Fallback Loading Component
// const Loading = () => <div>Loading...</div>;

const ChatWithUs = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 3000); // 1 second delay
    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  if (!show) {
    return null; // Render nothing until delay is over
  }

  return (
    <Suspense fallback={<DashboardSkeleton />}>
      <ChatWithUsLazy />
    </Suspense>
  );
};

const CustomRouter = () => {
  return (
    <Router>
      <PushNotificationListener />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/auth-login" element={<CheckUserLoggedOrNot />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/update-password/:resetId/:userId"
          element={<UpdatePassword />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/auth/google" element={<LoginWithGoogle />} />

        {/* Manager Routes */}
        <Route path="/manager/dashboard" element={<OrderReport />} />
        <Route path="/manager/operators" element={<OperatorList />} />
        <Route path="/manager/clients" element={<ClientList />} />
        <Route path="/manager/experts" element={<ExpertList />} />
        <Route path="/manager/leads" element={<LeadList />} />
        <Route path="/manager/orders" element={<Orderlist />} />
        <Route path="/manager/live-users" element={<LiveUserNewDashboard />} />
        <Route path="/manager/marketings" element={<MarketingSA />} />
        <Route path="/manager/report/order" element={<OrderReport />} />
        <Route path="/manager/report/user" element={<UsersReport />} />
        <Route path="/manager/query" element={<Queries />} />
        <Route path="/manager/report/revenue" element={<RevenueReport />} />
        <Route path="/manager/androidpanel" element={<AndroidApp />} />
        <Route
          path="/manager/client/dashboard"
          element={<ClientDashboardDesign />}
        />
        <Route path="/manager/coupon-code" element={<CouponCodeManagement />} />
        <Route path="/manager/live/leads" element={<LiveLeads />} />
        <Route path="/manager/Share" element={<Share />} />
        <Route path="/manager/manage-services" element={<ManageServices />} />

        {/* Operator Routes */}
        <Route path="/operator/dashboard" element={<OperatorDashboard />} />
        <Route path="/operator/leads" element={<LeadListOperator />} />
        <Route path="/operator/orders" element={<OrderListOperator />} />
        <Route path="/operator/live-users" element={<LiveUserNewDashboard />} />
        <Route path="/operator/report/order" element={<OrderReport />} />
        <Route path="/operator/report/user" element={<UsersReport />} />
        <Route path="/operator/query" element={<Queries />} />
        <Route path="/operator/live/leads" element={<LiveLeads />} />
        <Route path="/operator/Share" element={<Share />} />

        {/* Client Routes */}
        <Route path="/client/dashboard" element={<ClientDashboard />} />
        <Route path="/client/new-order" element={<NewOrders />} />
        {/* <Route
          path="/client/experts"
          element={
            <Fragment>
              <ClientHeader />
              <ExpertListOpen />
            </Fragment>
          }
        /> */}
        <Route
          path="/client/profile"
          element={
            <Fragment>
              <ClientHeader />
              <ClientProfile />
            </Fragment>
          }
        />
        <Route
          path="/client/payment/checking"
          element={
            <Fragment>
              <ClientHeader />
              <CheckPaymentStatus />
            </Fragment>
          }
        />
        {/* <Route path="/client/leads" element={<LeadListClient />} />
        <Route path="/client/orders" element={<OrderListClient />} /> */}
        <Route
          path="/client/Share"
          element={
            <Fragment>
              <ClientHeader />
              <AppShare />
              {/* <Share/> */}
            </Fragment>
          }
        />

        <Route
          path="/client/delete-account"
          element={<DeleteAccountButton />}
        />
        <Route
          path="/client/account-delete-success"
          element={<AccountDeleted />}
        />

        {/* Expert Routes */}
        <Route path="/expert/dashboard" element={<ExpertDashboard />} />
        <Route path="/expert/leads" element={<OrderListExpert />} />
        <Route path="/expert/open-lead" element={<OpenLeadExpert />} />
        <Route path="/expert/Share" element={<Share />} />

        {/* Super Admin Routes */}
        <Route path="/super-admin/dashboard" element={<OrderReport />} />
        <Route path="/super-admin/operators" element={<OperatorListSA />} />
        <Route path="/super-admin/clients" element={<ClientListSA />} />
        <Route path="/super-admin/experts" element={<ExpertListSA />} />
        <Route path="/super-admin/managers" element={<ManagerList />} />
        <Route path="/super-admin/admins" element={<AdminList />} />
        <Route path="/super-admin/leads" element={<LeadListSA />} />
        <Route path="/super-admin/orders" element={<OrderlistSA />} />
        <Route
          path="/super-admin/live-users"
          element={<LiveUserNewDashboard />}
        />
        <Route path="/super-admin/marketings" element={<MarketingSA />} />
        <Route path="/super-admin/report/order" element={<OrderReport />} />
        <Route path="/super-admin/report/user" element={<UsersReport />} />
        <Route path="/super-admin/query" element={<Queries />} />
        <Route path="/super-admin/report/revenue" element={<RevenueReport />} />
        <Route path="/super-admin/androidpanel" element={<AndroidApp />} />
        <Route
          path="/super-admin/client/dashboard"
          element={<ClientDashboardDesign />}
        />
        <Route
          path="/super-admin/manage-services"
          element={<ManageServices />}
        />
        <Route
          path="/super-admin/coupon-code"
          element={<CouponCodeManagement />}
        />
        <Route path="/super-admin/live/leads" element={<LiveLeads />} />
        <Route path="/super-admin/Share" element={<Share />} />
        <Route path="/super-admin/Chat1" element={<Chat1 />} />

        {/* Admin Routes */}
        <Route path="/admin/dashboard" element={<OrderReport />} />
        <Route path="/admin/operators" element={<OperatorListSA />} />
        <Route path="/admin/clients" element={<ClientListSA />} />
        <Route path="/admin/experts" element={<ExpertListSA />} />
        <Route path="/admin/managers" element={<ManagerList />} />
        <Route path="/admin/leads" element={<LeadListSA />} />
        <Route path="/admin/orders" element={<OrderlistSA />} />
        {/* <Route path="/admin/live-users" element={<LiveUser />} /> */}
        <Route path="/admin/marketings" element={<MarketingSA />} />
        <Route path="/admin/report/order" element={<OrderReport />} />
        <Route path="/admin/report/user" element={<UsersReport />} />
        <Route path="/admin/query" element={<Queries />} />
        <Route path="/admin/report/revenue" element={<RevenueReport />} />
        <Route path="/admin/androidpanel" element={<AndroidApp />} />
        <Route
          path="/admin/client/dashboard"
          element={<ClientDashboardDesign />}
        />

        <Route path="/admin/coupon-code" element={<CouponCodeManagement />} />
        <Route path="/admin/Share" element={<Share />} />
        <Route path="/admin/live-users" element={<LiveUserNewDashboard />} />

        <Route path="/admin/manage-services" element={<ManageServices />} />

        {/* <Route path="/chat" element={<ChatWithUs />} /> */}
        <Route path="/chat" element={<ChatWithUs />} />

        <Route path="/check-chat" element={<CheckChatRouter />} />
        <Route path="/marketings/live/leads" element={<LiveLeads />} />
        <Route path="/marketings/androidpanel" element={<AndroidApp />} />
        <Route path="/admin/live/leads" element={<LiveLeads />} />
        <Route path="/testing" element={<PushNotificationApp />} />

        {/* Catch-All Route */}
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
};

export default CustomRouter;
