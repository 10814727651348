"use client"

import { motion } from "framer-motion"
import { Apple, SmartphoneIcon, Lightbulb, Wrench, CheckCircle, ArrowRight } from "lucide-react"
import Link from "next/link"

const IconAnimation = ({ icon: Icon, text, delay }) => (
  <motion.div
    className="flex flex-col items-center"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay }}
  >
    <motion.div
      className="text-blue-300 mb-2"
      initial={{ scale: 0, rotate: -180 }}
      animate={{ scale: 1, rotate: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: delay + 0.2,
      }}
    >
      <Icon size={32} />
    </motion.div>
    <motion.p
      className="text-blue-100 text-sm text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: delay + 0.4 }}
    >
      {text}
    </motion.p>
  </motion.div>
)

const DownloadButton = ({ icon: Icon, text, link }) => (
  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
    <Link
      href={link}
      className="flex items-center justify-between bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-2 rounded-full font-medium transition-all hover:from-blue-600 hover:to-blue-700 hover:shadow-lg"
    >
      <div className="flex items-center">
        <Icon className="w-5 h-5 mr-2" />
        <span>{text}</span>
      </div>
      <ArrowRight className="w-4 h-4" />
    </Link>
  </motion.div>
)

export default function AppShare() {
  return (
    <div
      className="flex items-center justify-center p-4 relative "
      style={{
        height:"89vh",
        background: "linear-gradient(135deg, rgba(33, 150, 243, 0.8), rgba(3, 169, 244, 0.8))",
      }}
    >
      <div className="absolute inset-0 bg-[url('https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-eKrnXai9VrMD9S2sVcKRA4zrZyjG4U.png')] bg-cover bg-center opacity-30"></div>

      <motion.div
        className="bg-gradient-to-br from-black/40 to-black/60 backdrop-blur-md rounded-2xl p-8 max-w-md w-full relative overflow-hidden shadow-2xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <motion.div
          className="absolute top-0 left-0 right-0 h-1 bg-blue-400"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
        />

        <motion.h1
          className="text-3xl font-bold text-white mb-2 text-center"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          Smart Solutions
        </motion.h1>

        <motion.p
          className="text-blue-100 text-center mb-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          Empowering you with innovative tools
        </motion.p>

        <div className="flex justify-around mb-8">
          <IconAnimation icon={Lightbulb} text="Innovative" delay={0.5} />
          <IconAnimation icon={Wrench} text="Efficient" delay={0.7} />
          <IconAnimation icon={CheckCircle} text="Reliable" delay={0.9} />
        </div>

        <motion.div
          className="space-y-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.2 }}
        >
          <DownloadButton icon={Apple} text="Download for iOS" link="#ios-download" />
          <DownloadButton icon={SmartphoneIcon} text="Download for Android" link="#android-download" />
        </motion.div>

        <motion.div
          className="mt-6 text-center text-blue-100 text-sm"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1.4 }}
        >
          Join thousands of satisfied users today!
        </motion.div>
      </motion.div>
    </div>
  )
}

