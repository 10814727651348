  // BenefitsSection Component
  const BenefitsSection = () => {
    const benefits = [
      "Money-Refund Policy if the work is found unsatisfactory",
      "On-time delivery of the assignment",
      "Free Plagiarism Report to prove the authenticity of content",
      "24*7 Customer Assistance to resolve queries",
      "100% Ownership to the clients",
      "Privacy of confidential information",
      "Free Unlimited Revisions if we do not match your expectations",
      "Exclusive offers and discounts",
    ]

    return (
      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-4">With Each Order, You Get:</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {benefits.map((benefit) => (
            <div key={benefit} className="flex items-start gap-2">
              <svg
                className="w-5 h-5 text-green-500 mt-0.5 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <span className="text-sm text-gray-600">{benefit}</span>
            </div>
          ))}
        </div>
      </div>
    )
  }

  export default BenefitsSection;