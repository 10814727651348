import { useState, useEffect } from "react";
import { Menu, X, ChevronDown, Search, Phone, Mail, Bell } from 'lucide-react';
import { Link, useNavigate } from "react-router-dom";
import { getUserAction } from "../../services/action/authentication";
import { useDispatch, useSelector } from "react-redux";
import LoaderMultiple from "../loader/LoaderMultiple";
import { getLeadDetailAction, getNotificationListAction, startChatAction, updateNotificationStatusAction } from "../../services/action/common";

const ClientHeader = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const {isLoadingOrderCreate,isLoadingService,isLoadingSubject,isImageLoading} = useSelector((state)=>state.commonReducer);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isBalanceDropdownOpen, setIsBalanceDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const {notificationList} = useSelector((state)=>state.commonReducer);
  const navigate = useNavigate();


  const cbNotification=()=>{
    dispatch(getNotificationListAction({userType:"client"}))
  }
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleOpenChat = (
      leadId,
      subject,
      senderName,
      messageId,
      notificationId,
      isNew,
      orderId
    ) => {
      if (isNew) {
        dispatch(
          updateNotificationStatusAction({
            userType:"client",
            data: { leadId: leadId },
            cb: cbNotification,
          })
        );
      }
      dispatch(
        startChatAction({
          leadDetail: { senderName, orderId, messageId, _id: leadId, subject },
          userId: user?._id,
        })
      );
    };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserAction({ errorCb: () => navigate("/") }));
  }, [dispatch, navigate]);

  useEffect(()=>{
    dispatch(getNotificationListAction({userType:"client"}))
  },[dispatch])

  const NotificationItem = ({ notification }) => (
    <div className="px-4 py-3 border-b last:border-b-0 hover:bg-gray-50 cursor-pointer" 
    
    
    onClick={()=>{
      handleOpenChat(
        notification?.leadId,
        notification?.subject,
        notification?.senderName,
        notification?.messageId,
        notification?._id,
        !notification?.isSeen,
        notification?.orderId
      );
    }}
    >
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-900">{notification.orderId}</p>
          <p className="text-xs text-gray-500 truncate">{notification.subject}</p>
        </div>
        <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
          {notification.messageNewMessageCount}
        </span>
      </div>
    </div>
  );

  return (
    <header
      className={`sticky top-0 left-0 right-0 z-50 bg-white transition-all duration-300 ${
        isScrolled ? "shadow-lg" : ""
      }`}
    >
      <LoaderMultiple
        type={isLoadingOrderCreate || isLoadingService || isLoadingSubject || isImageLoading}
        message={"Processing your request..."}
      />
      {/* Top Bar */}
      <div className="bg-blue-600 text-white py-2">
        <div className="max-w-6xl mx-auto px-4 flex flex-col sm:flex-row justify-between items-center">
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <a
              href="tel:+918510001300"
              className="flex items-center hover:text-blue-200 transition-colors text-white"
            >
              <Phone className="h-4 w-4 mr-2" />
              <span className="text-sm">+61 489 921 023</span>
            </a>
            <a
              href="mailto:help@clickinpedia.com"
              className="flex items-center hover:text-blue-200 transition-colors text-white"
            >
              <Mail className="h-4 w-4 mr-2" />
              <span className="text-sm">help@clickinpedia.com</span>
            </a>
          </div>
          <div className="flex items-center">
            <span className="bg-yellow-400 text-blue-900 px-3 py-1 rounded-l-full font-medium text-sm">
              OFFER!
            </span>
            <span className="bg-blue-700 px-3 py-1 rounded-r-full text-sm">
              Save 45% on Assignment Crafted by Human Writers
            </span>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <span className="text-2xl font-bold text-blue-600">
              Clickinpedia
            </span>
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden lg:flex items-center space-x-6">
            <Link
              to="/client/dashboard"
              className="text-gray-700 hover:text-blue-500 transition-colors"
            >
              My Orders
            </Link>
            <Link
              to="/client/new-order"
              className="text-gray-700 hover:text-blue-500 transition-colors"
            >
              Order Now
            </Link>
            <Link
              to="/client/share"
              className="text-gray-700 hover:text-blue-500 transition-colors"
            >
              iOS/Android
            </Link>
          </nav>

          {/* Search, Balance, Notifications, and User */}
          <div className="hidden lg:flex items-center space-x-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-48 pl-10 pr-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            <div className="relative">
              <button
                className="bg-blue-50 px-3 py-1.5 rounded-lg flex items-center space-x-1"
                onClick={() => setIsBalanceDropdownOpen(!isBalanceDropdownOpen)}
              >
                <span className="text-sm font-medium text-blue-600">
                  Wallet : $ {user?.wallet?.amount}{" "}
                </span>
              </button>
            </div>
            <div className="relative">
              <button
                className="text-gray-600 hover:text-blue-500 transition-colors"
                onClick={() =>
                  setIsNotificationDropdownOpen(!isNotificationDropdownOpen)
                }
              >
                <Bell className="h-6 w-6" />
                <span className="absolute -top-2 -right-2 h-5 w-5 bg-red-500 text-white text-xs rounded-full flex items-center justify-center">
                 {notificationList?.newCount}
                </span>
              </button>
              {isNotificationDropdownOpen && (
                <div className="absolute right-0 mt-2 w-80 bg-white rounded-md shadow-lg overflow-hidden z-10">
                  <div className="py-2 px-4 bg-gray-100 border-b">
                    <h3 className="text-sm font-semibold text-gray-800">Notifications</h3>
                  </div>
                  <div className="max-h-64 overflow-y-auto">
                    {notificationList?.data?.map((notification) => (
                      <NotificationItem key={notification?.leadId} notification={notification} />
                    ))}
                  </div>
                  {notificationList?.data?.length === 0 && (
                    <div className="py-4 px-4 text-sm text-gray-500 text-center">
                      No new notifications
                    </div>
                  )}
                  {/* <div className="py-2 px-4 bg-gray-100 border-t">
                    <Link to="/client/notifications" className="text-sm text-blue-600 hover:text-blue-800">
                      View all notifications
                    </Link>
                  </div> */}
                </div>
              )}
            </div>
            <button
              onClick={() => navigate("/client/profile")}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            >
              {user?.name}
            </button>
          </div>

          {/* Mobile menu button */}
          <button
            className="lg:hidden p-2 rounded-md text-gray-600 hover:text-blue-500 transition-colors"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden bg-white shadow-lg">
          <div className="max-w-6xl mx-auto px-4 py-4 space-y-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search..."
                className="w-full pl-10 pr-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
            <nav className="space-y-2">
              <Link
                to="/client/dashboard"
                className="block py-2 text-gray-700 hover:text-blue-500 transition-colors"
              >
                My Order
              </Link>
              <Link
                to="/client/new-order"
                className="block py-2 text-gray-700 hover:text-blue-500 transition-colors"
              >
                Order Now
              </Link>
              <Link
                to="/client/share"
                className="block py-2 text-gray-700 hover:text-blue-500 transition-colors"
              >
                iOS/Android
              </Link>
              <Link
                to="/client/notifications"
                className="block py-2 text-gray-700 hover:text-blue-500 transition-colors"
              >
                Notifications
                {notificationList?.newCount > 0 && (
                  <span className="ml-2 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
                    {notificationList.newCount}
                  </span>
                )}
              </Link>
            </nav>
            <button
              onClick={() => navigate("/client/profile")}
              className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            >
              {user?.name}
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default ClientHeader;
