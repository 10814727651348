import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  Home,
  FileText,
  MessageCircle,
  Share2,
  CheckCircle,
  Clock,
  CircleDot,
  Search,
  Filter,
  ChevronRight,
  MoreVertical,
  BookOpen,
  GraduationCap,
  PenTool,
  Edit,
  FileSearch,
} from "lucide-react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardAction,
  requestForAssignLeadAction,
  startChatAction,
} from "../../services/action/common";
import {
  returnDateWithTime,
  truncateString,
} from "../../components/utils/functions/functions";
import CustomModal from "../../components/utils/modals/CustomModal";
import RequestLeadId from "../../components/manager/leads/components/expert/RequestLeadId";

const ExpertDashboard = () => {
  const { user } = useSelector((state) => state.authenticationReducer);
  const { dashboardData } = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [requestLeadId, setRequestLeadId] = useState("");
  const [leadDetails, setLeadDetails] = useState({
    modal: false,
    edit: false,
    preview: false,
    data: "",
  });
  useEffect(() => {
    dispatch(getDashboardAction({ userType: "expert" }));
  }, []);
  const stats = [
    {
      icon: <FileText className="w-6 h-6 text-blue-400" />,
      title: "My Orders",
      value: dashboardData?.totalAssignedOrder,
    },
    {
      icon: <CheckCircle className="w-6 h-6 text-green-400" />,
      title: "Completed",
      value: dashboardData?.completedOrders,
    },
    {
      icon: <Clock className="w-6 h-6 text-yellow-400" />,
      title: "Missed Daedline",
      value: dashboardData?.missedOrder,
    },
    {
      icon: <CircleDot className="w-6 h-6 text-purple-400" />,
      title: "Applied",
      value: dashboardData?.totalApplied,
    },
  ];

  const services = [
    {
      title: "Assignment Help",
      icon: <FileText className="w-6 h-6" />,
      count: 15,
    },
    {
      title: "Dissertation Help",
      icon: <BookOpen className="w-6 h-6" />,
      count: 8,
    },
    {
      title: "Coursework Help",
      icon: <GraduationCap className="w-6 h-6" />,
      count: 12,
    },
    { title: "Thesis Help", icon: <PenTool className="w-6 h-6" />, count: 6 },
    { title: "Essay Help", icon: <Edit className="w-6 h-6" />, count: 20 },
    {
      title: "Research Proposal",
      icon: <FileSearch className="w-6 h-6" />,
      count: 5,
    },
  ];

  const bannerSlides = [
    {
      title: "Welcome to Expert Dashboard",
      description: "Manage your orders and boost your productivity",
    },
    {
      title: "New Feature: Real-time Chat",
      description: "Communicate with clients instantly",
    },
    {
      title: "Upcoming Webinar",
      description: "Join us for tips on academic writing",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % bannerSlides.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + bannerSlides.length) % bannerSlides.length
    );
  };

  const startChat = (item) => {
    dispatch(startChatAction({ leadDetail: item, userId: user?._id }));
  };

  const afterSave = () => {
    setLeadDetails({});
    dispatch(getDashboardAction({userType:"expert"}))
  };
  const removeFromRequestExpert = (lead) => {
    dispatch(
      requestForAssignLeadAction({
        userType: user?.userType?.toLowerCase(),
        data: {
          leadId: lead?._id,
          expertId: user?._id,
          comment: "Remove",
        },
        cb: () => {
          setRequestLeadId("");
          afterSave();
        },
      })
    );
  };
  return (
    <DashboardLayout>
      <CustomModal
        open={requestLeadId}
        onClose={() => setRequestLeadId("")}
        headerShown={false}
        content={() => (
          <RequestLeadId
            lead={requestLeadId}
            onClose={() => {
              setRequestLeadId("");
              afterSave();
            }}
          />
        )}
      />
      <div className="min-h-screen bg-[#001B3D] text-white">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="relative mb-6 rounded-xl overflow-hidden shadow-lg bg-[#002952]">
            <div className="relative h-[150px]">
              {bannerSlides.map((slide, index) => (
                <motion.div
                  key={index}
                  className="absolute inset-0 flex items-center justify-center p-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: index === currentSlide ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="text-center">
                    <h2 className="text-xl md:text-2xl font-bold mb-2 text-white">
                      {slide.title}
                    </h2>
                    <p className="text-sm md:text-base text-blue-100">
                      {slide.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
            <button
              onClick={prevSlide}
              className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-1 rounded-full"
            >
              <ChevronRight className="w-4 h-4 transform rotate-180" />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-1 rounded-full"
            >
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>

          <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-[#002952] rounded-lg p-4 shadow-md"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-[#003366] rounded-md">{stat.icon}</div>
                  <div>
                    <p className="text-lg font-bold">{stat.value}</p>
                    <p className="text-blue-100 text-xs">{stat.title}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>

          <div className="bg-[#002952] rounded-xl p-4 mb-4 shadow-lg">
            <div className="relative">
              <input
                type="text"
                placeholder="Search available orders..."
                className="w-full bg-[#001B3D] text-white text-sm rounded-lg pl-10 pr-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="w-5 h-5 text-blue-100 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <button className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1 hover:bg-[#003366] rounded transition-colors">
                <Filter className="w-5 h-5 text-blue-100" />
              </button>
            </div>
          </div>

          <div className="bg-[#002952] rounded-lg shadow-md overflow-hidden">
            <div className="p-4 border-b border-[#003366]">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-bold text-white">
                  Available Orders
                </h2>
                <span className="text-xs bg-blue-500 px-2 py-1 rounded-full">
                  {dashboardData?.totalOpenOrders} orders
                </span>
              </div>
            </div>

            <div className="md:hidden divide-y divide-[#003366]">
              {dashboardData?.orderList?.map((order, index) => (
                <motion.div
                  key={order.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="p-3"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div className="w-3/4">
                      <h3 className="font-semibold text-sm mb-1 truncate text-white">
                        {order.title}
                      </h3>
                      <div className="flex items-center gap-2 text-blue-100 text-xs">
                        <span className="truncate">{order.subject}</span>
                        <span>•</span>
                        <span>{order.wordCount} words</span>
                      </div>
                    </div>
                    {/* <span className="font-bold text-green-400 text-sm">{order.budget}</span> */}
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <span className="px-2 py-1 bg-[#003366] text-xs rounded-full">
                      {order.category}
                    </span>
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${
                        order.urgency === "HIGH"
                          ? "bg-red-500/20 text-red-400"
                          : order.urgency === "MEDIUM"
                          ? "bg-yellow-500/20 text-yellow-400"
                          : "bg-green-500/20 text-green-400"
                      }`}
                    >
                      {order.urgency || "HIGH"}
                    </span>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-1 text-blue-100 text-xs">
                      <Clock className="w-3 h-3" />
                      <span>{returnDateWithTime(order.createdAt)}</span>
                    </div>
                    <div className="flex gap-2">
                      <span
                        className={`px-2 py-1 rounded-full text-xs ${getStatusColor(
                          order.orderStatus
                        )}`}
                      >
                        {order.orderStatus}
                      </span>
                      <button className="p-1 hover:bg-[#003366] rounded-md transition-colors">
                        <MoreVertical className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            <div className="hidden md:block overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="bg-[#003366]">
                    <th className="text-left p-3 font-semibold text-sm">
                      Order ID
                    </th>
                    <th className="text-left p-3 font-semibold text-sm">
                      Title
                    </th>
                    <th className="text-left p-3 font-semibold text-sm">
                      Type
                    </th>
                    <th className="text-left p-3 font-semibold text-sm">
                      Words
                    </th>
                    <th className="text-left p-3 font-semibold text-sm">
                      Created At
                    </th>
                    <th className="text-left p-3 font-semibold text-sm">
                      Status
                    </th>
                    {/* <th className="text-left p-3 font-semibold text-sm">
                      Actions
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {dashboardData?.orderList?.map((order, index) => (
                    <motion.tr
                      key={order.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="border-b border-[#003366] hover:bg-[#003366]/50 text-white"
                    >
                      <td className="p-3 text-xs">
                        #{order?.orderId || order?.leadId}
                      </td>
                      <td className="p-3">
                        <div>
                          <p className="font-medium text-sm truncate text-white">
                            {truncateString(order?.subject, 20)}
                          </p>
                          <p className="text-xs text-blue-100 truncate">
                            {truncateString(order?.requirement, 20)}
                          </p>
                        </div>
                      </td>
                      <td className="p-3">
                        <span className="px-2 py-1 bg-[#003366] text-xs rounded-full">
                          {order.category}
                        </span>
                      </td>
                      <td className="p-3 text-xs">{order?.wordCount}</td>
                      <td className="p-3">
                        <div className="flex items-center gap-1 text-blue-100 text-xs">
                          <Clock className="w-3 h-3" />
                          <span>{returnDateWithTime(order.createdAt)}</span>
                        </div>
                      </td>
                      <td className="p-3">
                        <span
                          className={`px-2 py-1 rounded-full text-xs ${getStatusColor(
                            order.orderStatus
                          )}`}
                        >
                          {order.orderStatus}
                        </span>
                      </td>
                      {/* <td className="p-3">
                        <div className="flex items-center gap-2">
                          <button
                           onClick={() => order?.expertRequest?removeFromRequestExpert(order): setRequestLeadId(order)}
                           className="px-2 py-1 bg-blue-500 text-white rounded-md text-xs hover:bg-blue-600 transition-colors"
                          >
                            {order?.expertRequest?"Cancel Request":"Request For Assign"}
                          </button>
                          <button
                            onClick={() => startChat(order)}
                            className="px-2 py-1 bg-[#003366] text-white rounded-md text-xs hover:bg-[#004488] transition-colors"
                          >
                            Chat
                          </button>
                        </div>
                      </td> */}
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* <div className="mb-6 mt-3">
            <h2 className="text-xl font-bold mb-3 text-white">Our Services</h2>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3">
              {services.map((service, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  className="bg-[#002952] rounded-lg p-3 shadow-md cursor-pointer transition-all hover:bg-[#003366]"
                >
                  <div className="flex flex-col items-center text-center">
                    <div className="mb-2 p-2 bg-[#003366] rounded-full">
                      {service.icon}
                    </div>
                    <h3 className="font-semibold text-sm mb-1 truncate w-full text-white">
                      {service.title}
                    </h3>
                    <span className="text-xs px-2 py-1 bg-blue-500 rounded-full">
                      {service.count}
                    </span>
                  </div>
                </motion.div>
              ))}
            </div>
          </div> */}
        </div>

        <motion.div
          initial={{ y: 100 }}
          animate={{ y: 0 }}
          className="fixed bottom-0 left-0 right-0 bg-[#002952] border-t border-[#003366] md:hidden"
        >
          <div className="flex justify-around items-center p-2">
            {[
              {
                icon: <Home className="w-4 h-4" />,
                label: "Home",
                href: "/dashboard",
                isActive: true,
              },
              {
                icon: <FileText className="w-4 h-4" />,
                label: "Orders",
                href: "/orders",
              },
              {
                icon: <MessageCircle className="w-4 h-4" />,
                label: "Chat",
                href: "/chat",
                badge: "4",
              },
              {
                icon: <Share2 className="w-4 h-4" />,
                label: "Share",
                href: "#",
              },
            ].map((item, index) => (
              <Link
                key={item.label}
                to={item.href}
                className={`relative flex flex-col items-center ${
                  item.isActive ? "text-blue-500" : "text-blue-100"
                }`}
              >
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  {item.icon}
                  {item.badge && (
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-[8px] w-3 h-3 flex items-center justify-center rounded-full">
                      {item.badge}
                    </span>
                  )}
                </motion.div>
                <span className="text-[8px] mt-1">{item.label}</span>
              </Link>
            ))}
          </div>
        </motion.div>
      </div>
    </DashboardLayout>
  );
};

const getStatusColor = (status) => {
  switch (status) {
    case "OPEN":
      return "bg-green-500/20 text-green-400";
    case "ASSIGNED":
      return "bg-blue-500/20 text-blue-400";
    case "UNDERQC":
      return "bg-yellow-500/20 text-yellow-400";
    case "DELIVERED":
      return "bg-purple-500/20 text-purple-400";
    case "REOPEN":
      return "bg-red-500/20 text-red-400";
    case "ONHOLD":
      return "bg-orange-500/20 text-orange-400";
    case "REFUND":
      return "bg-pink-500/20 text-pink-400";
    case "MISSED_ORDER":
      return "bg-red-700/20 text-red-600";
    case "CLIENTDEADLINE":
      return "bg-indigo-500/20 text-indigo-400";
    case "DONE":
      return "bg-teal-500/20 text-teal-400";
    default:
      return "bg-gray-500/20 text-gray-400";
  }
};

export default ExpertDashboard;
