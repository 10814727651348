import { Fragment, useState } from "react";
import { CheckCircle2, X } from "lucide-react";
import { useSelector } from "react-redux";
import {
  pagesWordsList,
  timeDurations,
} from "../../../../components/utils/constant/ServiceSubject";
import PaymentConfirmation from "./payment/PaymentConfirmation";
import { validateForm } from "../../../../components/utils/functions/functions";

// CouponModal Component
const CouponModal = ({ isOpen, onClose, coupons, onApply }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-start justify-center z-[9999] p-4 overflow-y-auto">
      <div className="bg-white rounded-lg p-6 w-full max-w-md shadow-xl mt-20">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">
            Available Coupons
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <div className="space-y-4">
          {coupons.map((coupon) => (
            <div
              key={coupon.code}
              className="bg-[#F8FAFF] rounded-lg p-4 border border-[#E5EDFF]"
            >
              <div className="flex justify-between items-start gap-4">
                <div className="space-y-1">
                  <div className="text-[#2563EB] font-semibold">
                    {coupon.code === "SUMMER2025"
                      ? "SEASONAL OFFER"
                      : "NEWUSER"}
                  </div>
                  <div className="text-gray-600 text-sm">
                    {coupon.description}
                  </div>
                </div>
                <div className="text-[#2563EB] text-2xl font-bold">
                  {coupon.discount}%
                  <span className="block text-center text-lg">OFF</span>
                </div>
              </div>
              <button
                onClick={() => {
                  onApply(coupon.code);
                  onClose();
                }}
                className="mt-4 w-full bg-[#2563EB] text-white py-3 rounded-md hover:bg-[#1d4ed8] transition-colors"
              >
                Apply Coupon
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// OrderSummary Component
const OrderSummary = ({
  watch,
  couponCode,
  setCouponCode,
  currentStep,
  onSubmit,
  setValue,
  handleSubmit,
  leadDetails,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const watchedValues = watch();
  const { selectedSubject } = useSelector((state) => state.commonReducer);

  // Mock coupon data (replace with actual data in your implementation)
  const coupons = [
    { code: "SUMMER2025", discount: 45, description: "Summer sale discount" },
    { code: "NEWUSER", discount: 35, description: "New user welcome discount" },
    // { code: "FLASH30", discount: 30, description: "Flash sale - Limited time offer" },
  ];

  const handleApplyCoupon = (code) => {
    setCouponCode(code);
    // Add logic here to validate and apply the coupon
  };

  const handleContinue = (data) => {
    if (validateForm({ data, setValue })) {
      setIsPaymentModalOpen(true);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg sticky top-4">
      <div className="mb-4">
        <div className="flex items-center gap-2 mb-2">
          <svg
            className="w-4 h-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
            />
          </svg>
          <span className="text-sm text-gray-600">Have A Coupon code?</span>
          {!leadDetails && <button
            className="text-sm text-[#2563EB] hover:text-[#1d4ed8] font-medium"
            onClick={() => setIsModalOpen(true)}
          >
            Offer List
          </button>}
        </div>
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Enter Your Code"
            className="flex-1 border border-gray-200 rounded-md p-2 text-sm focus:ring-2 focus:ring-[#2563EB] focus:border-transparent"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          {/* <button
            className="text-sm text-[#FF5C5C] font-medium hover:text-[#ff4444]"
            onClick={() => handleApplyCoupon(couponCode)}
          >
            Apply
          </button> */}
        </div>
        {!leadDetails && watchedValues?.couponCode && <p className="text-green-500 text-sm mt-1">
          Appied
          </p>}
        {selectedSubject?.couponMessage === "Invalid coupon code." ? (
          <p className="text-red-500 text-sm mt-1">
            {selectedSubject?.couponMessage}
          </p>
        ) : (
          <p className="text-green-500 text-sm mt-1">
            {selectedSubject?.couponMessage}
          </p>
        )}
      </div>

      <h2 className="text-lg font-semibold mb-4">Order Summary</h2>
      <div className="space-y-2 mb-4">
        <div className="text-[#2563EB] font-medium bg-blue-50 p-2 rounded-md mb-2">
          {watchedValues.serviceName || "No service selected"}
        </div>
        <div className="text-sm text-gray-600">
          {watchedValues?.subjectName || "No subject selected"}
        </div>
        <div className="text-sm text-gray-600">
          {
            pagesWordsList?.find((item) => item.value == watchedValues?.pages)
              ?.label
          }
        </div>
        <div className="text-sm text-gray-600">
          {watchedValues.exactDate && watchedValues.exactTime
            ? `${watchedValues.exactDate} ${watchedValues.exactTime}`
            : timeDurations.find((item) => item.value == watchedValues.urgency)
                ?.label}
        </div>
        {currentStep === 2 && watchedValues.writerLevel !== "all" && (
          <div className="text-sm text-gray-600">
            <span className="font-medium capitalize">
              {watchedValues.writerLevel}
            </span>
          </div>
        )}
      </div>

      <div className="space-y-3 border-t pt-4">
        {selectedSubject?.offPrice && (
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Initial Price</span>
            <span className="line-through text-gray-400">
              {selectedSubject?.offPrice || "$0.00"}
            </span>
          </div>
        )}
        {selectedSubject?.basicPrice && (
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Basic Price</span>
            <span>{selectedSubject?.basicPrice}</span>
          </div>
        )}
        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Plagiarism Report</span>
          <span className="text-green-500">FREE</span>
        </div>
        {selectedSubject?.qcCharge && (
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Quality Check</span>
            <span className="text-green-500">{selectedSubject?.qcCharge}</span>
          </div>
        )}
        {selectedSubject?.serviceCharge && (
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Service Charge</span>
            <span>{selectedSubject?.serviceCharge}</span>
          </div>
        )}
        {watchedValues.writerLevel && (
          <div className="flex justify-between text-sm items-center">
            <span className="text-gray-600">
              {selectedSubject?.planMessage}
            </span>
            <span className="text-[#2563EB] font-medium">
              {selectedSubject?.planPrice}
            </span>
          </div>
        )}
        {selectedSubject?.discountPercentage && (
          <div className="flex justify-between text-sm items-center">
            <span className="text-gray-600">Discount</span>
            <div className="flex items-center gap-2">
              <span className="bg-yellow-100 text-yellow-800 text-xs px-2 py-0.5 rounded">
                {selectedSubject?.discountPercentage} OFF
              </span>
              <span className="text-green-500">
                -{selectedSubject?.discountPrice}
              </span>
            </div>
          </div>
        )}
        {selectedSubject?.finalPrice && (
          <div className="flex justify-between font-semibold pt-2 border-t">
            <span>Total Amount</span>
            <span>{selectedSubject?.finalPrice}</span>
          </div>
        )}
      </div>

      <div className="mt-6">
        <h3 className="text-sm font-medium text-gray-700 mb-3">
          Payment & Security
        </h3>
        <div className="grid grid-cols-3 gap-3">
          {[
            { name: "PayPal" },
            { name: "Visa" },
            { name: "MasterCard" },
            { name: "Maestro" },
            { name: "Bank Transfer" },
            { name: "American Express" },
          ].map((method) => (
            <div
              key={method.name}
              className="bg-gray-50 p-2 rounded flex items-center justify-center gap-2"
            >
              <span className="text-xs text-gray-600">{method.name}</span>
            </div>
          ))}
        </div>
      </div>
      {leadDetails ? (
        <button
          type="button"
          className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-4"
        >
          Order Created
        </button>
      ) : (
        <Fragment>
          {currentStep === 1 ? (
            <button
              type="button"
              onClick={handleSubmit(handleContinue)}
              className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-4"
            >
              Continue to Select Writer
            </button>
          ) : (
            <button
              onClick={handleSubmit(handleContinue)}
              className="w-full bg-gradient-to-r from-[#2563EB] to-[#22D3EE] text-white py-3 rounded-md hover:opacity-90 transition duration-300 mt-4"
            >
              Proceed to Payment
            </button>
          )}
        </Fragment>
      )}
      <div className="mt-4 space-y-2">
        <div className="flex items-center gap-2 text-xs text-gray-600">
          <CheckCircle2 className="w-4 h-4 text-green-500" />
          <span>Secure SSL Encryption</span>
        </div>
        <div className="flex items-center gap-2 text-xs text-gray-600">
          <CheckCircle2 className="w-4 h-4 text-green-500" />
          <span>Money Back Guarantee</span>
        </div>
      </div>

      <CouponModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        coupons={coupons}
        onApply={handleApplyCoupon}
      />
      <PaymentConfirmation
        isOpen={isPaymentModalOpen}
        setValue={setValue}
        onClose={() => {
          setIsPaymentModalOpen(false);
        }}
        onDone={handleSubmit(onSubmit)}
        totalAmount={selectedSubject?.finalPrice}
      />
    </div>
  );
};

export default OrderSummary;
