/* eslint-disable no-fallthrough */
import { countryList } from "../../../components/constants/countryList";
import { appActions } from "../../action";
const initialState = {};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${appActions.UPLOAD_IMAGE}_START`:
      return { ...state, isImageLoading: "upload" };
    case `${appActions.UPLOAD_IMAGE}_SUCCESS`:
      return { ...state, isImageLoading: false };
    case `${appActions.UPLOAD_IMAGE}_FAIL`:
      return { ...state, isImageLoading: false };

    case `${appActions.START_CHAT}_START`:
      return { ...state, isLoading: true };
    case `${appActions.START_CHAT}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        leadDetail: action.payload.leadDetail,
      };
    case `${appActions.START_CHAT}_FAIL`:
      return { ...state, isLoading: false };

    case appActions.CLOSE_CHAT:
      return { ...state, isLoading: false, leadDetail: undefined };
    case `${appActions.GET_DASHBOARD}_START`:
      return { ...state, isDashboardLoading: true };
    case `${appActions.GET_DASHBOARD}_SUCCESS`:
      return {
        ...state,
        isDashboardLoading: false,
        dashboardData: action.payload,
      };
    case `${appActions.GET_DASHBOARD}_FAIL`:
      return { ...state, isDashboardLoading: false };

    case `${appActions.GET_EXPERT_LIST_OPTION}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        expertListOption: action.payload?.data,
      };

    case `${appActions.GET_NOTIFICATION_LIST}_SUCCESS`:
      return { ...state, isLoading: false, notificationList: action.payload };

    case appActions.UPDATE_NOTIFICATION:
      let currentCount = (state?.notificationList?.newCount || 0) + 1;
      let currentList = state?.notificationList?.data || [];
      currentList = [action?.payload, ...currentList];
      return {
        ...state,
        notificationList: {
          newCount: currentCount,
          data: currentList,
        },
      };

    case `${appActions.GET_COUNTRY_LIST}_START`:
      return {
        ...state,
        isCountryListLoading: true,
        countryListOption: countryList,
      };
    case `${appActions.GET_COUNTRY_LIST}_SUCCESS`:
      return {
        ...state,
        isCountryListLoading: false,
        countryListOption: countryList,
      };
    case `${appActions.GET_COUNTRY_LIST}_FAIL`:
      return {
        ...state,
        isCountryListLoading: false,
        countryListOption: countryList,
      };

    case `${appActions.UPLOAD_AUDIO}_START`:
      return { ...state, isAudioLoading: true };
    case `${appActions.UPLOAD_AUDIO}_SUCCESS`:
      return { ...state, isAudioLoading: false };
    case `${appActions.UPLOAD_AUDIO}_FAIL`:
      return { ...state, isAudioLoading: false };

    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_START`:
      return { ...state, isOperListOption: true };
    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_SUCCESS`:
      return {
        ...state,
        isOperListOption: false,
        operListOption: action?.payload?.data,
      };
    case `${appActions.GET_OPERATOR_MANAGER_LIST_ACTION}_FAIL`:
      return { ...state, isOperListOption: false };

    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_START`:
      return { ...state, isAssignRquest: true };
    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_SUCCESS`:
      return { ...state, isAssignRquest: false };
    case `${appActions.REQUEST_FOR_ASSIGN_LEAD}_FAIL`:
      return { ...state, isAssignRquest: false };

    case `${appActions.GET_ALL_LIVE_USER_LIST}_START`:
      return { ...state, isLiveUserloading: true };
    case `${appActions.GET_ALL_LIVE_USER_LIST}_SUCCESS`:
      if (action?.request?.firstTime) {
        return {
          ...state,
          isLiveUserloading: false,
          allLiveUser: action?.payload,
        };
      } else {
        const data = [...state.allLiveUser.data, ...action?.payload?.data];
        return {
          ...state,
          isLiveUserloading: false,
          allLiveUser: { ...state.allLiveUser, data: data },
        };
      }

    case `${appActions.GET_ALL_LIVE_USER_LIST}_FAIL`:
      return { ...state, isLiveUserloading: false };

    case `${appActions.GET_OPEN_USER_CHAT_LIST}_START`:
      return { ...state, isLoadOpenChat: true };
    case `${appActions.GET_OPEN_USER_CHAT_LIST}_SUCCESS`:
      return {
        ...state,
        isLoadOpenChat: false,
        openUserChatList: action?.payload?.data,
      };
    case `${appActions.GET_OPEN_USER_CHAT_LIST}_FAIL`:
      return { ...state, isLoadOpenChat: false };

    case `${appActions.GET_OPEN_NOTIFICATION}_START`:
      return { ...state, isNotificationLoad: true };
    case `${appActions.GET_OPEN_NOTIFICATION}_SUCCESS`:
      return {
        ...state,
        isNotificationLoad: false,
        openNoticationList: action?.payload?.data,
      };
    case `${appActions.GET_OPEN_NOTIFICATION}_FAIL`:
      return { ...state, isNotificationLoad: false };

    case appActions.START_OPEN_CHAT:
      return {
        ...state,
        currentOpenChatUser: action?.payload,
      };

    case appActions.CLOSE_OPEN_CHAT:
      return {
        ...state,
        currentOpenChatUser: false,
      };

    case appActions.SET_NOTIFICATION_PERMISSION:
      return { ...state, isShowNotificationPopup: action?.payload?.isShown };

    case `${appActions.GET_REPORTS}_START`:
      return { ...state, isReportLoading: true };
    case `${appActions.GET_REPORTS}_SUCCESS`:
      return {
        ...state,
        isReportLoading: false,
        reports: action?.payload,
      };
    case `${appActions.GET_REPORTS}_FAIL`:
      return { ...state, isReportLoading: false };

    case `${appActions.GET_LEAD_DETAILS}_START`:
      return { ...state, isLeadDetails: true };
    case `${appActions.GET_LEAD_DETAILS}_SUCCESS`:
      return {
        ...state,
        isLeadDetails: false,
        leadDetail: action?.payload?.data,
      };
    case `${appActions.GET_LEAD_DETAILS}_FAIL`:
      return { ...state, isLeadDetails: false };

    case `${appActions.GET_EXPERT_QUERIES}_START`:
      return { ...state, isLoadingQueries: true };
    case `${appActions.GET_EXPERT_QUERIES}_SUCCESS`:
      if (action?.request?.firstTime) {
        return {
          ...state,
          isLoadingQueries: false,
          queriesList: action?.payload,
        };
      } else {
        const oldQuery = [...state.queriesList.data, ...action?.payload.data];
        return {
          ...state,
          isLoadingQueries: false,
          queriesList: { ...action?.payload, data: oldQuery },
        };
      }
    case `${appActions.GET_EXPERT_QUERIES}_FAIL`:
      return { ...state, isLoadingQueries: false };

    case `${appActions.UPDATE_CURRENCY}_START`:
      return { ...state, isUpdateCurrency: true };
    case `${appActions.UPDATE_CURRENCY}_SUCCESS`:
      return {
        ...state,
        isUpdateCurrency: false,
      };
    case `${appActions.UPDATE_CURRENCY}_FAIL`:
      return { ...state, isUpdateCurrency: false };

    case `${appActions.GET_CURRENCY_LIST}_START`:
      return { ...state, isCurrencyList: true };
    case `${appActions.GET_CURRENCY_LIST}_SUCCESS`:
      return {
        ...state,
        isCurrencyList: false,
        currency: action?.payload?.data,
      };
    case `${appActions.GET_CURRENCY_LIST}_FAIL`:
      return { ...state, isCurrencyList: false };

    case `${appActions.UNASSIGNED_LEAD}_START`:
      return { ...state, isUnAssigned: true };
    case `${appActions.UNASSIGNED_LEAD}_SUCCESS`:
      return { ...state, isUnAssigned: false };
    case `${appActions.UNASSIGNED_LEAD}_FAIL`:
      return { ...state, isUnAssigned: false };

    case `${appActions.GET_ORDER_ACTIVITY}_START`:
      return { ...state, isOrderActivity: true };
    case `${appActions.GET_ORDER_ACTIVITY}_SUCCESS`:
      return {
        ...state,
        isOrderActivity: false,
        orderActivity: action?.payload?.data,
      };
    case `${appActions.GET_ORDER_ACTIVITY}_FAIL`:
      return { ...state, isOrderActivity: false };

    case `${appActions.GET_LEAD_ACTIVITY}_START`:
      return { ...state, isOrderActivity: true };
    case `${appActions.GET_LEAD_ACTIVITY}_SUCCESS`:
      return {
        ...state,
        isOrderActivity: false,
        leadActivity: action?.payload?.data,
      };
    case `${appActions.GET_LEAD_ACTIVITY}_FAIL`:
      return { ...state, isOrderActivity: false };

    case `${appActions.GET_WELCOME_SCREEN_DATA}_START`:
      return { ...state, isDashBoardLoading: true, androidWelcome: undefined };
    case `${appActions.GET_WELCOME_SCREEN_DATA}_SUCCESS`:
      return {
        ...state,
        isDashBoardLoading: false,
        androidWelcome: action.payload?.data?.options?.[0],
      };
    case `${appActions.GET_WELCOME_SCREEN_DATA}_FAIL`:
      return { ...state, isDashBoardLoading: false };

    case `${appActions.ON_UPDATE_ANDROID_DESIGN}_START`:
      return { ...state, isUpdateDesign: true };
    case `${appActions.ON_UPDATE_ANDROID_DESIGN}_SUCCESS`:
      return {
        ...state,
        isUpdateDesign: false,
      };

    case `${appActions.ON_UPDATE_ANDROID_DESIGN}_FAIL`:
      return { ...state, isUpdateDesign: false };

    case `${appActions.GET_REFERAL_AMOUNT}_START`:
      return { ...state };
    case `${appActions.GET_REFERAL_AMOUNT}_SUCCESS`:
      return { ...state, referalAmount: action?.payload };
    case `${appActions.GET_REFERAL_AMOUNT}_FAIL`:
      return { ...state };

    case `${appActions.ALL_LIVE_LEADS}_START`:
      return { ...state };
    case `${appActions.ALL_LIVE_LEADS}_SUCCESS`:
      if (action?.request?.data?.firstTime) {
        return { ...state, liveLeads: action?.payload };
      } else {
        let tempLiveLeads = [
          ...state.liveLeads?.data,
          ...action?.payload?.data,
        ];
        return {
          ...state,
          liveLeads: { ...action?.payload, data: tempLiveLeads },
        };
      }

    case `${appActions.ALL_LIVE_LEADS}_FAIL`:
      return { ...state };

    case `${appActions.GET_LIVE_LEADS_DETAILS}_START`:
      return { ...state };
    case `${appActions.GET_LIVE_LEADS_DETAILS}_SUCCESS`:
      return { ...state, liveLeadDetails: action?.payload?.data };
    case `${appActions.GET_LIVE_LEADS_DETAILS}_FAIL`:
      return { ...state };

    case `${appActions.GET_REPORT_ORDER_LIST}_START`:
      return { ...state };
    case `${appActions.GET_REPORT_ORDER_LIST}_SUCCESS`:
      if (action?.request?.firstTime) {
        return { ...state, orderListReport: action?.payload };
      } else {
        let tempLiveLeads = [
          ...state.orderListReport?.orders,
          ...action?.payload?.orders,
        ];
        return {
          ...state,
          orderListReport: { ...action?.payload, orders: tempLiveLeads },
        };
      }
    case `${appActions.GET_REPORT_ORDER_LIST}_FAIL`:
      return { ...state };

    case `${appActions.GET_USER_REPORTLIST}_START`:
      return { ...state };
    case `${appActions.GET_USER_REPORTLIST}_SUCCESS`:
      if (action?.request?.firstTime) {
        return { ...state, userReportList: action?.payload };
      } else {
        let tempLiveLeads = [
          ...state.userReportList?.data,
          ...action?.payload?.data,
        ];
        return {
          ...state,
          userReportList: { ...action?.payload, data: tempLiveLeads },
        };
      }
    case `${appActions.GET_USER_REPORTLIST}_FAIL`:
      return { ...state };

    case `${appActions.GET_USER_DETAILS}_START`:
      return { ...state };
    case `${appActions.GET_USER_DETAILS}_SUCCESS`:
      return { ...state, userDetails: action?.payload };
    case `${appActions.GET_USER_DETAILS}_FAIL`:
      return { ...state };

    case `${appActions.GET_LIVE_USER_TITLE_COUNT}_START`:
      return { ...state };
    case `${appActions.GET_LIVE_USER_TITLE_COUNT}_SUCCESS`:
      return { ...state, chatHeaderInfo: action?.payload?.data };
    case `${appActions.GET_LIVE_USER_TITLE_COUNT}_FAIL`:
      return { ...state };

    case `${appActions.GET_SERVICES}_START`:
      return { ...state, isLoadingService: "services" };
    case `${appActions.GET_SERVICES}_SUCCESS`:
      return { ...state, isLoadingService: false, services: action?.payload };
    case `${appActions.GET_SERVICES}_FAIL`:
      return { ...state, isLoadingService: false };

    case `${appActions.GET_SUBJECT}_START`:
      return { ...state };
    case `${appActions.GET_SUBJECT}_SUCCESS`:
      return { ...state, subjects: action?.payload };
    case `${appActions.GET_SUBJECT}_FAIL`:
      return { ...state };

    case `${appActions.GET_SUBJECT_BY_CATEGORY}_START`:
      return { ...state, isLoadingSubject: "subject" };
    case `${appActions.GET_SUBJECT_BY_CATEGORY}_SUCCESS`:
      return {
        ...state,
        isLoadingSubject: false,
        subjectsCategory: action?.payload,
      };

    case `${appActions.GET_SUBJECT_BY_CATEGORY}_FAIL`:
      return { isLoadingSubject: false, ...state };

    case `${appActions.GET_SUBJECT_PRICING}_START`:
      return { ...state };
    case `${appActions.GET_SUBJECT_PRICING}_SUCCESS`:
      return { ...state, selectedSubject: action?.payload?.data };
    case `${appActions.GET_SUBJECT_PRICING}_FAIL`:
      return { ...state };

      case `${appActions.CREATE_CLIENT_ORDER}_START`:
        return { ...state , isLoadingOrderCreate:"order"};
      case `${appActions.CREATE_CLIENT_ORDER}_SUCCESS`:
        return { ...state, isLoadingOrderCreate:false,selectedSubject: action?.payload?.data };
      case `${appActions.CREATE_CLIENT_ORDER}_FAIL`:
        return { ...state,isLoadingOrderCreate:false};

      

    default:
      return { ...state, isLoading: false };
  }
};
