import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import {
  X,
  CheckCircle,
  Truck,
  FileText,
  AlertCircle,
  PauseCircle,
  RefreshCcw,
  Clock,
  Calendar,
} from "lucide-react"



const steps = [
  {
    status: "OPEN",
    icon: FileText,
    label: "Order Placed",
    time: "2023-05-15 09:30 AM",
    details: "Expected delivery: 2023-05-20",
  },
  {
    status: "ASSIGNED",
    icon: CheckCircle,
    label: "Writer Assigned",
    time: "2023-05-15 10:15 AM",
    details: "Assigned to: John Doe",
  },
  {
    status: "UNDERQC",
    icon: AlertCircle,
    label: "Quality Check",
    time: "2023-05-18 02:00 PM",
    details: "Estimated completion: 2 hours",
  },
  {
    status: "DELIVERED",
    icon: Truck,
    label: "Delivered",
    time: "2023-05-19 11:45 AM",
    details: "Delivered 1 day early",
  },
  {
    status: "REOPEN",
    icon: RefreshCcw,
    label: "Reopened",
    time: "2023-05-21 10:00 AM",
    details: "Reason: Client requested revisions",
  },
  {
    status: "ONHOLD",
    icon: PauseCircle,
    label: "On Hold",
    time: "2023-05-22 03:30 PM",
    details: "Waiting for client input",
  },
  {
    status: "REFUND",
    icon: RefreshCcw,
    label: "Refunded",
    time: "2023-05-23 09:15 AM",
    details: "Full refund processed",
  },
  {
    status: "MISSED_ORDER",
    icon: Clock,
    label: "Missed",
    time: "2023-05-20 11:59 PM",
    details: "Reason: Writer unavailable",
  },
  {
    status: "CLIENTDEADLINE",
    icon: Calendar,
    label: "Client Deadline",
    time: "2023-05-25 05:00 PM",
    details: "Final submission due",
  },
  {
    status: "DONE",
    icon: CheckCircle,
    label: "Completed",
    time: "2023-05-24 04:30 PM",
    details: "Final version approved by client",
  },
]

const statusIndex = {
  OPEN: 0,
  ASSIGNED: 1,
  UNDERQC: 2,
  DELIVERED: 3,
  REOPEN: 4,
  ONHOLD: 5,
  REFUND: 6,
  MISSED_ORDER: 7,
  CLIENTDEADLINE: 8,
  DONE: 9,
}

const TrackOrderModal = ({ isOpen, onClose, orderId, status }) => {
  const currentStepIndex = statusIndex[status] || 0

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg shadow-xl p-6 w-full max-w-md max-h-[80vh] overflow-hidden"
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">Track Order</h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700 transition-colors">
                <X size={20} />
              </button>
            </div>
            <p className="text-sm text-gray-600 mb-4">Order ID: {orderId}</p>
            <div className="relative flex flex-col max-h-[60vh] overflow-y-auto pr-4 scrollbar-thin scrollbar-thumb-blue-500 scrollbar-track-gray-200">
              <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200" />
              <div className="space-y-4">
                {steps.map((step, index) => (
                  <div key={index} className="relative flex items-start py-2">
                    <motion.div
                      initial={false}
                      animate={{
                        backgroundColor: index <= currentStepIndex ? "#3b82f6" : "#e5e7eb",
                        borderColor: index <= currentStepIndex ? "#3b82f6" : "#e5e7eb",
                      }}
                      className="z-10 w-6 h-6 mt-1 rounded-full border-2 flex items-center justify-center bg-white"
                    >
                      <step.icon size={12} className={index <= currentStepIndex ? "text-blue-600" : "text-gray-400"} />
                    </motion.div>
                    <div className="ml-3 flex-1">
                      <p className="text-sm font-medium text-gray-900">{step.label}</p>
                      <p className="text-xs text-gray-500">{step.status}</p>
                      <p className="text-xs text-gray-400 mt-1">{step.time}</p>
                      <p className="text-xs text-gray-600 mt-1">{step.details}</p>
                    </div>
                    {index <= currentStepIndex && (
                      <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} className="flex-shrink-0">
                        <CheckCircle className="text-green-500" size={14} />
                      </motion.div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default TrackOrderModal

